import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import l1 from "../../images/pdf/l1.png";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEye,
  faUniversity,
  faPen,
  faDownload,
  faArrowLeft,
  faAngleLeft,
  faList,
  faSave,
  faPerson,
  faUser,
  faUpload,
  faClose,
  faPhotoVideo,
  faImage,
  faPlus,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons";
import { apiUrl } from "../../config";
import p404 from "../../images/undraw_text_files_au1q.png";
import Tree from "react-d3-tree";
import Treetest from "../../Comps/Tree";
import { Confirm, CustomDialog, StaticDialog } from "react-st-modal";
import RiskAppreciationTable from "../../Comps/RiskAppreciationTable";
import imagem from "../../images/img.png";
import ImageMarker from "../../Comps/ImageMarker";
import ImageView from "../../Comps/ImageView";
import TextView from "../../Comps/TextView";
import Situation from "../../Comps/Situation";
import Jtf from "../../Comps/Jtf";
import Rgt from "../../Comps/Rgt";
import Obs from "../../Comps/Obs";
import DadosCortina from "../DadosCortina";
import TextPage from "../../Comps/TextPage";
import Anexos from "../../Comps/Anexos";

class PrevLau extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loader: true,
      p404: false,
      data: [],
      normas: [],
      tecs: [],
      Opc: {},
      images: [],
      isOpen: false,
      isActive: false,
      diagram: [],
      form: [],
      grupoi: [],
      ModalOpen: false,
      ImagePreview: undefined,
      ImgPrev: undefined,
      upl7: [],
      AtMlhs: [],
      ImageView: [],
      Situation: [],
      Rgt: [],
      txt7: "",
      TextPage: [],
      Anexos: [],
      CortinaLuz: [],
    };
  }

  componentDidMount() {
    document.title = "Pré-visualização";
    var url = window.location.href;
    const match = url.match(/preview\/(.*)$/);
    fetch(
      `${apiUrl}/${window.location.pathname.split("/")[1]}/preview/${match[1]}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Cookies.get("auth_token"),
        },
      }
    ).then((response) => {
      if (response.status == 200) {
        response.json().then((data) => {
          this.setState({
            data,
            diagram: data.diagram,
            form: data.form,
            grupoi: data.groupi,
            Loader: false,
            ImageView: data.ImageView,
            Situation: data.Situation,
            Jtf: data.Jtf,
            Rgt: data.Rgt,
            Obs: data.Obs,
            TextView: data.TextView,
            ImageView: data.ImageView,
            TextPage: data.TextPage,
            CortinaLuz: data.CortinaLuz,
            Anexos: data.Anexos,
          });
          this.setState((prevState) => ({
            images: [...prevState.images, ...data.images],
          }));
        });
      }
      if (response.status == 404) {
        this.setState({ Loader: false, p404: true });
      }
    });
  }
  formatCNPJ(cnpj) {
    // Remove caracteres não numéricos
    cnpj = cnpj.replace(/\D/g, "");

    // Adiciona os pontos e barra de acordo com o padrão
    cnpj = cnpj.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );

    return cnpj;
  }
  handleGamepadConnected = (event) => {
    console.log("Gamepad conectado:", event.gamepad);
    this.setState({ gamepadIndex: event.gamepad.index });
  };

  AddImg(event) {
    const file = event.target.files[0];

    if (file) {
      const isImage = file.type.startsWith("image/");
      if (isImage == false) {
        event.target.value = "";
        toast.warn("Somente imagens são aceitas!");
        return false;
      }
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        ImgPrev: reader.result,
      });
    };
    reader.readAsDataURL(file);

    this.setState({ upl7: event.target.files });
  }
  handleTextChange = (index, text) => {
    this.setState(({ TextPage }) => {
      const updatedPages = [...TextPage];
      updatedPages[index].text = text;
      return { TextPage: updatedPages };
    });
  };

  handleSave = (index, e) => {
    const page = this.state.TextPage[index];
    e.target.classList.add("load");
    fetch(`${apiUrl}/TextPage/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: page.id, text: page.text }),
    })
      .then((response) => response.json())
      .then((data) => {
        e.target.classList.remove("load");
        console.log("Texto salvo com sucesso", data);
      })
      .catch((error) => console.error("Erro ao salvar", error));
  };

  handleAddPage = (e) => {
    e.target.classList.add("load");
    fetch(`${apiUrl}/TextPage/new`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ref: window.location.pathname.split("/")[3],
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        e.target.classList.remove("load");
        this.setState(({ TextPage }) => ({
          TextPage: [...TextPage, { id: data.id, text: "" }],
        }));
      });
  };

  handleToggle = () => {
    // this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  };
  generate_token(length) {
    //edit the token allowed characters
    var a =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split(
        ""
      );
    var b = [];
    for (var i = 0; i < length; i++) {
      var j = (Math.random() * (a.length - 1)).toFixed(0);
      b[i] = a[j];
    }
    return b.join("");
  }

  handleFileChange(event) {
    var file = event.target.files;

    const formData = new FormData();
    if (file) {
      Array.from(file).forEach((file) => {
        formData.append("images", file);
      });

      fetch(
        `${apiUrl}/solicitante/imagens/` +
          window.location.pathname.split("/")[3],
        {
          method: "POST",
          body: formData,
        }
      ).then((response) => {
        if (response.status == 200) {
          response.json().then((data) => {
            this.setState((prevState) => {
              const novaLista = [data[0], ...prevState.images];

              console.log(novaLista);
              return {
                images: novaLista,
              };
            });
          });
        }
      });
    }
  }

  handleOpc = (e, index) => {
    const { name, value } = e.target;

    var check = this.state.form.some((u) => u.e === `${index}`);
    if (check) {
      this.setState((prevState) => ({
        form: prevState.form.map((item) =>
          item.e === `${index}` ? { ...item, marker: `${value}` } : item
        ),
      }));

      this.setState((prevState) => ({
        Opc: {
          ...prevState.Opc,
          [name]: value,
        },
      }));
      console.log("a");
    } else {
      console.log("b");

      this.setState((prevState) => ({
        form: [
          ...prevState.form,
          {
            e: `${index}`,
            marker: `${value}`,
          },
        ],
      }));

      this.setState((prevState) => ({
        Opc: {
          ...prevState.Opc,
          [name]: value,
        },
      }));
    }
  };

  render() {
    var { info } = this.state.data;
    if (this.state.Loader == true) {
      return (
        <>
          <div className="loader-container">
            <div class="loader"></div>
          </div>
        </>
      );
    }
    if (this.state.p404 == true) {
      return (
        <>
          <div class="p404">
            <img src={p404} />
            <p>Desculpe, Esse documento não foi encontrado.</p>
            <button
              type="submit"
              class="btn btn-primary float-left ml-2"
              onClick={() => {
                this.props.navigate(
                  `/${window.location.pathname.split("/")[1]}`
                );
              }}
            >
              {" "}
              <FontAwesomeIcon color="#fff" icon={faArrowLeft} /> Início
            </button>
          </div>
        </>
      );
    }

    return (
      <>
        <header class="bg-white shadow">
          <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <button
              class="font-semibold text-xl text-gray-800 leading-tight"
              onClick={() => {
                this.props.navigate(-1);
              }}
            >
              <FontAwesomeIcon icon={faAngleLeft} /> Pré-visualização
            </button>
          </div>
        </header>

        <main>
          <div className="py-12">
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>Página 1:</h3>
                    <div>
                      <Link
                        to={`/${
                          window.location.pathname.split("/")[1]
                        }/preview/${info.token}/files`}
                      >
                        <button class="btn btn-xs btn-primary">
                          <FontAwesomeIcon color="#fff" icon={faFileAlt} />{" "}
                          Arquivos
                        </button>
                      </Link>
                    </div>
                  </div>

                  <div class="card-body">
                    <table className="table table-bordered">
                      <thead>
                        <tr></tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>EXECUTANTE:</td>
                          <td>
                            <div
                              onClick={() => {
                                this.setState({ slcE: true });
                              }}
                              style={{
                                padding: 10,
                                border: "solid 1px #ccc",
                                borderRadius: 5,
                                cursor: "pointer",
                              }}
                            >
                              {info.executante == undefined ? (
                                "Selecione:"
                              ) : (
                                <>
                                  {this.state.data.executante.razao_social} -{" "}
                                  {this.formatCNPJ(
                                    this.state.data.executante.cnpj
                                  )}
                                </>
                              )}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>CONTRATANTE:</td>
                          <td>
                            {" "}
                            {this.state.data.contratante.razao_social} -{" "}
                            {this.state.data.contratante.cnpj}
                          </td>
                        </tr>
                        <tr>
                          <td>Rev:</td>
                          <td>
                            <TextView
                              type="text"
                              code="IDTbiyu"
                              data={this.state.TextView}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Número:</td>
                          <td>
                            <TextView
                              type="text"
                              code="KBYufnt"
                              data={this.state.TextView}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>MÁQUINA / EQUIPAMENTO:</td>
                          <td>
                            <TextView
                              type="text"
                              code="Uyfibi76"
                              data={this.state.TextView}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>ART VINCULADA: Nº</td>
                          <td>
                            {" "}
                            <TextView
                              type="text"
                              code="liyfubinyu"
                              data={this.state.TextView}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>CIDADE/ESTADO:</td>
                          <td>
                            {" "}
                            <TextView
                              type="text"
                              code="OGNyfn7y"
                              data={this.state.TextView}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Data:</td>
                          <td>
                            {" "}
                            <TextView
                              type="text"
                              code="INgb79F75d"
                              data={this.state.TextView}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Arquivos digitais</td>
                          <td>
                            <ImageView
                              type="image"
                              code="INgb79F75d"
                              data={this.state.ImageView}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Site:</td>
                          <td>
                            <TextView
                              type="text"
                              code="OIfy9n76fo"
                              data={this.state.TextView}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>1 - RESPONSÁVEIS TÉCNICOS</h3>
                    <Link
                      to="/tecnicos"
                      class="btn btn-primary float-right ml-2"
                    >
                      {" "}
                      <FontAwesomeIcon color="#fff" icon={faUser} /> Técnicos
                    </Link>
                  </div>
                  <div class="card-body">
                    <div class="form-group">
                      <select
                        class="form-control"
                        value="Selecione"
                        onChange={(event) => {
                          const selectedId = parseInt(event.target.value, 10);
                          const selectedItem = this.state.data.tecs.find(
                            (item) => item.id === selectedId
                          );

                          const check = this.state.data.doc_tecs.some(
                            (usuario) => usuario.id === selectedId
                          );
                          if (check) {
                            toast.warn(
                              `O tecnico: ${selectedItem.nome} já foi inserido!`
                            );
                            return false;
                          }

                          fetch(
                            `${apiUrl}/solicitante/${
                              window.location.pathname.split("/")[3]
                            }/atualizar-tecs/`,
                            {
                              method: "POST",
                              credentials: "include",
                              headers: {
                                "Content-Type": "application/json",
                                Authorization:
                                  "Bearer " + Cookies.get("auth_token"),
                              },
                              body: JSON.stringify({ ids: `${selectedId}` }),
                            }
                          ).then((response) => {
                            if (response.status == 200) {
                              response.json().then((data) => {
                                this.setState((prevState) => ({
                                  data: {
                                    ...prevState.data,
                                    doc_tecs: [
                                      ...prevState.data.doc_tecs,
                                      selectedItem,
                                    ],
                                  },
                                }));
                                toast.success(
                                  "Normas atualizadas com sucesso!"
                                );
                              });
                            }
                          });
                        }}
                        id="exampleSelect"
                      >
                        <option>Selecione:</option>
                        {this.state.data.tecs.map((item) => (
                          <>
                            <option key={item.id} value={item.id}>
                              {item.nome} - {item.func}
                            </option>
                          </>
                        ))}
                      </select>
                    </div>
                    <br />

                    {this.state.data.doc_tecs.length > 0 ? (
                      <>
                        {" "}
                        <table className="table ntbl table-bordered">
                          <thead>
                            <tr>
                              <th scope="col">NOME</th>
                              <th scope="col">FUNÇÃO</th>
                              <th scope="col">OPÇÃO</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.data.doc_tecs.map((item) => (
                              <>
                                <tr>
                                  <td>{item.nome}</td>
                                  <td>{item.func}</td>
                                  <td>
                                    <button
                                      class="btn btn-primary  ml-2"
                                      onClick={() => {
                                        this.props.navigate(
                                          `/tecnicos/editar/doc/${
                                            window.location.pathname.split(
                                              "/"
                                            )[3]
                                          }/${item.id}`
                                        );
                                      }}
                                    >
                                      {" "}
                                      <FontAwesomeIcon
                                        color="#fff"
                                        icon={faPen}
                                      />
                                    </button>

                                    <button
                                      class="btn btn-danger  ml-2"
                                      onClick={async () => {
                                        const result = await Confirm(
                                          "Após a remoção, não será possível recuperar o dado.",
                                          "Tem certeza?"
                                        );
                                        if (result) {
                                          fetch(
                                            `${apiUrl}/tecnico/doc/remover/`,
                                            {
                                              method: "POST",
                                              credentials: "include",
                                              headers: {
                                                "Content-Type":
                                                  "application/json",
                                                Authorization:
                                                  "Bearer " +
                                                  Cookies.get("auth_token"),
                                              },
                                              body: JSON.stringify({
                                                id: item.id,
                                              }),
                                            }
                                          ).then((response) => {
                                            this.setState((prevState) => ({
                                              data: {
                                                ...prevState.data,
                                                doc_tecs:
                                                  prevState.data.doc_tecs.filter(
                                                    (norma) =>
                                                      norma.id !== item.id
                                                  ),
                                              },
                                            }));
                                          });
                                        }
                                      }}
                                    >
                                      {" "}
                                      <FontAwesomeIcon
                                        color="#fff"
                                        icon={faTrash}
                                      />
                                    </button>
                                  </td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <>Não há nenhum ténico salvo!</>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>2 - EQUIPAMENTO AVALIADO</h3>
                    <Link
                      to="/tecnicos"
                      class="btn btn-primary float-right ml-2"
                    >
                      {" "}
                      <FontAwesomeIcon color="#fff" icon={faUser} /> Técnicos
                    </Link>
                  </div>

                  <div class="card-body">
                    <div class="prev-imgs">
                      {this.state.images.length > 0 ? (
                        <>
                          {this.state.images.map((item) => (
                            <>
                              <div class="pi">
                                <img
                                  src={`${apiUrl}/img/` + item.img}
                                  alt="not loaded"
                                />
                                <button
                                  className="rmver"
                                  onClick={async () => {
                                    const result = await Confirm(
                                      "Após a remoção, não será possível recuperar o dado.",
                                      "Tem certeza?"
                                    );
                                    if (result) {
                                      fetch(
                                        `${apiUrl}/laudo-tecnico/deletar/imagem/${item.id}/` +
                                          window.location.pathname.split(
                                            "/"
                                          )[3],
                                        {
                                          method: "DELETE",
                                        }
                                      ).then((response) => {
                                        if (response.status == 200) {
                                          this.setState({
                                            images: this.state.images.filter(
                                              (e) => e.id !== item.id
                                            ),
                                          });
                                        }
                                      });
                                    }
                                  }}
                                >
                                  <FontAwesomeIcon
                                    color="#fff"
                                    icon={faClose}
                                  />
                                </button>
                              </div>
                            </>
                          ))}
                          <br />
                          <div style={{ display: "table", margin: "auto" }}>
                            <button
                              className="btn btn-primary btn-upl"
                              onClick={() => {
                                this.setState({ upload5: true });
                              }}
                            >
                              <FontAwesomeIcon color="#fff" icon={faImage} />{" "}
                              Adicionar imagem
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <button
                            className="btn btn-primary btn-upl"
                            onClick={() => {
                              this.setState({ upload5: true });
                            }}
                          >
                            <FontAwesomeIcon color="#fff" icon={faImage} />{" "}
                            Adicionar imagem
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>3 - AVALIAÇÃO</h3>
                  </div>

                  <div class="card-body">
                    <div>
                      <strong>Princípios gerais</strong>
                      <p>
                        <strong>12.1.1</strong> Esta Norma Regulamentadora - NR
                        e seus anexos definem referências técnicas, princípios
                        fundamentais e medidas de proteção para resguardar a
                        saúde e a integridade física dos trabalhadores e
                        estabelece requisitos mínimos para a prevenção de
                        acidentes e doenças do trabalho nas fases de projeto e
                        de utilização de máquinas e equipamentos, e ainda à sua
                        fabricação, importação, comercialização, exposição e
                        cessão a qualquer título, em todas as atividades
                        econômicas, sem prejuízo da observância do disposto nas
                        demais NRs aprovadas pela Portaria MTb n.º 3.214, de 8
                        de junho de 1978, nas normas técnicas oficiais ou nas
                        normas internacionais aplicáveis e, na ausência ou
                        omissão destas, opcionalmente, nas normas Europeias tipo
                        “C” harmonizadas.
                      </p>

                      <p>
                        Item <strong>12.1.1.1 </strong>Entende-se como fase de
                        utilização o transporte, montagem, instalação, ajuste,
                        operação, limpeza, manutenção, inspeção, desativação e
                        desmonte da máquina ou equipamento.
                      </p>

                      <p>
                        <p>
                          Item <strong>12.1.12</strong> Os sistemas robóticos
                          que obedeçam às prescrições das normas ABNT ISO
                          10218-1, ABNT ISO 10218-2, da ISO/TS 15066 e demais
                          normas técnicas oficiais ou, na ausência ou omissão
                          destas, nas normas internacionais aplicáveis, estão em
                          conformidade com os requisitos de segurança previstos
                          nessa NR.
                        </p>
                      </p>
                      <p>
                        <strong>Itens: 12.1.1.1 a 12.1.12 </strong>
                      </p>
                      <div>
                        <Situation
                          code="21d2rty5"
                          data={this.state.Situation}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3></h3>
                  </div>

                  <div class="card-body">
                    <div>
                      <Jtf code="65ds465ty" data={this.state.Jtf} />
                      <Rgt code="32s1rth5y" data={this.state.Rgt} />
                      <Obs code="654th654t" data={this.state.Obs} />
                      <br />
                      <p>
                        <strong> 12.1.9</strong> Na aplicação desta NR e de seus
                        anexos, devem-se considerar as características das
                        máquinas e equipamentos, do processo, a apreciação de
                        riscos e o estado da técnica.
                      </p>

                      <p>
                        Item <strong>12.1.9.1</strong> A adoção de sistemas de
                        segurança nas zonas de perigo deve considerar as
                        características técnicas da máquina e do processo de
                        trabalho e as medidas e alternativas técnicas
                        existentes, de modo a atingir o nível necessário de
                        segurança previsto nesta NR.
                      </p>

                      <Situation
                        defaultvalue="b"
                        code="3yfgjgh156"
                        data={this.state.Situation}
                      />
                      <Jtf code="6fdg6515f" data={this.state.Jtf} />
                      <Rgt code="321k89ads" data={this.state.Rgt} />
                      <Obs code="fgh6565dt" data={this.state.Obs} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>5</h3>
                  </div>

                  <div class="card-body">
                    <div style={{ marginTop: 10 }}>
                      <div>
                        <strong>6.2 - Arranjo Físico e Instalações</strong>
                      </div>

                      <div>
                        <strong>12.2</strong> Arranjo físico e instalações.
                      </div>

                      <div style={{ marginTop: 10 }}>
                        <p>
                          <strong>Item 12.2.1</strong> Nos locais de instalação
                          de máquinas e equipamentos, as áreas de circulação
                          devem ser devidamente demarcadas em conformidade com
                          as normas técnicas oficiais.
                        </p>
                        <p>
                          <strong>Item 12.2.1.1</strong> É permitida a
                          demarcação das áreas de circulação utilizando-se
                          marcos, balizas ou outros meios físicos.
                        </p>
                        <p>
                          <strong>Item 12.2.1.2</strong> As áreas de circulação
                          devem ser mantidas desobstruídas.
                        </p>
                        <p>
                          <strong>Item 12.2.2</strong> A distância mínima entre
                          máquinas, em conformidade com suas características e
                          aplicações, deve resguardar a segurança dos
                          trabalhadores durante sua operação, manutenção,
                          ajuste, limpeza e inspeção, e permitir a movimentação
                          dos segmentos corporais, em face da natureza da
                          tarefa.
                        </p>
                        <p>
                          <strong>Item 12.2.3</strong> As áreas de circulação e
                          armazenamento de materiais e os espaços em torno de
                          máquinas devem ser projetados, dimensionados e
                          mantidos de forma que os trabalhadores e os
                          transportadores de materiais, mecanizados e manuais,
                          movimentem-se com segurança.
                        </p>
                        <p>
                          <strong>Item 12.2.4</strong> O piso do local de
                          trabalho onde se instalam máquinas e equipamentos e
                          das áreas de circulação devem ser resistentes às
                          cargas a que estão sujeitos e não devem oferecer
                          riscos de acidentes.
                        </p>
                        <p>
                          <strong>Item 12.2.5</strong> As ferramentas utilizadas
                          no processo produtivo devem ser organizadas e
                          armazenadas ou dispostas em locais específicos para
                          essa finalidade.
                        </p>
                      </div>
                    </div>
                    <div style={{ marginTop: 10 }}>
                      <strong>
                        Itens:12.2.1, 12.2.1.1, 12.2.1.2, 12.2.2, 12.2.3,
                        12.2.4, 12.2.5
                      </strong>
                    </div>

                    <Situation
                      code="3yfdfghtry"
                      defaultvalue="b"
                      data={this.state.Situation}
                    />
                    <Jtf code="6dgadfh6515f" data={this.state.Jtf} />
                    <Rgt code="321fdzhhfdds" data={this.state.Rgt} />
                    <Obs code="fghsrthfd5dt" data={this.state.Obs} />
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>6</h3>
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <p>
                      Item <strong>12.2.6</strong> As máquinas estacionárias
                      devem possuir medidas preventivas quanto à sua
                      estabilidade, de modo que não basculem e não se desloquem
                      intempestivamente por vibrações, choques, forças externas
                      previsíveis, forças dinâmicas internas ou qualquer outro
                      motivo acidental.
                    </p>
                  </div>
                  <div class="card-body">
                    <Situation
                      code="3246rdfg"
                      defaultvalue="b"
                      data={this.state.Situation}
                    />
                    <Jtf code="fd56h465h564" data={this.state.Jtf} />
                    <Rgt code="rtsh6545gh" data={this.state.Rgt} />
                    <Obs code="sd654h6f85dg4" data={this.state.Obs} />
                    <div style={{ marginTop: 10 }}>
                      Item <strong>12.2.6.1</strong> As máquinas estacionárias
                      instaladas a partir da Portaria SIT n.º 197, de 17 de
                      dezembro de 2010, D.O.U. de 24/12/2010, devem respeitar os
                      requisitos necessários fornecidos pelos fabricantes ou, na
                      falta desses, o projeto elaborado por profissional
                      legalmente habilitado quanto à fundação, fixação,
                      amortecimento, nivelamento.
                    </div>
                    <Situation code="8re9755fd6" data={this.state.Situation} />
                    <Jtf code="df5g4df4g65" data={this.state.Jtf} />
                    <Rgt code="df426dfh54f" data={this.state.Rgt} />
                    <Obs code="324sdf65fhb" data={this.state.Obs} />

                    <div style={{ marginTop: 10 }}>
                      <strong>Item 12.2.7</strong> Nas máquinas móveis que
                      possuem rodízios, pelo menos dois deles devem possuir
                      travas.
                      <strong>Item 12.2.8</strong> As máquinas, as áreas de
                      circulação, os postos de trabalho e quaisquer outros
                      locais em que possa haver trabalhadores devem ficar
                      posicionados de modo que não ocorra transporte e
                      movimentação aérea de materiais sobre os trabalhadores.
                    </div>
                    <strong>Itens:12.2.7, 12.2.8</strong>
                    <Situation
                      code="321rsd8t94fd"
                      data={this.state.Situation}
                    />
                    <Jtf code="dar32132fdzs1h" data={this.state.Jtf} />
                    <Rgt code="dar32165f6d5f" data={this.state.Rgt} />
                    <Obs code="321d6r54g5df6" data={this.state.Obs} />
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>7</h3>
                  </div>

                  <div class="card-body">
                    <div style={{ marginTop: 10, marginLeft: 15 }}>
                      <strong>6.3 Instalações e Dispositivos Elétricos</strong>
                    </div>
                    <div>
                      <p>
                        <strong>12.3</strong> Instalações e dispositivos
                        elétricos.
                      </p>
                      <br />
                      <p>
                        Item <strong>12.3.1</strong> Os circuitos elétricos de
                        comando e potência das máquinas e equipamentos devem ser
                        projetadas e mantidas de modo a prevenir, por meios
                        seguros, os perigos de choque elétrico, incêndio,
                        explosão e outros tipos de acidentes, conforme previsto
                        nas normas técnicas oficiais e, na falta dessas, nas
                        normas internacionais aplicáveis.
                      </p>
                    </div>

                    <Situation
                      defaultvalue="b"
                      code="3214df35g1fd"
                      data={this.state.Situation}
                    />
                    <Jtf
                      code="231fh3gfhgfyt"
                      maxLength={700}
                      data={this.state.Jtf}
                    />
                    <Rgt code="hgk216a5ew65ui" data={this.state.Rgt} />
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>8</h3>
                  </div>

                  <div class="card-body">
                    <Obs code="23sd1321sb" data={this.state.Obs} />
                    <div>
                      <br />
                      <p>
                        Item <strong>12.3.2</strong> Devem ser aterrados,
                        conforme as normas técnicas oficiais vigentes, as
                        carcaças, invólucros, blindagens ou partes condutoras
                        das máquinas e equipamentos que não façam parte dos
                        circuitos elétricos, mas que possam ficar sob tensão.
                      </p>
                    </div>
                    <Situation
                      defaultvalue="b"
                      code="32as1r32g1fd"
                      data={this.state.Situation}
                    />
                    <Jtf code="32aer165dsf" data={this.state.Jtf} />
                    <Rgt code="sdr21321re4" data={this.state.Rgt} />
                    <Obs code="er6541dfs56er" data={this.state.Obs} />
                    <div>
                      <br />
                      <p>
                        Item <strong>12.3.3</strong> Os circuitos elétricos de
                        comando e potência das máquinas e equipamentos que
                        estejam ou possam estar em contato direto ou indireto
                        com água ou agentes corrosivos devem ser projetadas com
                        meios e dispositivos que garantam sua blindagem,
                        estanqueidade, isolamento e aterramento, de modo a
                        prevenir a ocorrência de acidentes.
                      </p>
                    </div>

                    <Situation
                      defaultvalue="b"
                      code="aer23164yk65"
                      data={this.state.Situation}
                    />
                    <Jtf code="sae32132sd" data={this.state.Jtf} />
                    <Rgt code="231654sfds" data={this.state.Rgt} />
                    <Obs code="q21tr132fgh56" data={this.state.Obs} />
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>9</h3>
                  </div>

                  <div class="card-body">
                    <div>
                      <p>
                        Item <strong>12.3.4</strong> Os condutores de
                        alimentação elétrica das máquinas e equipamentos devem
                        atender aos seguintes requisitos mínimos de segurança:
                      </p>
                    </div>
                    <div>
                      <p>
                        a) oferecer resistência mecânica compatível com a sua
                        utilização;{" "}
                      </p>
                      <p>
                        b) possuir proteção contra a possibilidade de rompimento
                        mecânico, de contatos abrasivos{" "}
                      </p>
                      <p>
                        e de contato com lubrificantes, combustíveis e calor;{" "}
                      </p>
                      <p>
                        c) localização de forma que nenhum segmento fique em
                        contato com as partes móveis ou cantos vivos;{" "}
                      </p>
                      <p>
                        d) não dificultar o trânsito de pessoas e materiais ou a
                        operação das máquinas;{" "}
                      </p>
                      <p>
                        e) não oferecer quaisquer outros tipos de riscos na sua
                        localização; e{" "}
                      </p>
                      <p>
                        f) ser constituídos de materiais que não propaguem o
                        fogo.{" "}
                      </p>
                    </div>
                    <Situation
                      code="321dsf6541"
                      defaultvalue="b"
                      data={this.state.Situation}
                    />
                    <Jtf code="a2123cvb1re" data={this.state.Jtf} />
                    <Rgt code="23z1d6541fd" data={this.state.Rgt} />
                    <Obs code="23136zd5f46" data={this.state.Obs} />
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>10</h3>
                  </div>

                  <div class="card-body">
                    <div>
                      <p>
                        Item <strong>12.3.5</strong> Os quadros ou painéis de
                        comando e potência das máquinas e equipamentos devem
                        atender aos seguintes requisitos mínimos de segurança:
                      </p>
                    </div>
                    <div>
                      <p>
                        a) possuir porta de acesso mantida permanentemente
                        fechada, exceto nas situações de manutenção, pesquisa de
                        defeitos e outras intervenções, devendo ser observadas
                        as condições previstas nas normas técnicas oficiais ou
                        nas normas internacionais aplicáveis;
                      </p>
                      <p>
                        b) possuir sinalização quanto ao perigo de choque
                        elétrico e restrição de acesso por pessoas não
                        autorizadas;{" "}
                      </p>
                      <p>
                        c) ser mantidos em bom estado de conservação, limpos e
                        livres de objetos e ferramentas;{" "}
                      </p>
                      <p>
                        d) possuir proteção e identificação dos circuitos; e{" "}
                      </p>
                      <p>
                        e) observar ao grau de proteção adequado em função do
                        ambiente de uso.{" "}
                      </p>
                    </div>
                    <Situation
                      code="s65d4654fd5"
                      defaultvalue="b"
                      data={this.state.Situation}
                    />
                    <Jtf code="32zdf1g651" data={this.state.Jtf} />
                    <Rgt code="32d1f3fgh" data={this.state.Rgt} />
                    <Obs code="hg2k3151sd51" data={this.state.Obs} />
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>11</h3>
                  </div>

                  <div class="card-body">
                    <div>
                      <p>
                        Item <strong>12.3.6</strong> As ligações e derivações
                        dos condutores elétricos das máquinas e equipamentos
                      </p>
                    </div>
                    <div>
                      <p>
                        devem ser feitas mediante dispositivos apropriados e
                        conforme as normas técnicas oficiais vigentes, de modo a
                        assegurar resistência mecânica e contato elétrico
                        adequado, com características equivalentes aos
                        condutores elétricos utilizados e proteção contra
                        riscos.
                      </p>
                    </div>
                    <Situation
                      code="32c132c32"
                      defaultvalue="b"
                      data={this.state.Situation}
                    />
                    <Jtf code="df1s65fdf" data={this.state.Jtf} />
                    <Rgt code="321sdfh524155" data={this.state.Rgt} />
                    <Obs code="sfd3h5165fggf" data={this.state.Obs} />
                    <br />
                    <p>
                      Item <strong>12.3.7</strong> As instalações elétricas das
                      máquinas e equipamentos que utilizem energia elétrica
                      fornecida por fonte externa devem possuir dispositivo
                      protetor contra sobrecorrente, dimensionado conforme a
                      demanda de consumo do circuito.
                    </p>
                    <p>
                      Item <strong>12.3.7.1</strong> As máquinas e equipamentos
                      devem possuir dispositivo protetor contra sobretensão
                      quando a elevação da tensão puder ocasionar risco de
                      acidentes.
                    </p>
                    <div>
                      <Situation
                        code="drf3213fd"
                        defaultvalue="b"
                        data={this.state.Situation}
                      />
                      <Jtf code="231525tr5dfh2" data={this.state.Jtf} />
                      <Rgt code="254r8s4655df46" data={this.state.Rgt} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>12</h3>
                  </div>

                  <div class="card-body">
                    <div>
                      <p>
                        Item <strong>12.3.7.2</strong> Nas máquinas e
                        equipamentos em que a falta ou a inversão de fases da
                        alimentação elétrica puder ocasionar riscos, deve haver
                        dispositivo que impeça a ocorrência de acidentes.
                      </p>
                    </div>

                    <Situation code="321df321fd" data={this.state.Situation} />
                    <Jtf code="32df13213fg" data={this.state.Jtf} />
                    <Rgt code="sd321635fh65fg" data={this.state.Rgt} />
                    <Obs code="gh21654gra65e" data={this.state.Obs} />

                    <br />
                    <p>
                      Item <strong>12.3.8</strong> São proibidas nas máquinas e
                      equipamentos:
                    </p>

                    <div>
                      <p>
                        a) a utilização de chave geral como dispositivo de
                        partida e parada;{" "}
                      </p>
                      <p>
                        b) a utilização de chaves tipo faca nos circuitos
                        elétricos; e{" "}
                      </p>
                      <p>
                        c) a existência de partes energizadas expostas de
                        circuitos que utilizam energia elétrica.{" "}
                      </p>
                    </div>
                    <div>
                      <Situation
                        defaultvalue="b"
                        code="231f365gf"
                        data={this.state.Situation}
                      />
                      <Jtf code="32d1f531fg" data={this.state.Jtf} />
                      <Rgt code="32jk1l65asfg" data={this.state.Rgt} />
                      <Obs code="654th654t" data={this.state.Obs} />
                    </div>
                    <div style={{ marginTop: 10 }}>
                      <p>
                        Item <strong>12.3.9</strong> As baterias devem atender
                        aos seguintes requisitos mínimos de segurança:{" "}
                      </p>
                      <div>
                        <p>
                          a) localização de modo que sua manutenção e troca
                          possam ser realizadas facilmente a partir do solo ou
                          de uma plataforma de apoio;{" "}
                        </p>
                        <p>
                          b) constituição e fixação de forma a não haver
                          deslocamento acidental; e{" "}
                        </p>
                        <p>
                          c) proteção do terminal positivo, a fim de prevenir
                          contato acidental e curto-circuito.{" "}
                        </p>
                      </div>
                      <div>
                        Item: <strong>12.3.10</strong> Os serviços e
                        substituições de baterias devem ser realizados conforme
                        indicação constante do manual de operação.
                      </div>
                      <Situation
                        code="321x6f3516"
                        data={this.state.Situation}
                      />
                      <Jtf code="23sd165vd5" data={this.state.Jtf} />
                      <Rgt code="23165sd4d" data={this.state.Rgt} />
                      <Obs code="231sda65g4df" data={this.state.Obs} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>13</h3>
                  </div>

                  <div class="card-body">
                    <div style={{ marginTop: 10 }}>
                      <strong>
                        6.4 Dispositivos de Partida Acionamento e Paradas
                      </strong>
                      <p>
                        Item <strong>12.4.1</strong> Os dispositivos de partida,
                        acionamento e parada das máquinas devem ser projetados,
                      </p>
                    </div>
                    <p>selecionados e instalados de modo que: </p>
                    <div>
                      <p>a) não se localizem em suas zonas perigosas;</p>
                      <p>
                        b) possam ser acionados ou desligados em caso de
                        emergência por outra pessoa que não seja o operador;
                      </p>
                      <p>
                        c) impeçam acionamento ou desligamento involuntário pelo
                        operador ou por qualquer outra forma acidental;
                      </p>
                      <p>d) não acarretem riscos adicionais; e</p>
                      <p>e) dificulte-se a burla.</p>
                    </div>
                    <div>
                      <Situation
                        code="52fd45fg4"
                        defaultvalue="b"
                        data={this.state.Situation}
                      />
                      <Jtf code="ft216fg5465h" data={this.state.Jtf} />
                      <Rgt code="23df1g3213f" data={this.state.Rgt} />
                      <Obs code="23rtd1s32fg" data={this.state.Obs} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>14</h3>
                  </div>

                  <div class="card-body">
                    <div>
                      <p>
                        Item <strong>12.4.2</strong> Os comandos de partida ou
                        acionamento das máquinas devem possuir dispositivos que
                        impeçam seu funcionamento automático ao serem
                        energizadas.
                      </p>
                    </div>
                    <Situation
                      code="5dfzghfd"
                      defaultvalue="b"
                      data={this.state.Situation}
                    />
                    <Jtf code="ft21fsshfsd" data={this.state.Jtf} />
                    <Rgt code="23dsrytsdfg" data={this.state.Rgt} />
                    <Obs code="2dyureasods" data={this.state.Obs} />
                    <div>
                      <strong>Item 12.4.3</strong>
                      <p>
                        Quando forem utilizados dispositivos de acionamento
                        bimanual, visando a manter as mãos do operador fora da
                        zona de perigo, esses devem atender aos seguintes
                        requisitos mínimos do comando:
                      </p>
                      <div>
                        <strong>a)</strong>
                        <p>
                          possuir atuação síncrona, ou seja, um sinal de saída
                          deve ser gerado somente quando os dois dispositivos de
                          atuação do comando - botões - forem atuados com um
                          retardo de tempo menor ou igual a 0,5 s (meio
                          segundo);
                        </p>
                      </div>
                      <div>
                        <strong>b)</strong>
                        <p>
                          estar sob monitoramento automático por interface de
                          segurança, se indicado pela apreciação de risco;
                        </p>
                      </div>
                      <div>
                        <strong>c)</strong>
                        <p>
                          ter relação entre os sinais de entrada e saída, de
                          modo que os sinais de entrada aplicados a cada um dos
                          dois dispositivos de atuação devem juntos se iniciar e
                          manter o sinal de saída somente durante a aplicação
                          dos dois sinais;
                        </p>
                      </div>
                      <div>
                        <strong>d)</strong>
                        <p>
                          o sinal de saída deve terminar quando houver
                          desacionamento de qualquer dos dispositivos de
                          atuação;
                        </p>
                      </div>
                      <div>
                        <strong>e)</strong>
                        <p>
                          possuir dispositivos de atuação que exijam intenção do
                          operador em acioná-los a fim de minimizar a
                          probabilidade de acionamento acidental;
                        </p>
                      </div>
                      <div>
                        <strong>f)</strong>
                        <p>
                          possuir distanciamento, barreiras ou outra solução
                          prevista nas normas técnicas oficiais ou nas normas
                          internacionais aplicáveis entre os dispositivos de
                          atuação para dificultar a burla do efeito de proteção;
                        </p>
                      </div>
                      <div>
                        <strong>g)</strong>
                        <p>
                          tornar possível o reinício do sinal de saída somente
                          após a desativação dos dois dispositivos de atuação.
                        </p>
                      </div>
                      <Situation
                        code="5dfdfreewd"
                        data={this.state.Situation}
                      />
                      <Jtf code="ft2srtuasksd" data={this.state.Jtf} />
                      <Rgt code="23dsdrysdrtg" data={this.state.Rgt} />
                      <Obs code="2dasdrtgdrss" data={this.state.Obs} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>15</h3>
                  </div>

                  <div class="card-body">
                    <p>
                      Item <strong>12.4.4</strong> Nas máquinas e equipamentos
                      operados por dois ou mais dispositivos de acionamento
                      bimanual, a atuação síncrona é requerida somente para cada
                      um dos dispositivos de acionamento bimanual e não entre
                      dispositivos diferentes, que devem manter simultaneidade
                      entre si.
                    </p>
                    <Situation code="231321gf" data={this.state.Situation} />
                    <Jtf code="2165gfj5fgh" data={this.state.Jtf} />
                    <Rgt code="OIug8yGFyf" data={this.state.Rgt} />
                    <div style={{ marginTop: 10 }}>
                      <p>
                        Item <strong>12.4.5</strong> Os dispositivos de
                        acionamento bimanual devem ser posicionados a uma
                        distância segura da zona de perigo, levando em
                        consideração:{" "}
                      </p>
                      <p>
                        a) a forma, a disposição e o tempo de resposta do
                        dispositivo de acionamento bimanual;
                      </p>
                      <p>
                        b) o tempo máximo necessário para a paralisação da
                        máquina ou para a remoção do perigo, após o término do
                        sinal de saída do dispositivo de acionamento bimanual; e
                      </p>
                      <p>c) a utilização projetada para a máquina.</p>
                    </div>
                    <Situation code="s365d4fg65" data={this.state.Situation} />
                    <Jtf code="231s6h55fg" data={this.state.Jtf} />
                    <Obs code="d23f1f35d4g" data={this.state.Obs} />
                    <Rgt code="OUYHfgou" data={this.state.Rgt} />
                    <div>
                      <p>
                        Item <strong>12.4.6</strong> Os dispositivos de
                        acionamento bimanual móveis instalados em pedestais
                        devem:{" "}
                      </p>
                      <p>
                        {" "}
                        a) manter-se estáveis em sua posição de trabalho; e{" "}
                      </p>
                      <p>
                        {" "}
                        b) possuir altura compatível com o alcance do operador
                        em sua posição de trabalho.{" "}
                      </p>
                      <br />
                      <Situation code="521d65g65" data={this.state.Situation} />
                      <Jtf code="65sdf46546" data={this.state.Jtf} />
                      <Rgt code="tg546x5gf4" data={this.state.Rgt} />
                      <Obs code="23df4654gf6" data={this.state.Obs} />
                      <br />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>17</h3>
                  </div>

                  <div class="card-body">
                    <p>
                      Item <strong>12.4.7</strong> Nas máquinas e equipamentos
                      cuja operação requeira a participação de mais de uma
                      pessoa, o número de dispositivos de acionamento bimanual
                      simultâneos deve corresponder ao número de operadores
                      expostos aos perigos decorrentes de seu acionamento, de
                      modo que o nível de proteção seja o mesmo para cada
                      trabalhador.
                    </p>
                    <p>
                      Item: <strong>12.30.2</strong> O circuito de acionamento
                      deve ser projetado de modo a impedir o funcionamento dos
                      dispositivos de acionamento bimanual habilitados pelo
                      seletor enquanto os demais comandos não habilitados não
                      forem desconectados.{" "}
                    </p>
                    <div>
                      <strong>Item 12.4.7.1</strong> Deve haver seletor do
                      número de dispositivos de acionamento em utilização, com
                      bloqueio que impeça a sua seleção por pessoas não
                      autorizadas.
                    </div>

                    <div>
                      <strong>Item 12.4.7.2</strong> O circuito de acionamento
                      deve ser projetado de modo a impedir o funcionamento dos
                      dispositivos de acionamento bimanual habilitados pelo
                      seletor enquanto os demais dispositivos de acionamento
                      bimanuais não habilitados não forem desconectados.
                    </div>

                    <div>
                      <strong>Item 12.4.7.3</strong> Quando utilizados dois ou
                      mais dispositivos de acionamento bimanual simultâneos,
                      devem possuir sinal luminoso que indique seu
                      funcionamento.
                    </div>
                    <Situation code="321rse564t" data={this.state.Situation} />
                    <Jtf code="32546fd465fg" data={this.state.Jtf} />
                    <Rgt code="32rew545t46f5" data={this.state.Rgt} />
                    <Obs code="df564165f4ghf" data={this.state.Obs} />
                    <br />
                    <div>
                      <strong>Item 12.4.8</strong> As máquinas ou equipamentos
                      concebidos e fabricados para permitir a utilização de
                      vários modos de comando ou de funcionamento que apresentem
                      níveis de segurança diferentes devem possuir um seletor
                      que atenda aos seguintes requisitos:
                    </div>

                    <div>
                      <p>
                        <strong>a)</strong> possibilidade de bloqueio em cada
                        posição, impedindo a sua mudança por pessoas não
                        autorizadas;
                      </p>
                      <p>
                        <strong>b)</strong> correspondência de cada posição a um
                        único modo de comando ou de funcionamento;
                      </p>
                      <p>
                        <strong>c)</strong> modo de comando selecionado com
                        prioridade sobre todos os outros sistemas de comando,
                        com exceção da parada de emergência; e
                      </p>
                      <p>
                        <strong>d)</strong> a seleção deve ser visível, clara e
                        facilmente identificável.
                      </p>
                    </div>
                    <Situation
                      code="52fg4654"
                      defaultvalue="b"
                      data={this.state.Situation}
                    />
                    <Jtf code="21t564fg6554" data={this.state.Jtf} />
                    <Rgt code="32fh65fg455t" data={this.state.Rgt} />
                    <br />
                    <div>
                      <strong>Item 12.4.9</strong> As máquinas e equipamentos,
                      cujo acionamento por pessoas não autorizadas possa
                      oferecer risco à saúde ou integridade física de qualquer
                      pessoa, devem possuir sistema que possibilite o bloqueio
                      de seus dispositivos de acionamento.
                    </div>

                    <div>
                      <strong>Item 12.4.10</strong> O acionamento e o
                      desligamento simultâneo por um único comando de um
                      conjunto de máquinas e equipamentos ou de máquinas e
                      equipamentos de grande dimensão devem ser precedidos da
                      emissão de sinal sonoro ou visual.
                    </div>

                    <div>
                      <strong>Item 12.4.11</strong> Devem ser adotadas, quando
                      necessárias, medidas adicionais de alerta, como sinal
                      visual e dispositivos de telecomunicação, considerando as
                      características do processo produtivo e dos trabalhadores.
                    </div>

                    <div>
                      <strong>Item 12.4.12</strong> As máquinas e equipamentos
                      comandados por radiofrequência devem possuir proteção
                      contra interferências eletromagnéticas acidentais.
                    </div>
                    <Situation
                      code="541g65fd465"
                      defaultvalue="b"
                      data={this.state.Situation}
                    />
                    <Jtf code="2df165g46fgf" data={this.state.Jtf} />
                    <Rgt code="d23f1g531df65" data={this.state.Rgt} />
                    <Obs code="231df564f665g" data={this.state.Obs} />
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>18</h3>
                  </div>

                  <div class="card-body">
                    <p>
                      Item <strong>12.4.13</strong> Os componentes de partida,
                      parada, acionamento e controles que compõem a interface de
                      operação das máquinas e equipamentos fabricados a partir
                      de 24 de Março de 2012 devem:
                    </p>
                    <div>
                      <p>
                        <strong>a)</strong> possibilitar a instalação e
                        funcionamento do sistema de parada de emergência, quando
                        aplicável, conforme itens e subitens do capítulo sobre
                        dispositivos de parada de emergência, desta NR; e
                      </p>
                      <p>
                        <strong>b)</strong> operar em extrabaixa tensão de até
                        25VCA (vinte e cinco volts em corrente alternada) ou de
                        até 60VCC (sessenta volts em corrente contínua).
                      </p>
                    </div>
                    <Situation
                      defaultvalue="b"
                      code="56f1d65416df4"
                      data={this.state.Situation}
                    />
                    <Jtf code="3fdg5646dfg" data={this.state.Jtf} />
                    <Rgt code="3df6546fd6f5" data={this.state.Rgt} />
                    <Obs code="df5df65f4g6hf" data={this.state.Obs} />
                    <br />
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>19</h3>
                  </div>

                  <div class="card-body">
                    <div>
                      <strong>Item 12.4.13.1</strong> Os componentes de partida,
                      parada, acionamento e controles que compõem a interface de
                      operação das máquinas e equipamentos fabricados até 24 de
                      março de 2012 devem:
                    </div>

                    <div>
                      <p>
                        <strong>a)</strong> possibilitar a instalação e
                        funcionamento do sistema de parada de emergência, quando
                        aplicável, conforme itens e subitens do capítulo
                        dispositivos de parada de emergência, desta NR; e
                      </p>
                      <p>
                        <strong>b)</strong> quando a apreciação de risco indicar
                        a necessidade de proteções contra choques
                        elétricos,operar em extrabaixa tensão de até 25VCA
                        (vinte e cinco volts em corrente alternada) ou de até
                        60VCC (sessenta volts em corrente contínua).{" "}
                      </p>
                    </div>
                    <Situation
                      code="5fdghfgf4"
                      defaultvalue="b"
                      data={this.state.Situation}
                    />
                    <Jtf code="3fdfhsgfdsf" data={this.state.Jtf} />
                    <Rgt code="3dghdfgfdfd" data={this.state.Rgt} />
                    <Obs code="fgdsdfsgfdf" data={this.state.Obs} />
                    <br />
                    <div>
                      <strong>Item 12.4.13.1.1</strong> Poderá ser adotada outra
                      medida de proteção contra choques elétricos, conforme
                      normas técnicas oficiais vigentes em alternativa às
                      alíneas "b" dos respectivos subitens 12.4.13 e 12.4.13.1
                      desta NR.
                    </div>

                    <div>
                      <strong>Item 12.4.14</strong> Se indicada pela apreciação
                      de riscos a necessidade de redundância dos dispositivos
                      responsáveis pela prevenção de partida inesperada ou pela
                      função de parada relacionada à segurança, conforme a
                      categoria de segurança requerida, o circuito elétrico da
                      chave de partida de motores de máquinas e equipamentos
                      deve:
                    </div>

                    <div>
                      <p>
                        <strong>a)</strong> possuir estrutura redundante;
                      </p>
                      <p>
                        <strong>b)</strong> permitir que as falhas que
                        comprometem a função de segurança sejam monitoradas; e
                      </p>
                      <p>
                        <strong>c)</strong> ser adequadamente dimensionado de
                        acordo com o estabelecido pelas normas técnicas oficiais
                        ou pelas normas internacionais aplicáveis.
                      </p>
                    </div>
                    <Situation
                      code="5fdfhfhgf"
                      defaultvalue="b"
                      data={this.state.Situation}
                    />
                    <Jtf code="3fdfhjysdyktd" data={this.state.Jtf} />
                    <Rgt code="d61g165984wef21" data={this.state.Rgt} />
                    <Obs code="gh62we89f213zsc" data={this.state.Obs} />
                    <br />
                    <p>
                      Item <strong>12.4.14.1</strong> É permitida a parada
                      controlada do motor, desde que não haja riscos decorrentes
                      de sua parada não instantânea.
                    </p>
                    <Situation
                      code="gh54651asdfg"
                      data={this.state.Situation}
                    />
                    <Jtf code="21xdf12hfg32" data={this.state.Jtf} />
                    <Rgt code="jk321321dsfa" data={this.state.Rgt} />
                    <Obs code="jn2546tf23yt" data={this.state.Obs} />
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>20</h3>
                  </div>

                  <div class="card-body">
                    <strong>6.5 Sistemas de Segurança</strong>
                    <p>
                      Item <strong>12.5.1</strong> As zonas de perigo das
                      máquinas e equipamentos devem possuir sistemas de
                      segurança, caracterizados por proteções fixas, proteções
                      móveis e dispositivos de segurança interligados, que
                      resguardem proteção à saúde e à integridade física dos
                      trabalhadores.
                    </p>
                    <Situation
                      defaultvalue="b"
                      code="f65xgf6516514"
                      data={this.state.Situation}
                    />
                    <Jtf code="c5g61v65c65" data={this.state.Jtf} />

                    <div>
                      <Rgt code="OIugbolijn" data={this.state.Rgt} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>21</h3>
                  </div>

                  <div class="card-body">
                    <Obs code="t65y46t5fd6" data={this.state.Obs} />
                    <br />
                    <p>
                      Item <strong>12.5.1.1</strong> Quando utilizadas proteções
                      que restringem o acesso do corpo ou parte dele, devem ser
                      observadas as distâncias mínimas conforme normas técnicas
                      oficiais ou normas internacionais aplicáveis.
                    </p>
                    <Situation
                      defaultvalue="b"
                      code="sd65f468f5df5d6"
                      data={this.state.Situation}
                    />
                    <Jtf code="re6511f32fg21" data={this.state.Jtf} />
                    <Rgt code="2r321dfs213df" data={this.state.Rgt} />
                    <Obs code="df321y65dfs1f" data={this.state.Obs} />
                    <br />
                    <div>
                      <strong>Item 12.5.2</strong> Os sistemas de segurança
                      devem ser selecionados e instalados de modo a atender aos
                      seguintes requisitos:
                    </div>

                    <div>
                      <p>
                        <strong>a)</strong> ter categoria de segurança conforme
                        apreciação de riscos prevista nas normas técnicas
                        oficiais;
                      </p>
                      <p>
                        <strong>b)</strong> estar sob a responsabilidade técnica
                        de profissional legalmente habilitado;
                      </p>
                      <p>
                        <strong>c)</strong> possuir conformidade técnica com o
                        sistema de comando a que são integrados;
                      </p>
                      <p>
                        <strong>d)</strong> instalação de modo que dificulte a
                        sua burla;
                      </p>
                      <p>
                        <strong>e)</strong> manterem-se sob vigilância
                        automática, ou seja, monitoramento, se indicado pela
                        apreciação de risco, de acordo com a categoria de
                        segurança requerida, exceto para dispositivos de
                        segurança exclusivamente mecânicos; e
                      </p>
                      <p>
                        <strong>f)</strong> paralisação dos movimentos perigosos
                        e demais riscos quando ocorrerem falhas ou situações
                        anormais de trabalho.{" "}
                      </p>
                    </div>
                    <Situation
                      code="d56r4561fg"
                      defaultvalue="b"
                      data={this.state.Situation}
                    />
                    <Jtf code="65thd4665fd" data={this.state.Jtf} />
                    <Rgt code="s65dt465fsg89r" data={this.state.Rgt} />
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>22</h3>
                  </div>

                  <div class="card-body">
                    <Obs code="231rt165g" data={this.state.Obs} />
                    <div>
                      <br />
                      <p>
                        Item <strong>12.5.2.1</strong> A instalação de sistemas
                        de segurança deve ser realizada por profissional
                        legalmente habilitado ou profissional qualificado ou
                        capacitado, quando autorizados pela empresa.
                      </p>
                    </div>
                    <Situation
                      code="d54g65g"
                      defaultvalue="b"
                      data={this.state.Situation}
                    />
                    <Jtf code="65thse65t45fd" data={this.state.Jtf} />
                    <Rgt code="s65d65dfy469r" data={this.state.Rgt} />
                    <Obs code="23fd325fd565g" data={this.state.Obs} />
                    <br />
                    <p>
                      Item <strong>12.5.3</strong> Os sistemas de segurança, se
                      indicado pela apreciação de riscos, devem exigir rearme
                      (“reset”) manual.{" "}
                    </p>

                    <Situation
                      defaultvalue="b"
                      code="ddf65s43dfsg"
                      data={this.state.Situation}
                    />
                    <Jtf code="6534565fd45fd" data={this.state.Jtf} />
                    <Rgt code="s65dsfdh4569r" data={this.state.Rgt} />
                    <Obs code="235df5665fd5g" data={this.state.Obs} />
                    <br />
                    <p>
                      Item <strong>12.5.3.1</strong> Depois que um comando de
                      parada tiver sido iniciado pelo sistema de segurança, a
                      condição de parada deve ser mantida até que existam
                      condições seguras para o rearme.
                    </p>
                    <div>
                      <Situation
                        defaultvalue="b"
                        code="24fd1565fdfg"
                        data={this.state.Situation}
                      />
                      <Jtf code="65thdf5646adsd" data={this.state.Jtf} />
                      <Rgt code="s6er4165fd5fkr" data={this.state.Rgt} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>23</h3>
                  </div>

                  <div class="card-body">
                    <Obs code="231rtdf66df55g" data={this.state.Obs} />
                    <br />
                    <div>
                      <strong>Item 12.5.5</strong> Os componentes relacionados
                      aos sistemas de segurança e comandos de acionamento e
                      parada das máquinas, inclusive de emergência, devem
                      garantir a manutenção do estado seguro da máquina ou
                      equipamento quando ocorrerem flutuações no nível de
                      energia além dos limites considerados no projeto,
                      incluindo o corte e restabelecimento do fornecimento de
                      energia.
                    </div>

                    <div>
                      <strong>Item 12.5.6</strong> A proteção deve ser móvel
                      quando o acesso a uma zona de perigo for requerido mais de
                      uma vez por turno de trabalho, observando-se que:
                    </div>

                    <div>
                      <p>
                        <strong>a)</strong> a proteção deve ser associada a um
                        dispositivo de intertravamento quando sua abertura não
                        possibilitar o acesso à zona de perigo antes da
                        eliminação do risco; e
                      </p>
                      <p>
                        <strong>b)</strong> a proteção deve ser associada a um
                        dispositivo de intertravamento com bloqueio quando sua
                        abertura possibilitar o acesso à zona de perigo antes da
                        eliminação do risco.
                      </p>
                    </div>
                    <Situation
                      defaultvalue="b"
                      code="24fsrt651dfzsgg"
                      data={this.state.Situation}
                    />
                    <Jtf code="65gfj65465gdsd" data={this.state.Jtf} />
                    <Rgt code="s32tr89l65ia5r" data={this.state.Rgt} />
                    <Obs code="231sda56f65fdg" data={this.state.Obs} />
                    <br />
                    <p>
                      Item <strong>12.5.6.1</strong> É permitida a ligação em
                      série, na mesma interface de segurança, de dispositivos de
                      intertravamento de diferentes proteções móveis, desde que
                      observado o disposto na ISO/TR 24.119.
                    </p>
                    <Situation
                      code="f41dfgfbsgg"
                      defaultvalue="b"
                      data={this.state.Situation}
                    />
                    <Jtf code="65gfghj32d165fd" data={this.state.Jtf} />
                    <Rgt code="sjkl231ds65uy5r" data={this.state.Rgt} />
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>23</h3>
                  </div>

                  <div class="card-body">
                    <br />
                    <div>
                      <strong>Item 12.5.7</strong> As máquinas e equipamentos
                      dotados de proteções móveis associadas a dispositivos de
                      intertravamento devem:
                    </div>
                    <div>
                      <p>
                        <strong>a)</strong> operar somente quando as proteções
                        estiverem fechadas;
                      </p>
                      <p>
                        <strong>b)</strong> paralisar suas funções perigosas
                        quando as proteções forem abertas durante a operação; e
                      </p>
                      <p>
                        <strong>c)</strong> garantir que o fechamento das
                        proteções por si só não possa dar início às funções
                        perigosas.
                      </p>
                    </div>
                    <Situation
                      code="24y54984dfg"
                      defaultvalue="b"
                      data={this.state.Situation}
                    />
                    <Jtf code="65tfgj216fg5sd" data={this.state.Jtf} />
                    <Rgt code="fg211j65ghjgkr" data={this.state.Rgt} />
                    <Obs code="23fd214gh65f6g" data={this.state.Obs} />
                    <br />
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>24</h3>
                  </div>

                  <div class="card-body">
                    <p>
                      Item <strong>12.5.7.1</strong> A utilização de proteções
                      intertravadas com comando de partida, como exceção ao
                      previsto na alínea “c” do subitem 12.5.7, deve ser
                      limitada e aplicada conforme as exigências específicas
                      previstas em normas técnicas oficiais.
                    </p>
                    <Situation
                      code="2f351gfhfg"
                      defaultvalue="b"
                      data={this.state.Situation}
                    />
                    <Jtf code="65t32fgh5465d" data={this.state.Jtf} />
                    <Rgt code="fg21fgh354gfr" data={this.state.Rgt} />
                    <Obs code="2fg6516gf5h6g" data={this.state.Obs} />
                    <br />
                    <div>
                      <strong>Item 12.5.8</strong> Os dispositivos de
                      intertravamento com bloqueio associados às proteções
                      móveis das máquinas e equipamentos devem:
                    </div>
                    <div>
                      <p>
                        <strong>a)</strong> permitir a operação somente enquanto
                        a proteção estiver fechada e bloqueada;
                      </p>
                      <p>
                        <strong>b)</strong> manter a proteção fechada e
                        bloqueada até que tenha sido eliminado o risco de lesão
                        devido às funções perigosas da máquina ou do
                        equipamento; e
                      </p>
                      <p>
                        <strong>c)</strong> garantir que o fechamento e bloqueio
                        da proteção por si só não possa dar início às funções
                        perigosas da máquina ou do equipamento.
                      </p>
                    </div>
                    <Situation
                      code="2ffg651651fg"
                      data={this.state.Situation}
                    />
                    <Jtf code="65df5616g65d" data={this.state.Jtf} />
                    <Rgt code="fg21fdfg51fr" data={this.state.Rgt} />
                    <Obs code="2fg6dfg213g" data={this.state.Obs} />
                    <br />
                    <p>
                      Item <strong>12.5.8.1</strong> A utilização de proteções
                      intertravadas com comando de partida, como exceção ao
                      previsto na alínea “c” do subitem 12.5.8, deve ser
                      limitada e aplicada conforme as exigências específicas
                      previstas em normas técnicas oficiais.
                    </p>
                    <Situation
                      code="2fffg321fdfg"
                      defaultvalue="b"
                      data={this.state.Situation}
                    />
                    <Jtf code="6grf6515g65d" data={this.state.Jtf} />
                    <Rgt code="fg2hg54vggfr" data={this.state.Rgt} />
                    <Obs code="2fgfg54165dg" data={this.state.Obs} />
                    <br />
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>25</h3>
                  </div>

                  <div class="card-body">
                    <p>
                      Item <strong>12.5.9</strong> As transmissões de força e os
                      componentes móveis a elas interligados, acessíveis ou
                      expostos, desde que ofereçam risco, devem possuir
                      proteções fixas, ou móveis com dispositivos de
                      intertravamento, que impeçam o acesso por todos os lados.
                    </p>
                    <Situation
                      code="265gf1651gfg"
                      defaultvalue="b"
                      data={this.state.Situation}
                    />
                    <Jtf code="65df4gh6546gd" data={this.state.Jtf} />
                    <Rgt code="ffg654h65fghfr" data={this.state.Rgt} />
                    <Obs code="2fgfg65.66.gfg" data={this.state.Obs} />
                    <br />
                    <p>
                      Item <strong>12.5.9.1</strong> Quando utilizadas proteções
                      móveis para o enclausuramento de transmissões de força que
                      possuam inércia, devem ser utilizados dispositivos de
                      intertravamento com bloqueio.
                    </p>
                    <Situation
                      code="df561651fdgfg"
                      defaultvalue="b"
                      data={this.state.Situation}
                    />
                    <Jtf code="65ik5y156a65ytgd" data={this.state.Jtf} />
                    <Rgt code="ffhk621984refdfr" data={this.state.Rgt} />
                    <Obs code="h65465asdfc21546" data={this.state.Obs} />
                    <br />
                    <p>
                      Item <strong>12.5.9.2</strong> O eixo cardã deve possuir
                      proteção adequada, em toda a sua extensão, fixada na
                      tomada de força da máquina, desde a cruzeta até o
                      acoplamento do implemento ou equipamento.
                    </p>
                    <Situation code="bhv651132g" data={this.state.Situation} />
                    <Jtf code="65ig651561fxcfgf" data={this.state.Jtf} />
                    <Rgt code="KLJhvlJKHhujh" data={this.state.Rgt} />
                    <br />
                    <p>
                      Item <strong>12.5.10</strong> As máquinas e equipamentos
                      que ofereçam risco de ruptura de suas partes, projeção de
                      materiais, partículas ou substâncias, devem possuir
                      proteções que garantam a segurança e a saúde dos
                      trabalhadores.
                    </p>
                    <Situation code="651gh132sd" data={this.state.Situation} />
                    <Jtf code="f65g5651hfg654jh" data={this.state.Jtf} />
                    <Obs code="hfgf6fg651665fgg" data={this.state.Obs} />

                    <Rgt code="ILUgUILgyo8" data={this.state.Rgt} />
                    <br />

                    <div>
                      <strong>Item 12.5.11</strong> As proteções devem ser
                      projetadas e construídas de modo a atender aos seguintes
                      requisitos de segurança:
                    </div>
                    <div>
                      <p>
                        <strong>a)</strong> cumprir suas funções apropriadamente
                        durante a vida útil da máquina ou possibilitar a
                        reposição de partes deterioradas ou danificadas;
                      </p>
                      <p>
                        <strong>b)</strong> ser constituídas de materiais
                        resistentes e adequados à contenção de projeção de
                        peças, materiais e partículas;
                      </p>
                      <p>
                        <strong>c)</strong> fixação firme e garantia de
                        estabilidade e resistência mecânica compatíveis com os
                        esforços requeridos; .
                      </p>
                      <p>
                        <strong>d)</strong> não criar pontos de esmagamento ou
                        agarramento com partes da máquina ou com outras
                        proteções;
                      </p>
                      <p>
                        <strong>e)</strong> não possuir extremidades e arestas
                        cortantes ou outras saliências perigosas;
                      </p>
                      <p>
                        <strong>f)</strong> resistir às condições ambientais do
                        local onde estão instaladas;
                      </p>
                      <p>
                        <strong>g)</strong> dificulte-se a burla;
                      </p>
                      <p>
                        <strong>h)</strong> proporcionar condições de higiene e
                        limpeza;
                      </p>
                      <p>
                        <strong>i)</strong> impedir o acesso à zona de perigo;
                      </p>
                      <p>
                        <strong>j)</strong> ter seus dispositivos de
                        intertravamento protegidos adequadamente contra
                        sujidade, poeiras e corrosão, se necessário;
                      </p>
                      <p>
                        <strong>k)</strong> ter ação positiva, ou seja, atuação
                        de modo positivo;
                      </p>
                      <p>
                        <strong>l)</strong> não acarretar riscos adicionais.
                      </p>
                      <div>
                        <Situation
                          code="265f56gh156hg12"
                          defaultvalue="b"
                          data={this.state.Situation}
                        />
                        <Jtf code="65dff65fg65d" data={this.state.Jtf} />
                        <Obs code="2fg56516gg" data={this.state.Obs} />
                        <Rgt code="LIflyuvijhçugpiu" data={this.state.Rgt} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>26</h3>
                  </div>

                  <div class="card-body">
                    <p>
                      Item <strong>12.5.12</strong> Quando a proteção for
                      confeccionada com material descontínuo, devem ser
                      observadas as distâncias de segurança para impedir o
                      acesso às zonas de perigo, conforme previsto nas normas
                      técnicas oficiais ou nas normas internacionais aplicáveis.
                    </p>
                    <Situation
                      code="265fg564g6"
                      defaultvalue="b"
                      data={this.state.Situation}
                    />
                    <Jtf code="6561hg516jf5d" data={this.state.Jtf} />
                    <Obs code="2gh261gf21g13" data={this.state.Obs} />
                    <Rgt code="UYTDurxty" data={this.state.Rgt} />
                    <br />
                    <div>
                      <strong>Item 12.5.13</strong> Sempre que forem utilizados
                      sistemas de segurança, inclusive proteções distantes, com
                      possibilidade de alguma pessoa ficar na zona de perigo,
                      deve ser adotada uma das seguintes medidas adicionais de
                      proteção coletiva para impedir a partida da máquina
                      enquanto houver pessoas nessa zona:
                    </div>
                    <div>
                      <p>
                        <strong>a)</strong> sensoriamento da presença de
                        pessoas;
                      </p>
                      <p>
                        <strong>b)</strong> proteções móveis ou sensores de
                        segurança na entrada ou acesso à zona de perigo,
                        associadas a rearme (“reset”) manual.
                      </p>
                    </div>
                    <Situation
                      code="32g231fg"
                      defaultvalue="b"
                      data={this.state.Situation}
                    />
                    <Jtf code="123ffgf2h12f3214" data={this.state.Jtf} />
                    <Rgt code="rtsfds2611gf35gh" data={this.state.Rgt} />
                    <Obs code="sdgf21d56112fdg4" data={this.state.Obs} />
                    <br />
                    <p>
                      Item <strong>12.5.13.1</strong> A localização dos
                      atuadores de rearme (“reset”) manual deve permitir uma
                      visão completa da zona protegida pelo sistema.
                    </p>
                    <Situation
                      code="gfhj1231hg"
                      defaultvalue="b"
                      data={this.state.Situation}
                    />
                    <Jtf code="1fdg231h3f3214" data={this.state.Jtf} />
                    <Obs code="sdgfdf56ggf214" data={this.state.Obs} />
                    <Rgt code="OUgyoiugyujhgy" data={this.state.Rgt} />
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>29</h3>
                  </div>

                  <div class="card-body">
                    <br />
                    <p>
                      Item <strong>12.5.13.2</strong> Quando não for possível o
                      cumprimento da exigência do subitem 12.5.13.1, deve ser
                      adotado o sensoriamento da presença de pessoas nas zonas
                      de perigo com a visualização obstruída, ou a adoção de
                      sistema que exija a ida à zona de perigo não visualizada,
                      como, por exemplo, duplo rearme (“reset”).
                    </p>
                    <Situation
                      code="3gh656h5j1g"
                      defaultvalue="b"
                      data={this.state.Situation}
                    />
                    <Jtf code="1fgh5645gre8rgk4" data={this.state.Jtf} />
                    <Obs code="gh21465era546dg4" data={this.state.Obs} />
                    <Rgt code="KUftyewjhnbrfgty" data={this.state.Rgt} />
                    <br />
                    <p>
                      Item <strong>12.5.13.3</strong> Deve haver dispositivos de
                      parada de emergência localizados no interior da zona
                      protegida pelo sistema, bem como meios de liberar pessoas
                      presas dentro dela.
                    </p>

                    <Situation
                      code="32xfdh154dfg"
                      defaultvalue="b"
                      data={this.state.Situation}
                    />
                    <Jtf code="fddf3212132fd4" data={this.state.Jtf} />
                    <Rgt code="rf3213213d3dfh" data={this.state.Rgt} />
                    <Obs code="df62gf23fg124" data={this.state.Obs} />
                    <br />
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>30</h3>
                  </div>

                  <div class="card-body">
                    <p>
                      Item <strong>12.5.14</strong> As proteções também
                      utilizadas como meio de acesso por exigência das
                      características da máquina ou do equipamento devem atender
                      aos requisitos de resistência e segurança adequados a
                      ambas as finalidades
                    </p>
                    <Situation code="3h321ty54f" data={this.state.Situation} />
                    <Jtf code="d321fx34fgh65gfh" data={this.state.Jtf} />
                    <Obs code="gf324165gfh65dg4" data={this.state.Obs} />
                    <Rgt code="KUYfukyfgiufiujk" data={this.state.Rgt} />
                    <br />
                    <p>
                      Item <strong>12.5.15</strong> Deve haver proteção no fundo
                      dos degraus da escada, ou seja, nos espelhos, sempre que
                      uma parte saliente do pé ou da mão possa contatar uma zona
                      perigosa.
                    </p>
                    <Situation code="f65f56465f" data={this.state.Situation} />
                    <Jtf code="d3fgh5465g6fg5dh" data={this.state.Jtf} />
                    <Rgt code="Phgyp98ny" data={this.state.Rgt} />
                    <br />
                    Item <strong>12.5.16 </strong> As proteções, dispositivos e
                    sistemas de segurança são partes integrantes das máquinas e
                    equipamentos e não podem ser considerados itens opcionais
                    para qualquer fim.
                    <Situation
                      code="fgf32165fg"
                      defaultvalue="b"
                      data={this.state.Situation}
                    />
                    <Jtf code="df231g52165fghjk" data={this.state.Jtf} />
                    <Rgt code="OYgf8ygt" data={this.state.Rgt} />
                    <br />
                    <p>
                      Item <strong>12.5.17</strong> Em função do risco, poderá
                      ser exigido projeto, diagrama ou representação esquemática
                      dos sistemas de segurança de máquinas, com respectivas
                      especificações técnicas em língua portuguesa, elaborado
                      por profissional legalmente habilitado.
                    </p>
                    <Situation
                      code="fgfd566545g"
                      defaultvalue="b"
                      data={this.state.Situation}
                    />
                    <Jtf code="ddfg3213gf654fjk" data={this.state.Jtf} />
                    <Rgt code="UOfyouygiu" data={this.state.Rgt} />
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>31</h3>
                  </div>

                  <div class="card-body">
                    <strong>6.6 Dispositivos de Parada de Emergência</strong>
                    <br />
                    <p>
                      Item <strong>12.6.1</strong> As máquinas devem ser
                      equipadas com um ou mais dispositivos de parada de
                      emergência, por meio dos quais possam ser evitadas
                      situações de perigo latentes e existentes.
                    </p>

                    <Situation
                      code="fdg216df54"
                      defaultvalue="b"
                      data={this.state.Situation}
                    />
                    <Jtf code="ffg656.gfhhfgfg4" data={this.state.Jtf} />
                    <Rgt
                      multiple
                      code="fg6d546fg44gf89h"
                      data={this.state.Rgt}
                    />
                    <Obs code="sgf65.65fgh9d+f9" data={this.state.Obs} />
                    <br />
                    <p>
                      Item <strong>12.6.1.1</strong> Os dispositivos de parada
                      de emergência não devem ser utilizados como dispositivos
                      de partida ou de acionamento.
                    </p>
                    <Situation
                      code="dfs564g65df"
                      defaultvalue="b"
                      data={this.state.Situation}
                    />
                    <Jtf code="fd64684fd4" data={this.state.Jtf} />
                    <Obs code="5fxd4g6.59fd8s" data={this.state.Obs} />
                    <Rgt code="LIyfgliou" data={this.state.Rgt} />
                    <br />
                    <div>
                      <strong>Item 12.6.1.2</strong> Excetuam-se da obrigação do
                      subitem 12.6.1:
                    </div>
                    <div>
                      <p>
                        <strong>a)</strong> as máquinas autopropelidas;
                      </p>
                      <p>
                        <strong>b)</strong> as máquinas e equipamentos nas quais
                        o dispositivo de parada de emergência não possibilita a
                        redução do risco.
                      </p>
                    </div>
                    <Situation
                      code="fg564h65fg"
                      defaultvalue="b"
                      data={this.state.Situation}
                    />
                    <Jtf code="fdf65g65gf.54" data={this.state.Jtf} />
                    <Obs code="5fxfd654df+8s" data={this.state.Obs} />
                    <Rgt code="OLguyo8uig" data={this.state.Rgt} />
                    <p>
                      Item <strong>12.6.2</strong> Os dispositivos de parada de
                      emergência devem ser posicionados em locais de fácil
                      acesso e visualização pelos operadores em seus postos de
                      trabalho e por outras pessoas, e mantidos permanentemente
                      desobstruídos.
                    </p>
                    <Situation
                      code="fdfh456dgh"
                      defaultvalue="b"
                      data={this.state.Situation}
                    />
                    <Jtf code="fdf6+fg59+87gf4" data={this.state.Jtf} />
                    <Obs code="5fg4h98gfh49898" data={this.state.Obs} />
                    <Rgt code="PIUguigt" data={this.state.Rgt} />
                    <br />
                    <div>
                      <strong>Item 12.6.3</strong> Os dispositivos de parada de
                      emergência devem:
                    </div>
                    <div>
                      <p>
                        <strong>a)</strong> ser selecionados, montados e
                        interconectados de forma a suportar as condições de
                        operação previstas, bem como as influências do meio;
                      </p>
                      <p>
                        <strong>b)</strong> ser usados como medida auxiliar, não
                        podendo ser alternativa a medidas adequadas de proteção
                        ou a sistemas automáticos de segurança;
                      </p>
                      <p>
                        <strong>c)</strong> possuir acionadores projetados para
                        fácil atuação do operador ou outros que possam ser
                        acionados rapidamente em situações de emergência;
                      </p>
                      <p>
                        <strong>d)</strong> prevalecer sobre todos os outros
                        comandos;
                      </p>
                      <p>
                        <strong>e)</strong> provocar a parada da operação ou
                        processo perigoso em período de tempo tão reduzido
                        quanto tecnicamente possível, sem provocar riscos
                        suplementares;
                      </p>
                      <p>
                        <strong>f)</strong> ter sua função disponível e
                        operacional a qualquer tempo, independentemente do modo
                        de operação;
                      </p>
                      <div>
                        <Situation
                          defaultvalue="b"
                          code="fdg216dty654"
                          data={this.state.Situation}
                        />
                        <Jtf code="ffgfg654654fdfg4" data={this.state.Jtf} />
                        <Obs code="sgf6554fg569d+f9" data={this.state.Obs} />
                        <Rgt code="Ptgu8piugpiu" data={this.state.Rgt} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>32</h3>
                  </div>

                  <div class="card-body">
                    <br />
                    <div>
                      <strong>Item 12.6.4</strong> A função parada de emergência
                      não deve:
                    </div>
                    <div>
                      <p>
                        <strong>a)</strong> prejudicar a eficiência de sistemas
                        de segurança ou dispositivos com funções relacionadas
                        com a segurança;
                      </p>
                      <p>
                        <strong>b)</strong> prejudicar qualquer meio projetado
                        para resgatar pessoas acidentadas; e
                      </p>
                      <p>
                        <strong>c)</strong> gerar risco adicional.
                      </p>
                    </div>

                    <Situation
                      code="fd65fg46654"
                      defaultvalue="b"
                      data={this.state.Situation}
                    />
                    <Jtf code="ff6dg565ffdfdfg4" data={this.state.Jtf} />
                    <Obs code="sgf65sd56f465+f9" data={this.state.Obs} />
                    <Rgt code="LOguyoiu" data={this.state.Rgt} />
                    <br />
                    <p>
                      Item <strong>12.6.5</strong> O acionamento do dispositivo
                      de parada de emergência deve também resultar na retenção
                      do acionador, de tal forma que, quando a ação no acionador
                      for descontinuada, este se mantenha retido até que seja
                      desacionado.
                    </p>

                    <Situation
                      code="df516fd54"
                      defaultvalue="b"
                      data={this.state.Situation}
                    />
                    <Jtf code="ff6dg56564+drgg4" data={this.state.Jtf} />
                    <Obs code="sgfsdaf445ds54-9" data={this.state.Obs} />
                    <Rgt code="LIY&gytg87" data={this.state.Rgt} />
                    <br />
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>33</h3>
                  </div>

                  <div class="card-body">
                    <p>
                      Item <strong>12.6.5.1</strong> O desacionamento deve ser
                      possível apenas como resultado de uma ação manual
                      intencionada sobre o acionador, por meio de manobra
                      apropriada.
                    </p>
                    <Situation
                      defaultvalue="b"
                      code="fddf5465y654"
                      data={this.state.Situation}
                    />
                    <Jtf code="ffgfdfg65465df" data={this.state.Jtf} />
                    <Obs code="sgf6654dfg9859" data={this.state.Obs} />
                    <Rgt code="ÒIH9ghṕiohj" data={this.state.Rgt} />
                    <br />
                    <div>
                      <strong>Item 12.6.6</strong> Quando usados acionadores do
                      tipo cabo, deve-se:
                    </div>
                    <div>
                      <p>
                        <strong>a)</strong> utilizar chaves de parada de
                        emergência que trabalhem tracionadas, de modo a cessarem
                        automaticamente as funções perigosas da máquina em caso
                        de ruptura ou afrouxamento dos cabos;
                      </p>
                      <p>
                        <strong>b)</strong> considerar o deslocamento e a força
                        aplicada nos acionadores, necessários para a atuação das
                        chaves de parada de emergência; e
                      </p>
                      <p>
                        <strong>c)</strong> obedecer à distância máxima entre as
                        chaves de parada de emergência recomendada pelo
                        fabricante.
                      </p>
                    </div>

                    <div>
                      <strong>Item 12.6.7</strong> As chaves de parada de
                      emergência devem ser localizadas de tal forma que todo o
                      cabo de acionamento seja visível a partir da posição de
                      desacionamento da parada de emergência.
                    </div>

                    <div>
                      <strong>Item 12.6.7.1</strong> Se não for possível o
                      cumprimento da exigência do subitem 12.6.7, deve-se
                      garantir que, após a atuação e antes do desacionamento, a
                      máquina ou equipamento seja inspecionado em toda a
                      extensão do cabo.
                    </div>
                    <Situation
                      code="fddf5465y654e"
                      data={this.state.Situation}
                    />
                    <Jtf code="ffgfdfg65465dfe" data={this.state.Jtf} />
                    <Obs code="sgf6654dfg9859e" data={this.state.Obs} />
                    <Rgt code="ÇILUgYFfgou" data={this.state.Rgt} />
                    <br />
                    <p>
                      Item <strong>12.6.8</strong> A parada de emergência deve
                      exigir rearme ou reset manual a ser realizado somente após
                      a correção do evento que motivou o acionamento da parada
                      de emergência.
                    </p>
                    <Situation
                      code="fdddfaghg4"
                      defaultvalue="b"
                      data={this.state.Situation}
                    />
                    <Jtf code="fghldfghdfdg" data={this.state.Jtf} />
                    <Obs code="sgfgjdfjfd59" data={this.state.Obs} />
                    <Rgt code="LIguyo8ytfg8" data={this.state.Rgt} />
                    <br />
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>34</h3>
                  </div>

                  <div class="card-body">
                    <p>
                      Item <strong>12.6.8.1</strong> A localização dos
                      acionadores de rearme deve permitir uma visualização
                      completa da área protegida pelo cabo.
                    </p>
                    <Situation
                      code="ffghgfhfgwe4"
                      data={this.state.Situation}
                    />
                    <Jtf code="fgrytruhdfdg" data={this.state.Jtf} />
                    <Obs code="sgfdyhfgfdf9" data={this.state.Obs} />
                    <Rgt code="IKfyoiuyf" data={this.state.Rgt} />
                    <br />
                    <strong>6.7 Componentes Pressurizados</strong>
                    <p>
                      Item <strong>12.7.1</strong> Devem ser adotadas medidas
                      adicionais de proteção das mangueiras, tubulações e demais
                      componentes pressurizados sujeitos a eventuais impactos
                      mecânicos e outros agentes agressivos, quando houver
                      risco.
                    </p>
                    <br />

                    <Situation
                      code="3fgh+965.fg"
                      defaultvalue="b"
                      data={this.state.Situation}
                    />
                    <Jtf code="fd5f3d26.ds4" data={this.state.Jtf} />
                    <Rgt code="rtdf6565gffd" data={this.state.Rgt} />
                    <Obs code="sd656df465g4" data={this.state.Obs} />
                    <br />
                    <strong>6.8 Transportadores de Materiais</strong>
                    <br />
                    <br />
                    <p>
                      Item <strong>12.8.1</strong> Os movimentos perigosos dos
                      transportadores contínuos de materiais, acessíveis durante
                      a operação normal, devem ser protegidos, especialmente nos
                      pontos de esmagamento, agarramento e aprisionamento.
                    </p>

                    <Situation code="3fgh654fg4h" data={this.state.Situation} />
                    <Jtf code="fd56f.g654fg54" data={this.state.Jtf} />
                    <Rgt code="rdfg656fg4ggfd" data={this.state.Rgt} />
                    <Obs code="sd656df465g4df" data={this.state.Obs} />
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>35</h3>
                  </div>

                  <div class="card-body">
                    <strong>6.9 Aspectos Ergonômicos</strong>
                    <br />
                    <p>
                      Item <strong>12.9.1</strong> Para o trabalho em máquinas e
                      equipamentos devem ser respeitadas as disposições contidas
                      na Norma Regulamentadora n.º 17 - Ergonomia.
                    </p>

                    <Situation code="365xdf45g" data={this.state.Situation} />
                    <Jtf code="fd5dfz654564" data={this.state.Jtf} />
                    <Rgt code="r65d4fzsdagh" data={this.state.Rgt} />
                    <Obs code="sd65dfshfgg4" data={this.state.Obs} />
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>35</h3>
                  </div>

                  <div class="card-body">
                    <strong>6.10 Riscos Adicionais</strong>
                    <br />

                    <div>
                      <strong>Item 12.10.1</strong> Para fins de aplicação desta
                      NR, devem ser considerados os seguintes riscos adicionais:
                    </div>
                    <div>
                      <p>
                        <strong>a)</strong> substâncias perigosas quaisquer,
                        sejam agentes biológicos ou agentes químicos em estado
                        sólido, líquido ou gasoso, que apresentem riscos à saúde
                        ou integridade física dos trabalhadores por meio de
                        inalação, ingestão ou contato com a pele, olhos ou
                        mucosas;
                      </p>
                      <p>
                        <strong>b)</strong> radiações ionizantes geradas pelas
                        máquinas e equipamentos ou provenientes de substâncias
                        radiativas por eles utilizadas, processadas ou
                        produzidas;
                      </p>
                      <p>
                        <strong>c)</strong> radiações não ionizantes com
                        potencial de causar danos à saúde ou integridade física
                        dos trabalhadores;
                      </p>
                      <p>
                        <strong>d)</strong> vibrações;
                      </p>
                      <p>
                        <strong>e)</strong> ruído;
                      </p>
                      <p>
                        <strong>f)</strong> calor;
                      </p>
                      <p>
                        <strong>g)</strong> combustíveis, inflamáveis,
                        explosivos e substâncias que reagem perigosamente; e
                      </p>
                      <p>
                        <strong>h)</strong> superfícies aquecidas acessíveis que
                        apresentem risco de queimaduras causadas pelo contato
                        com a pele.
                      </p>
                    </div>

                    <Situation code="fggfhfgryik" data={this.state.Situation} />
                    <Jtf code="fgcjgfggfhjtyk" data={this.state.Jtf} />
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>36</h3>
                  </div>

                  <div class="card-body">
                    <strong>
                      6.11 Manutenção, Inspeção, Preparação, Ajustes e Reparos
                    </strong>

                    <br />

                    <div>
                      <strong>Item 12.11.1</strong> As máquinas e equipamentos
                      devem ser submetidos a manutenções na forma e
                      periodicidade determinada pelo fabricante, por
                      profissional legalmente habilitado ou por profissional
                      qualificado, conforme as normas técnicas oficiais ou
                      normas técnicas internacionais aplicáveis.
                    </div>
                    <div>
                      <strong>Item 12.11.2</strong> As manutenções devem ser
                      registradas em livro próprio, ficha ou sistema
                      informatizado interno da empresa, com os seguintes dados:
                    </div>
                    <div>
                      <p>
                        <strong>a)</strong> intervenções realizadas;
                      </p>
                      <p>
                        <strong>b)</strong> data da realização de cada
                        intervenção;
                      </p>
                      <p>
                        <strong>c)</strong> serviço realizado;
                      </p>
                      <p>
                        <strong>d)</strong> peças reparadas ou substituídas;
                      </p>
                      <p>
                        <strong>e)</strong> condições de segurança do
                        equipamento;
                      </p>
                      <p>
                        <strong>f)</strong> indicação conclusiva quanto às
                        condições de segurança da máquina; e
                      </p>
                      <p>
                        <strong>g)</strong> nome do responsável pela execução
                        das intervenções.
                      </p>
                    </div>
                    <br />
                    <div>
                      <strong>Item 12.11.2.1</strong> O registro das manutenções
                      deve ficar disponível aos trabalhadores envolvidos na
                      operação, manutenção e reparos, bem como à Comissão
                      Interna de Prevenção de Acidentes - CIPA, ao Serviço de
                      Segurança e Medicina do Trabalho - SESMT e à Auditoria
                      Fiscal do Trabalho.
                    </div>
                    <div>
                      <strong>Item 12.11.2.2</strong> As manutenções de itens
                      que influenciem na segurança devem:
                    </div>
                    <div>
                      <p>
                        <strong>a)</strong> no caso de preventivas, possuir
                        cronograma de execução;
                      </p>
                      <p>
                        <strong>b)</strong> no caso de preditivas, possuir
                        descrição das técnicas de análise e meios de supervisão
                        centralizados ou de amostragem.
                      </p>
                    </div>
                    <div>
                      <strong>Item 12.11.3</strong> A manutenção, inspeção,
                      reparos, limpeza, ajuste e outras intervenções que se
                      fizerem necessárias devem ser executadas por profissionais
                      capacitados, qualificados ou legalmente habilitados,
                      formalmente autorizados pelo empregador, com as máquinas e
                      equipamentos parados e adoção dos seguintes procedimentos:
                    </div>
                    <div>
                      <p>
                        <strong>a)</strong> isolamento e descarga de todas as
                        fontes de energia das máquinas e equipamentos, de modo
                        visível ou facilmente identificável por meio dos
                        dispositivos de comando;
                      </p>
                      <p>
                        <strong>b)</strong> bloqueio mecânico e elétrico na
                        posição “desligado” ou “fechado” de todos os
                        dispositivos de corte de fontes de energia, a fim de
                        impedir a reenergização, e sinalização com cartão ou
                        etiqueta de bloqueio contendo o horário e a data do
                        bloqueio, o motivo da manutenção e o nome do
                        responsável;
                      </p>
                      <p>
                        <strong>c)</strong> medidas que garantam que à jusante
                        dos pontos de corte de energia não exista possibilidade
                        de gerar risco de acidentes;
                      </p>
                      <p>
                        <strong>d)</strong> medidas adicionais de segurança,
                        quando for realizada manutenção, inspeção e reparos de
                        máquinas ou equipamentos sustentadas somente por
                        sistemas hidráulicos e pneumáticos;
                      </p>
                      <p>
                        <strong>e)</strong> sistemas de retenção com trava
                        mecânica, para evitar o movimento de retorno acidental
                        de partes basculadas ou articuladas abertas das máquinas
                        e equipamentos.
                      </p>
                    </div>
                    <div>
                      <strong>Item 12.11.3.1</strong> Para situações especiais
                      de manutenção, regulagem, ajuste, limpeza, pesquisa de
                      defeitos e inconformidades, em que não seja possível o
                      cumprimento das condições estabelecidas no subitem
                      12.11.3, e em outras situações que impliquem a redução do
                      nível de segurança das máquinas e equipamentos e houver
                      necessidade de acesso às zonas de perigo, deve ser
                      possível selecionar um modo de operação que:
                    </div>
                    <div>
                      <p>
                        <strong>a)</strong> torne inoperante o modo de comando
                        automático;
                      </p>
                      <p>
                        <strong>b)</strong> permita a realização dos serviços
                        com o uso de dispositivo de acionamento de ação
                        continuada associado à redução da velocidade, ou
                        dispositivos de comando por movimento limitado;
                      </p>
                    </div>
                    <br />
                    <div>
                      <strong>Item 12.11.3.1</strong> Para situações especiais
                      de manutenção, regulagem, ajuste, limpeza, pesquisa de
                      defeitos e inconformidades, em que não seja possível o
                      cumprimento das condições estabelecidas no subitem
                      12.11.3, e em outras situações que impliquem a redução do
                      nível de segurança das máquinas e equipamentos e houver
                      necessidade de acesso às zonas de perigo, deve ser
                      possível selecionar um modo de operação que:
                    </div>
                    <div>
                      <p>
                        <strong>a)</strong> torne inoperante o modo de comando
                        automático;
                      </p>
                      <p>
                        <strong>b)</strong> permita a realização dos serviços
                        com o uso de dispositivo de acionamento de ação
                        continuada associado à redução da velocidade, ou
                        dispositivos de comando por movimento limitado;
                      </p>
                      <p>
                        <strong>c)</strong> impeça a mudança por trabalhadores
                        não autorizados;
                      </p>
                      <p>
                        <strong>d)</strong> a seleção corresponda a um único
                        modo de comando ou de funcionamento;
                      </p>
                      <p>
                        <strong>e)</strong> quando selecionado, tenha prioridade
                        sobre todos os outros sistemas de comando, com exceção
                        da parada de emergência;
                      </p>
                      <p>
                        <strong>f)</strong> torne a seleção visível, clara e
                        facilmente identificável.
                      </p>
                    </div>
                    <div>
                      <strong>Item 12.11.3.2</strong> Ficam dispensadas do
                      atendimento dos subitens 12.11.3 e 12.11.3.1, as situações
                      especiais de manutenção, regulagem, ajuste, pesquisa de
                      defeitos e inconformidades que não ofereçam riscos às
                      pessoas envolvidas na realização destas atividades, que
                      não impliquem na redução do nível de segurança e que não
                      necessitem de acesso às zonas de perigo, desde que
                      executadas sob supervisão do empregador ou pessoa por ele
                      designada.
                    </div>
                    <div>
                      <strong>Item 12.11.3.3</strong> Na impossibilidade técnica
                      da aplicação das medidas dos subitens 12.11.3 e 12.11.3.1,
                      em função de inércia térmica do processo, podem ser
                      adotadas outras medidas de segurança, desde que sejam
                      planejadas e gerenciadas por profissional legalmente
                      habilitado e resguardem a segurança e a saúde dos
                      trabalhadores.
                    </div>
                    <div>
                      <strong>Item 12.11.4</strong> A manutenção de máquinas e
                      equipamentos contemplará, quando indicado pelo fabricante,
                      dentre outros itens, a realização de Ensaios Não
                      Destrutivos - ENDs, nas estruturas e componentes
                      submetidos a solicitações de força e cuja ruptura ou
                      desgaste possa ocasionar acidentes.
                    </div>
                    <div>
                      <strong>Item 12.11.4.1</strong> Os ENDs, quando
                      realizados, devem atender às normas técnicas oficiais ou
                      normas técnicas internacionais aplicáveis.
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>37</h3>
                  </div>

                  <div class="card-body">
                    <div>
                      <strong>Item 12.11.5</strong> Nas manutenções das máquinas
                      e equipamentos, sempre que detectado qualquer defeito em
                      peça ou componente que comprometa a segurança, deve ser
                      providenciada sua reparação ou substituição imediata por
                      outra peça ou componente original ou equivalente, de modo
                      a garantir as mesmas características e condições seguras
                      de uso.
                    </div>
                    <Situation
                      defaultvalue="b"
                      code="4d6s5f465df4g65gd"
                      data={this.state.Situation}
                    />
                    <Jtf
                      code="y6546548sdth98rht"
                      maxLength={1000}
                      data={this.state.Jtf}
                    />

                    <Obs code="sdfh54g5fh4d98hj" data={this.state.Obs} />
                    <Rgt code="ÒIgpiugiu" data={this.state.Rgt} />
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>38</h3>
                  </div>

                  <div class="card-body">
                    <strong>6.12 Sinalização</strong>
                    <br />
                    <p>
                      Item <strong>12.12.1</strong> As máquinas e equipamentos,
                      bem como as instalações em que se encontram, devem possuir
                      sinalização de segurança para advertir os trabalhadores e
                      terceiros sobre os riscos a que estão expostos, as
                      instruções de operação e manutenção e outras informações
                      necessárias para garantir a integridade física e a saúde
                      dos trabalhadores.
                    </p>

                    <Situation
                      defaultvalue="b"
                      code="gfh51665hgdf"
                      data={this.state.Situation}
                    />
                    <Jtf code="IOUGiygf" data={this.state.Jtf} />
                    <Rgt multiple code="hgp9uipiu" data={this.state.Rgt} />

                    <p>
                      Item <strong>12.12.1.1</strong> A sinalização de segurança
                      compreende a utilização de cores, símbolos, inscrições,
                      sinais luminosos ou sonoros, entre outras formas de
                      comunicação de mesma eficácia.
                    </p>

                    <Situation
                      defaultvalue="b"
                      code="gfh51665hgdf"
                      data={this.state.Situation}
                    />
                    <Jtf code="fgfh65654gh" data={this.state.Jtf} />
                    <Rgt multiple code="rtsfghd655" data={this.state.Rgt} />

                    <br />

                    <p>
                      Item <strong>12.12.1.2</strong> A sinalização, inclusive
                      cores, das máquinas e equipamentos utilizados nos setores
                      alimentícios, médico e farmacêutico deve respeitar a
                      legislação sanitária vigente, sem prejuízo da segurança e
                      saúde dos trabalhadores ou terceiros.
                    </p>

                    <Situation
                      code="gfh5sthgf5hgdf"
                      data={this.state.Situation}
                    />
                    <Jtf code="fgfer6ue6tgf" data={this.state.Jtf} />
                    <Rgt multiple code="rtfdjhfjyd" data={this.state.Rgt} />
                    <br />
                    <p>
                      Item <strong>12.12.1.3</strong> A sinalização de segurança
                      deve ser adotada em todas as fases de utilização e vida
                      útil das máquinas e equipamentos
                    </p>
                    <br />
                    <Situation
                      defaultvalue="b"
                      code="gfh5sthgf5hgdf2"
                      data={this.state.Situation}
                    />
                    <Jtf code="fgfer6ue6tgf2" data={this.state.Jtf} />
                    <Rgt multiple code="rtfdjhfjyd2" data={this.state.Rgt} />

                    <br />
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>39</h3>
                  </div>

                  <div class="card-body">
                    <div>
                      <strong>Item 12.12.2</strong> A sinalização de segurança
                      deve:
                    </div>
                    <div>
                      <p>
                        <strong>a)</strong> ficar destacada na máquina ou
                        equipamento;
                      </p>
                      <p>
                        <strong>b)</strong> ficar em localização claramente
                        visível; e
                      </p>
                      <p>
                        <strong>c)</strong> ser de fácil compreensão.
                      </p>
                    </div>
                    <Situation
                      defaultvalue="b"
                      code="fgjhfdutrghy"
                      data={this.state.Situation}
                    />
                    <Jtf code="srtyydjfghjt" data={this.state.Jtf} />
                    <Rgt multiple code="fghfdgfgrtu" data={this.state.Rgt} />
                    <br />
                    <p>
                      Item <strong>12.12.4</strong> As inscrições das máquinas e
                      equipamentos devem:
                    </p>
                    <p>
                      <strong>a)</strong> ser escritas na língua portuguesa
                      (Brasil); e
                    </p>
                    <p>
                      <strong>b)</strong> ser legíveis.
                    </p>
                    <Situation
                      code="fgjhffgfdsgh"
                      data={this.state.Situation}
                    />
                    <Jtf code="srtdfghdf" data={this.state.Jtf} />
                    <Rgt multiple code="fdfsfdshfd" data={this.state.Rgt} />
                    <br />
                    <p>
                      Item <strong>12.12.4.1</strong> As inscrições devem
                      indicar claramente o risco e a parte da máquina ou
                      equipamento a que se referem, e não deve ser utilizada
                      somente a inscrição de “perigo”.
                    </p>
                    <Situation
                      defaultvalue="b"
                      code="fgdfurtuiyddsgh"
                      data={this.state.Situation}
                    />
                    <Jtf code="srtgkjdsfhtdfsf" data={this.state.Jtf} />
                    <Rgt multiple code="ffgjyfgfufd" data={this.state.Rgt} />
                    <br />
                    <p>
                      Item <strong>12.12.5</strong> As inscrições e símbolos
                      devem ser utilizados nas máquinas e equipamentos para
                      indicar as suas especificações e limitações técnicas
                      fundamentais à segurança.
                    </p>
                    <Situation
                      code="fgddt654165fdhsgh"
                      defaultvalue="b"
                      data={this.state.Situation}
                    />
                    <Jtf code="srdf5456f4h65dfsf" data={this.state.Jtf} />
                    <Rgt multiple code="ffgjdf2165fd" data={this.state.Rgt} />
                    <br />
                    <div>
                      <strong>Item 12.12.7</strong> As máquinas e equipamentos
                      fabricados a partir de 24 de dezembro de 2011 devem
                      possuir em local visível as seguintes informações
                      indeléveis:
                    </div>
                    <div>
                      <p>
                        <strong>a)</strong> razão social, CNPJ e endereço do
                        fabricante ou importador;
                      </p>
                      <p>
                        <strong>b)</strong> informação sobre tipo, modelo e
                        capacidade;
                      </p>
                      <p>
                        <strong>c)</strong> número de série ou identificação, e
                        ano de fabricação;
                      </p>
                      <p>
                        <strong>d)</strong> número de registro do
                        fabricante/importador ou do profissional legalmente
                        habilitado no Conselho Regional de Engenharia e
                        Agronomia - CREA; e
                      </p>
                      <p>
                        <strong>e)</strong> peso da máquina ou equipamento.
                      </p>
                    </div>

                    <Situation
                      code="fgdfdfg165fdhsgh"
                      defaultvalue="b"
                      data={this.state.Situation}
                    />
                    <Jtf code="srdf545fdgdfgdyrdtf" data={this.state.Jtf} />
                    <Rgt
                      multiple
                      code="ffgasdtgrr2165fd"
                      data={this.state.Rgt}
                    />
                    <br />
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>40</h3>
                  </div>

                  <div class="card-body">
                    <p>
                      Item <strong>12.12.7.1</strong> As máquinas e equipamentos
                      fabricados antes de 24 de dezembro de 2011 devem possuir
                      em local visível as seguintes informações:
                    </p>
                    <p>
                      <strong>a)</strong> informação sobre tipo, modelo e
                      capacidade;{" "}
                    </p>
                    <p>
                      <strong>b)</strong> número de série ou, quando
                      inexistente, identificação atribuída pela empresa.
                    </p>
                    <br />
                    <Situation
                      defaultvalue="b"
                      code="fyt5465dfsfdhsgh"
                      data={this.state.Situation}
                    />
                    <Jtf code="srdf54drt65651htf" data={this.state.Jtf} />

                    <Rgt code="32rsrd54" data={this.state.Rgt} />
                    <br />
                    <p>
                      Item <strong>12.12.8</strong> Para advertir os
                      trabalhadores sobre os possíveis perigos, devem ser
                      instalados dispositivos indicadores, se necessária a
                      leitura qualitativa ou quantitativa para o controle de
                      segurança.
                    </p>

                    <Situation
                      code="fgddtt65.46dsasgh"
                      data={this.state.Situation}
                    />
                    <Jtf code="srdfadfsg65+fsf" data={this.state.Jtf} />
                    <Rgt
                      multiple
                      code="ffgjdfs654dsf5fd"
                      data={this.state.Rgt}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>41</h3>
                  </div>

                  <div class="card-body">
                    <strong>6.13 Manuais</strong>

                    <p>
                      Item <strong>12.13.1</strong> As máquinas e equipamentos
                      devem possuir manual de instruções fornecido pelo
                      fabricante ou importador, com informações relativas à
                      segurança em todas as fases de utilização.
                    </p>

                    <Situation
                      defaultvalue="b"
                      code="32dsrty654fd5g4g"
                      data={this.state.Situation}
                    />
                    <Jtf code="fd5try654fd6g64" data={this.state.Jtf} />
                    <Rgt code="rdfs6545dg65fdh" data={this.state.Rgt} />
                    <Obs code="sdf98485dfg5f54" data={this.state.Obs} />
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>42</h3>
                  </div>

                  <div class="card-body">
                    <strong>6.14 Procedimentos de trabalho e segurança</strong>

                    <p>
                      Item <strong>12.14.1</strong> Devem ser elaborados
                      procedimentos de trabalho e segurança para máquinas e
                      equipamentos, específicos e padronizados, a partir da
                      apreciação de riscos.
                    </p>

                    <Situation
                      code="32dsrytu98dt4g"
                      data={this.state.Situation}
                    />
                    <Jtf code="fd5trst98df64" data={this.state.Jtf} />
                    <Rgt code="rdfsrdg564fgfdh" data={this.state.Rgt} />
                    <Obs code="sdf98dfg54dsf54" data={this.state.Obs} />
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>43</h3>
                  </div>

                  <div class="card-body">
                    <strong>6.15 Capacitação</strong>

                    <p>
                      Item <strong>12.16.1</strong> A operação, manutenção,
                      inspeção e demais intervenções em máquinas e equipamentos
                      devem ser realizadas por trabalhadores habilitados ou
                      qualificados ou capacitados, e autorizados para este fim.
                    </p>

                    <Situation
                      defaultvalue="b"
                      code="32dssdfag56fdt4g"
                      data={this.state.Situation}
                    />
                    <Jtf code="fd5fgh564gfh4" data={this.state.Jtf} />
                    <Rgt code="rdfsrdgadfs56dh" data={this.state.Rgt} />
                    <Obs code="sdf98d654fdsgf4" data={this.state.Obs} />
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>44</h3>
                  </div>

                  <div class="card-body">
                    <strong>6.16 Disposições finais</strong>

                    <p>
                      Item <strong>12.18.1</strong> O empregador deve manter à
                      disposição da Auditoria-Fiscal do Trabalho relação
                      atualizada das máquinas e equipamentos.
                    </p>

                    <Situation code="32dfdgfdhg" data={this.state.Situation} />
                    <Jtf code="fd5dfgfdjhg4" data={this.state.Jtf} />
                    <Rgt code="rdfgjdghdgfh" data={this.state.Rgt} />
                    <Obs code="sd6fghffsgf4" data={this.state.Obs} />
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>45</h3>
                  </div>

                  <div class="card-body">
                    <strong>
                      6.17 Outros requisitos específicos de segurança
                    </strong>

                    <p>
                      Item <strong>12.17.5</strong> Para fins de aplicação desta
                      NR, os Anexos contemplam obrigações, disposições especiais
                      ou exceções que se aplicam a um determinado tipo de
                      máquina ou equipamento, em caráter prioritário aos demais
                      requisitos desta NR, sem prejuízo ao disposto em NR
                      especifica.
                    </p>

                    <Situation
                      defaultvalue="b"
                      code="drtygdsfgdfg"
                      data={this.state.Situation}
                    />
                    <Jtf code="fdfghfgdsrytg4" data={this.state.Jtf} />
                    <Rgt code="OPIguyo8ughiu" data={this.state.Rgt} />
                    <br />
                    <p>
                      <strong>Registro: </strong>Cálculo das distâncias mínimas
                      de segurança para instalação de detectores de presença
                      optoeletrônicos – ESPS usando cortina de luz – AOPD O
                      cálculo da distância mínima, conforme ISO 12855 e NR-12
                      Anexo IB é a seguinte: 𝑆=(𝐾×𝑇)+𝐶 Onde: S = é a mínima
                      distância, em milímetros, da zona de perigo até o ponto,
                      linha ou plano de detecção; K = é um parâmetro em
                      milímetros por segundo, derivado dos dados de velocidade
                      de aproximação do corpo ou partes do corpo; T = é a
                      performance de parada de todo o sistema – tempo de
                      resposta total em segundos; C = é a distância adicional em
                      milímetros, baseada na intrusão contra a zona de perigo
                      antes da atuação do dispositivo de proteção.
                    </p>
                    <br />
                    <DadosCortina data={this.state.CortinaLuz} />
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>48</h3>
                  </div>

                  <div class="card-body">
                    <div>
                      Como a distância real é maior que a distância mínima da
                      zona de perigo até o plano de detecção, o resultado é o
                      seguinte:
                    </div>

                    <div>
                      <div className="mt-3">
                        Item 12.17.5.1 Nas situações onde os itens dos Anexos
                        conflitarem com os itens da parte geral da NR,
                        prevalecem os requisitos do anexo.
                      </div>
                      <div className="mt-3">
                        <Situation
                          code="OBIuyfyf"
                          data={this.state.Situation}
                        />
                        <Jtf code="KYRVSutdfo" data={this.state.Jtf} />
                        <Rgt code="Ktvriby" data={this.state.Rgt} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>PARECER TÉCNICO</h3>
                  </div>

                  <div class="card-body">
                    <div className=" mb-3">Categoria:</div>
                    <TextView code="rfy7iof8y7" data={this.state.TextView} />
                  </div>
                </div>
              </div>
              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>9 - Plano de Ação</h3>
                  </div>
                  <div class="card-body">
                    <div>
                      <Rgt code="thfgdfybesrt" data={this.state.Rgt} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>Controle de revisões</h3>
                  </div>

                  <div class="card-body">
                    <div className=" mb-3">Documento Original.</div>
                    <TextView code="ghfgdutrmty" data={this.state.TextView} />
                  </div>
                </div>
              </div>

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>ANEXOS</h3>
                  </div>

                  <div class="card-body">
                    <div className=" mb-3">Documento Original.</div>
                    <TextView
                      code="OUfgy8gyuiyboi"
                      data={this.state.TextView}
                    />
                  </div>
                </div>
              </div>

              <Anexos
                title="ANEXO I - Anotação de Responsabilidade Técnica – ART "
                data={this.state.Anexos}
                code="IOugyiUYFbi"
              />

              <Anexos
                title="ANEXO II - Certificação de Dispositivos de Segurança "
                data={this.state.Anexos}
                code="IUbfyrvdli"
              />
              <Anexos
                title="ANEXO III - Esquema Elétrico dos dispositivos de segurança e emergência"
                data={this.state.Anexos}
                code="IB6tdfkrF"
              />

              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>ENCERRAMENTO</h3>
                  </div>

                  <div class="card-body">
                    <div className=" mb-3">Data:</div>
                    <TextView code="OUIygfouy" data={this.state.TextView} />
                  </div>
                  <div className="card-footer d-flex justify-content-end">
                    <Link
                      to={`/${window.location.pathname.split("/")[1]}/preview/${
                        info.token
                      }/files`}
                    >
                      <button class="btn btn-xs btn-primary">
                        <FontAwesomeIcon color="#fff" icon={faFileAlt} />{" "}
                        Arquivos
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <StaticDialog
          isOpen={this.state.ModalOpen}
          title="Adicionar imagem"
          onAfterClose={(result) => {
            this.setState({ ModalOpen: false, txt7: "", ImagePreview: "" });
          }}
        >
          <div style={{ margin: 15 }}>
            <input
              type="text"
              class="form-control form-control-sm"
              value={this.state.txt7}
              onChange={(e) => {
                this.setState({ txt7: e.target.value });
              }}
              placeholder="Titulo:"
            />
            <br />

            <div class="area-8">
              {this.state.ImagePreview != "" ? (
                <>
                  <div class="preview">
                    <div class="edit">
                      <input type="file" onChange={(e) => this.AddImg(e)} />
                      <FontAwesomeIcon icon={faPen} color="#fff" />
                    </div>

                    <img src={`${this.state.ImagePreview}`} />
                  </div>
                </>
              ) : (
                <>
                  <div class="add">
                    <input
                      class="form-control"
                      type="file"
                      onChange={(e) => this.AddImg(e)}
                    />
                    <FontAwesomeIcon icon={faPlus} color="#282828" />
                  </div>
                </>
              )}
            </div>

            <hr />

            <div class="d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-primary"
                onClick={() => {
                  const id = toast.loading("Enviando");
                  const formData = new FormData();

                  formData.append("imagem", this.state.upl7[0]);
                  formData.append("titulo", this.state.txt7);
                  fetch(
                    `${apiUrl}/solicitante/add/grupoi/${
                      window.location.pathname.split("/")[3]
                    }/${this.state.itensModal.item.code}`,
                    {
                      method: "POST",
                      body: formData,
                    }
                  ).then((response) => {
                    if (response.status == 200) {
                      response.json().then((data) => {
                        toast.update(id, {
                          render: "Sucesso!",
                          type: "success",
                          isLoading: false,
                          autoClose: 3000,
                        });

                        this.setState((prevState) => {
                          const newGrupois = [...prevState.grupoi];
                          newGrupois[this.state.itensModal.index].list.push({
                            id: data.id,
                            img: data.img,
                          });
                          return { grupoi: newGrupois };
                        });
                        this.setState({
                          ModalOpen: false,
                          txt7: "",
                          ImagePreview: "",
                        });

                        toast.success("Imagem enviada com sucesso!");
                      });
                    }
                  });
                }}
              >
                <FontAwesomeIcon icon={faUpload} />
                Upload
              </button>
            </div>
          </div>
        </StaticDialog>
        <StaticDialog
          isOpen={this.state.slcE}
          title="Selecione o executante:"
          onAfterClose={(result) => {
            this.setState({ slcE: false, itemE: undefined });
          }}
        >
          <div style={{ padding: 10 }}>
            <select
              style={{ width: "100%" }}
              onChange={(e) => {
                var itemE = e.target.value;
                if (itemE == "") {
                  this.setState({ itemE: undefined });
                  return;
                }
                this.setState({ itemE });
              }}
            >
              <option value="">Selecione:</option>
              {this.state.data.exs.map((item) => (
                <>
                  <option value={item.cnpj}>
                    {item.razao_social} - {this.formatCNPJ(item.cnpj)}
                  </option>
                </>
              ))}
            </select>
          </div>
          <hr />

          <div class="d-flex justify-content-end" style={{ margin: 10 }}>
            <button
              type="button"
              class="btn btn-primary"
              disabled={this.state.itemE == undefined ? true : false}
              onClick={(e) => {
                e.target.classList.add("load");
                fetch(
                  `${apiUrl}/laudo-tecnico/${
                    window.location.pathname.split("/")[3]
                  }/atualizar-executante/`,
                  {
                    method: "POST",
                    credentials: "include",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + Cookies.get("auth_token"),
                    },
                    body: JSON.stringify({ itemE: this.state.itemE }),
                  }
                )
                  .then((response) => {
                    if (response.status == 200) {
                      var it = this.state.data.exs.find(
                        (item) => item.cnpj === this.state.itemE
                      );

                      this.setState((prevState) => ({
                        data: {
                          ...prevState.data,
                          executante: {
                            ...prevState.data.executante,
                            razao_social: it.razao_social,
                            cnpj: it.cnpj,
                          },
                        },
                      }));

                      this.setState((prevState) => ({
                        data: {
                          ...prevState.data,
                          info: {
                            ...prevState.data.info,
                            executante: it.cnpj,
                          },
                        },
                      }));

                      e.target.classList.remove("load");
                      toast.success("Sucesso!");
                      this.setState({ slcE: false, itemE: undefined });
                    }
                  })
                  .catch(() => {
                    e.target.classList.remove("load");
                    toast.error("Falha na comunicação");
                  });
              }}
            >
              <FontAwesomeIcon icon={faSave} /> Salvar
            </button>
          </div>
        </StaticDialog>
        <StaticDialog
          isOpen={this.state.upload5}
          title="Adicionar imagem"
          onAfterClose={(result) => {
            this.setState({ ImgPrev: undefined, upload5: false, upl7: [] });
          }}
        >
          <div style={{ marginLeft: 15 }}>
            <div class="area-8">
              {this.state.ImgPrev != undefined ? (
                <>
                  <div class="preview">
                    <div class="edit">
                      <input type="file" onChange={(e) => this.AddImg(e)} />
                      <FontAwesomeIcon icon={faPen} color="#fff" />
                    </div>

                    <img src={`${this.state.ImgPrev}`} />
                  </div>
                </>
              ) : (
                <>
                  <div class="add">
                    <input
                      class="form-control"
                      type="file"
                      onChange={(e) => this.AddImg(e)}
                    />
                    <FontAwesomeIcon icon={faPlus} color="#282828" />
                  </div>
                </>
              )}
            </div>
          </div>
          <hr />

          <div class="d-flex justify-content-end" style={{ margin: 10 }}>
            <button
              type="button"
              class="btn btn-primary"
              disabled={this.state.upl7.length == 0 ? true : false}
              onClick={() => {
                var file = this.state.upl7;

                if (file.length == 0) {
                  return;
                }
                const id = toast.loading("Enviando");

                const formData = new FormData();
                if (file) {
                  Array.from(file).forEach((file) => {
                    formData.append("images", file);
                  });

                  fetch(
                    `${apiUrl}/laudo-tecnico/imagens/` +
                      window.location.pathname.split("/")[3],
                    {
                      method: "POST",
                      body: formData,
                    }
                  ).then((response) => {
                    if (response.status == 200) {
                      response.json().then((data) => {
                        toast.update(id, {
                          render: "Salvo!",
                          type: "success",
                          isLoading: false,
                          autoClose: 3000,
                        });

                        this.setState((prevState) => {
                          const novaLista = [...prevState.images, data[0]];

                          return {
                            images: novaLista,
                          };
                        });
                        this.setState({
                          upload5: false,
                          ImgPrev: undefined,
                          upl7: [],
                        });
                      });
                    }
                  });
                }
              }}
            >
              <FontAwesomeIcon icon={faUpload} />
              Upload
            </button>
          </div>
        </StaticDialog>
        <ToastContainer />
      </>
    );
  }
}

export default PrevLau;

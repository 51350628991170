import React, { Component } from "react";
import { apiUrl } from "../../config";
import ImageView from "../../Comps/ImageView";
import Situation from "../../Comps/Situation";
import Jtf from "../../Comps/Jtf";
import Rgt from "../../Comps/Rgt";
import Obs from "../../Comps/Obs";
import l1 from "../../images/pdf/l1.png";
import TextView from "../../Comps/TextView";
import SituacaoGrafico from "../../Comps/SituacaoGrafico";
import Anexos from "../../Comps/Anexos";
class PreviewLau extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loader: true,
      responses: { a: 0, b: 0, c: 0 },
      TextPage: [],
      CortinaLuz: [],
      Rgt: {},
    };
  }
  componentDidMount() {
    fetch(
      `${apiUrl}/laudo-tecnico/preview/${
        window.location.pathname.split("/")[2]
      }`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((response) => {
      if (response.status == 200) {
        response.json().then((data) => {
          this.setState({
            data,
            TextView: data.TextView,
            info: data.info,
            diagram: data.diagram,
            form: data.form,
            grupoi: data.groupi,
            Loader: false,
            ImageView: data.ImageView,
            Situation: data.Situation,
            Jtf: data.Jtf,
            Rgt: data.Rgt,
            Obs: data.Obs,
            Loader: false,
            TextPage: data.TextPage,
            CortinaLuz: data.CortinaLuz,
            Anexos: data.Anexos,
          });
        });
      }
    });
  }
  NumeroPorExtenso(num) {
    const unidades = [
      "zero",
      "uma",
      "duas",
      "três",
      "quatro",
      "cinco",
      "seis",
      "sete",
      "oito",
      "nove",
    ];

    const dezenas = [
      "",
      "",
      "vinte",
      "trinta",
      "quarenta",
      "cinquenta",
      "sessenta",
      "setenta",
      "oitenta",
      "noventa",
    ];

    const teens = [
      "dez",
      "onze",
      "doze",
      "treze",
      "quatorze",
      "quinze",
      "dezesseis",
      "dezessete",
      "dezoito",
      "dezenove",
    ];

    if (num < 10) return unidades[num];
    if (num < 20) return teens[num - 10];
    if (num < 100) {
      const dezena = Math.floor(num / 10);
      const unidade = num % 10;
      return unidade === 0
        ? dezenas[dezena]
        : `${dezenas[dezena]} e ${unidades[unidade]}`;
    }

    return "Número fora do intervalo suportado";
  }
  quebraLinha = (texto) => {
    if (texto == null || texto == undefined) return "";
    return texto.split("\n").map((linha, index) => (
      <React.Fragment key={index}>
        {linha}
        {index < texto.split("\n").length - 1 && <br />}
      </React.Fragment>
    ));
  };
  DistanciaAdc(v) {
    if (v <= 14) {
      return 0;
    } else if (v > 14 && v <= 20) {
      return 80;
    } else if (v > 20 && v <= 30) {
      return 130;
    } else if (v > 30 && v <= 40) {
      return 240;
    } else if (v > 40) {
      return 850;
    } else {
      return 0;
    }
  }

  handleResponse = (data) => {
    this.setState((prevState) => ({
      responses: {
        ...prevState.responses,
        [data]: prevState.responses[data] + 1, // Incrementa o contador correspondente
      },
    }));
  };

  ShowText(code) {
    var r = this.state.TextView.find((item) => item.code === code);
    return <>{r != undefined ? r.text.toUpperCase() : ""}</>;
  }
  updateMessage = () => {
    this.setState({ message: "Mensagem atualizada!" });
  };

  render() {
    let loopCount = 0;

    const { info } = this.state;

    if (this.state.Loader) {
      return <>Carregando...</>;
    }
    var itens = [
      <>
        <div className="mt-3 mb-3">
          {" "}
          <strong>1 - INTRODUÇÃO E OBJETIVOS</strong>
        </div>
        <p>
          <strong>12.1.1</strong> Esta Norma Regulamentadora - NR e seus anexos
          definem referências técnicas, princípios fundamentais e medidas de
          proteção para resguardar a saúde e a integridade física dos
          trabalhadores e estabelece requisitos mínimos para a prevenção de
          acidentes e doenças do trabalho nas fases de projeto e de utilização
          de máquinas e equipamentos, e ainda à sua fabricação, importação,
          comercialização, exposição e cessão a qualquer título, em todas as
          atividades econômicas, sem prejuízo da observância do disposto nas
          demais NRs aprovadas pela Portaria 916 de 30 de julho de 2019, nas
          normas técnicas oficiais ou nas normas internacionais aplicáveis e, na
          ausência ou omissão destas, opcionalmente, nas normas Européias tipo
          “C” harmonizadas.
        </p>

        <p>
          Por solicitação da Empresa{" "}
          <strong>{this.state.data.executante.razao_social}</strong> fora
          executado a avaliação e o levantamento das condições do equipamento
          denominado <strong>{this.ShowText("Uyfibi76")}</strong> relativos ao
          atendimento dos requisitos da NR-12 aplicáveis, que compõe o presente
          documento denominado Laudo Técnico de Validação em Conformidade com a
          Norma Regulamentadora NR-12.
        </p>
        <p>
          Os levantamentos foram executados pela Empresa HRN MACHINE SEGURANÇA
          EM MÁQUINAS, no ato representado por{" "}
          {this.state.data.tecs.length > 0 ? (
            <strong>
              {this.state.data.tecs[0].nome} - {this.state.data.tecs[0].func}
            </strong>
          ) : (
            "---"
          )}
          . O presente “Laudo Técnico de Validação em Conformidade com a NR-12”
          tem por objetivo verificar a conformidade com a NR-12 e as Normas
          Técnicas aplicáveis do equipamento denominado{" "}
          <strong>{this.ShowText("Uyfibi76")}</strong>.
        </p>
        <div>
          O documento é composto pela avaliação de todos os itens aplicáveis da
          NR-12 e Normas Técnicas Pertinentes. Caso identificado algum item em
          não conformidade, o mesmo será acompanhado de plano de ação para
          correção futura.
        </div>

        <div>O presente Laudo contem:</div>

        <div>
          <ul>
            <li>Descrição técnica do equipamento e seus acessórios.</li>
            <li>
              Identificação de eventuais não conformidades e plano de ação.
            </li>
            <li>Identificação dos itens em conformidade.</li>
            <li>Complemento e finalizações. </li>
            <li>Anexos </li>
          </ul>
        </div>
      </>,
      <>
        <strong>2 - CARACTERIZAÇÃO DA EMPRESA SOLICITANTE</strong>

        <div className="al-c" style={{ marginTop: 20 }}>
          <img src={`${apiUrl}/img/${this.state.data.contratante.logo}`} />
        </div>
        <div style={{ marginTop: 20 }}>
          <div style={{ fontWeight: "bold", marginBottom: 20 }}>
            Razão Social: {this.state.data.contratante.razao_social}
          </div>
          <div style={{ fontWeight: "bold" }}>
            CNPJ : {this.state.data.contratante.cnpj}
          </div>
          <div style={{ fontWeight: "bold", marginTop: 20 }}>
            Endereço : {this.state.data.contratante.endereco}
          </div>
          <div style={{ fontWeight: "bold", marginTop: 20 }}>
            Estado : {this.state.data.contratante.estado}
          </div>
          {this.state.data.contratante.telefone != null && (
            <>
              <div style={{ fontWeight: "bold", marginTop: 20 }}>
                Telefone : {this.state.data.contratante.telefone}
              </div>
            </>
          )}
        </div>
        <div style={{ marginTop: 40 }}>
          <strong>3 - IDENTIFICAÇÃO DO EXECUTANTE</strong>
        </div>
        <div className="al-c" style={{ marginTop: 20 }}>
          <img src={`${apiUrl}/img/${this.state.data.executante.logo}`} />
        </div>
        <div style={{ marginTop: 20 }}>
          <div style={{ fontWeight: "bold", marginBottom: 20 }}>
            Razão Social: {this.state.data.executante.razao_social}
          </div>
          <div style={{ fontWeight: "bold" }}>
            CNPJ : {this.state.data.executante.cnpj}
          </div>
          <div style={{ fontWeight: "bold", marginTop: 20 }}>
            Endereço : {this.state.data.executante.endereco}
          </div>
          <div style={{ fontWeight: "bold", marginTop: 20 }}>
            Estado : {this.state.data.executante.estado}
          </div>
          {this.state.data.executante.telefone != null && (
            <>
              <div style={{ fontWeight: "bold", marginTop: 20 }}>
                Telefone : {this.state.data.executante.telefone}
              </div>
            </>
          )}
        </div>
      </>,
      <>
        <strong>4 - RESPONSÁVEIS TÉCNICOS</strong>
        <div>
          {this.state.data.doc_tecs.map((item) => (
            <>
              <strong>Responsável Técnico:</strong> {item.nome} - {item.func}
            </>
          ))}
        </div>
      </>,
    ];

    var itens2 = [
      <>
        <strong>6 - AVALIAÇÃO</strong>
        <div className="">
          <strong style={{ marginLeft: 0 }}>6.1 - Princípios gerais</strong>
        </div>
        <div>
          <p>
            <strong>12.1.1</strong> Esta Norma Regulamentadora - NR e seus
            anexos definem referências técnicas, princípios fundamentais e
            medidas de proteção para resguardar a saúde e a integridade física
            dos trabalhadores e estabelece requisitos mínimos para a prevenção
            de acidentes e doenças do trabalho nas fases de projeto e de
            utilização de máquinas e equipamentos, e ainda à sua fabricação,
            importação, comercialização, exposição e cessão a qualquer título,
            em todas as atividades econômicas, sem prejuízo da observância do
            disposto nas demais NRs aprovadas pela Portaria MTb n.º 3.214, de 8
            de junho de 1978, nas normas técnicas oficiais ou nas normas
            internacionais aplicáveis e, na ausência ou omissão destas,
            opcionalmente, nas normas Europeias tipo “C” harmonizadas.
          </p>

          <p>
            <strong> Item 12.1.1.1 </strong>Entende-se como fase de utilização o
            transporte, montagem, instalação, ajuste, operação, limpeza,
            manutenção, inspeção, desativação e desmonte da máquina ou
            equipamento.
          </p>

          <p>
            <p>
              <strong> Item 12.1.12</strong> Os sistemas robóticos que obedeçam
              às prescrições das normas ABNT ISO 10218-1, ABNT ISO 10218-2, da
              ISO/TS 15066 e demais normas técnicas oficiais ou, na ausência ou
              omissão destas, nas normas internacionais aplicáveis, estão em
              conformidade com os requisitos de segurança previstos nessa NR.
            </p>
          </p>
          <p>
            <strong>Itens: 12.1.1.1 a 12.1.12 </strong>
          </p>

          <Situation
            code="21d2rty5"
            data={this.state.data.Situation}
            resp={this.handleResponse}
          />
        </div>
        <Jtf code="65ds465ty" data={this.state.data.Jtf} />
        <Rgt code="32s1rth5y" data={this.state.Rgt} />
        <Obs code="654th654t" data={this.state.Obs} />
        <p>
          <strong> 12.1.9</strong> Naa aplicação desta NR e de seus anexos,
          devem-se considerar as características das máquinas e equipamentos, do
          processo, a apreciação de riscos e o estado da técnica.
        </p>
        <p>
          <strong> Item 12.1.9.1</strong> A adoção de sistemas de segurança nas
          zonas de perigo deve considerar as características técnicas da máquina
          e do processo de trabalho e as medidas e alternativas técnicas
          existentes, de modo a atingir o nível necessário de segurança previsto
          nesta NR.
        </p>
        <Situation
          resp={this.handleResponse}
          code="3yfgjgh156"
          data={this.state.Situation}
        />
        <Jtf code="6fdg6515f" data={this.state.Jtf} />
        <Rgt code="321k89ads" data={this.state.Rgt} />
        <Obs code="fgh6565dt" data={this.state.Obs} />
        <strong>12.2</strong> Arranjo físico e instalações.
        <div style={{ marginTop: 10 }}>
          <p>
            <strong>Item 12.2.1</strong> Nos locais de instalação de máquinas e
            equipamentos, as áreas de circulação devem ser devidamente
            demarcadas em conformidade com as normas técnicas oficiais.
          </p>
          <p>
            <strong>Item 12.2.1.1</strong> É permitida a demarcação das áreas de
            circulação utilizando-se marcos, balizas ou outros meios físicos.
          </p>
          <p>
            <strong>Item 12.2.1.2</strong> As áreas de circulação devem ser
            mantidas desobstruídas.
          </p>
          <p>
            <strong>Item 12.2.2</strong> A distância mínima entre máquinas, em
            conformidade com suas características e aplicações, deve resguardar
            a segurança dos trabalhadores durante sua operação, manutenção,
            ajuste, limpeza e inspeção, e permitir a movimentação dos segmentos
            corporais, em face da natureza da tarefa.
          </p>
          <p>
            <strong>Item 12.2.3</strong> As áreas de circulação e armazenamento
            de materiais e os espaços em torno de máquinas devem ser projetados,
            dimensionados e mantidos de forma que os trabalhadores e os
            transportadores de materiais, mecanizados e manuais, movimentem-se
            com segurança.
          </p>
          <p>
            <strong>Item 12.2.4</strong> O piso do local de trabalho onde se
            instalam máquinas e equipamentos e das áreas de circulação devem ser
            resistentes às cargas a que estão sujeitos e não devem oferecer
            riscos de acidentes.
          </p>
          <p>
            <strong>Item 12.2.5</strong> As ferramentas utilizadas no processo
            produtivo devem ser organizadas e armazenadas ou dispostas em locais
            específicos para essa finalidade.
          </p>
        </div>
      </>,
      <>
        <div className="mt-3 mb-3">
          <strong>
            Itens:12.2.1, 12.2.1.1, 12.2.1.2, 12.2.2, 12.2.3, 12.2.4, 12.2.5
          </strong>
        </div>

        <Situation
          code="3yfdfghtry"
          resp={this.handleResponse}
          data={this.state.Situation}
        />
        <Jtf code="6dgadfh6515f" data={this.state.Jtf} />
        <Rgt code="321fdzhhfdds" data={this.state.Rgt} />
        <Obs code="fghsrthfd5dt" data={this.state.Obs} />

        <p>
          <strong>Item 12.2.6</strong> As máquinas estacionárias devem possuir
          medidas preventivas quanto à sua estabilidade, de modo que não
          basculem e não se desloquem intempestivamente por vibrações, choques,
          forças externas previsíveis, forças dinâmicas internas ou qualquer
          outro motivo acidental.
        </p>
        <Situation
          resp={this.handleResponse}
          code="3246rdfg"
          data={this.state.Situation}
        />
        <Jtf code="fd56h465h564" data={this.state.Jtf} />
        <Rgt code="rtsh6545gh" data={this.state.Rgt} />
        <Obs code="sd654h6f85dg4" data={this.state.Obs} />
        <div className="mt-3 mb-3">
          <strong> Item 12.2.6.1</strong> As máquinas estacionárias instaladas a
          partir da Portaria SIT n.º 197, de 17 de dezembro de 2010, D.O.U. de
          24/12/2010, devem respeitar os requisitos necessários fornecidos pelos
          fabricantes ou, na falta desses, o projeto elaborado por profissional
          legalmente habilitado quanto à fundação, fixação, amortecimento,
          nivelamento.
        </div>

        <Situation
          resp={this.handleResponse}
          code="8re9755fd6"
          data={this.state.Situation}
        />
        <Jtf code="df5g4df4g65" data={this.state.Jtf} />
        <Rgt code="df426dfh54f" data={this.state.Rgt} />
        <Obs code="324sdf65fhb" data={this.state.Obs} />

        <div className="mt-3 mb-3">
          <strong>Item 12.2.7</strong> Nas máquinas móveis que possuem rodízios,
          pelo menos dois deles devem possuir travas.
        </div>
        <div className="mt-3 mb-3">
          <strong>Item 12.2.8</strong> As máquinas, as áreas de circulação, os
          postos de trabalho e quaisquer outros locais em que possa haver
          trabalhadores devem ficar posicionados de modo que não ocorra
          transporte e movimentação aérea de materiais sobre os trabalhadores.
        </div>
        <div className="mt-3 mb-3">
          <strong>Itens:12.2.7, 12.2.8</strong>
        </div>
        <Situation
          resp={this.handleResponse}
          code="321rsd8t94fd"
          data={this.state.Situation}
        />
        <Jtf code="dar32132fdzs1h" data={this.state.Jtf} />
        <Rgt code="dar32165f6d5f" data={this.state.Rgt} />
        <Obs code="321d6r54g5df6" data={this.state.Obs} />
      </>,
      <>
        <div>
          <p>
            <strong>12.3</strong> Instalações e dispositivos elétricos.
          </p>

          <p>
            <strong> Item 12.3.1</strong> Os circuitos elétricos de comando e
            potência das máquinas e equipamentos devem ser projetadas e mantidas
            de modo a prevenir, por meios seguros, os perigos de choque
            elétrico, incêndio, explosão e outros tipos de acidentes, conforme
            previsto nas normas técnicas oficiais e, na falta dessas, nas normas
            internacionais aplicáveis.
          </p>
        </div>

        <Situation
          resp={this.handleResponse}
          code="3214df35g1fd"
          data={this.state.Situation}
        />
        <Jtf code="231fh3gfhgfyt" data={this.state.Jtf} />
        <Rgt code="hgk216a5ew65ui" data={this.state.Rgt} />
      </>,
      <>
        <Obs code="23sd1321sb" data={this.state.Obs} />
        <div>
          <p>
            tem <strong>12.3.2</strong> Devem ser aterrados, conforme as normas
            técnicas oficiais vigentes, as carcaças, invólucros, blindagens ou
            partes condutoras das máquinas e equipamentos que não façam parte
            dos circuitos elétricos, mas que possam ficar sob tensão.
          </p>
        </div>
        <Situation
          resp={this.handleResponse}
          code="32as1r32g1fd"
          data={this.state.Situation}
        />
        <Jtf code="32aer165dsf" data={this.state.Jtf} />
        <Rgt code="sdr21321re4" data={this.state.Rgt} />
        <Obs code="er6541dfs56er" data={this.state.Obs} />
        <div>
          <p>
            <strong> Item 12.3.3</strong> Os circuitos elétricos de comando e
            potência das máquinas e equipamentos que estejam ou possam estar em
            contato direto ou indireto com água ou agentes corrosivos devem ser
            projetadas com meios e dispositivos que garantam sua blindagem,
            estanqueidade, isolamento e aterramento, de modo a prevenir a
            ocorrência de acidentes.
          </p>
        </div>

        <Situation
          resp={this.handleResponse}
          code="aer23164yk65"
          data={this.state.Situation}
        />
        <Jtf code="sae32132sd" data={this.state.Jtf} />
        <Rgt code="231654sfds" data={this.state.Rgt} />
        <Obs code="q21tr132fgh56" data={this.state.Obs} />
      </>,
      <>
        <div className="mt-3">
          <strong>Item 12.3.4</strong> Os condutores de alimentação elétrica das
          máquinas e equipamentos devem atender aos seguintes requisitos mínimos
          de segurança:
        </div>

        <div className="mt-3">
          a) oferecer resistência mecânica compatível com a sua utilização;
        </div>

        <div>
          b) possuir proteção contra a possibilidade de rompimento mecânico, de
          contatos abrasivos e de contato com lubrificantes, combustíveis e
          calor;
        </div>

        <div>
          c) localização de forma que nenhum segmento fique em contato com as
          partes móveis ou cantos vivos;
        </div>

        <div>
          d) não dificultar o trânsito de pessoas e materiais ou a operação das
          máquinas;
        </div>

        <div>
          e) não oferecer quaisquer outros tipos de riscos na sua localização; e
        </div>

        <div className="mb-3">
          f) ser constituídos de materiais que não propaguem o fogo.
        </div>

        <Situation
          resp={this.handleResponse}
          code="321dsf6541"
          data={this.state.Situation}
        />
        <Jtf code="a2123cvb1re" data={this.state.Jtf} />
        <Rgt code="23z1d6541fd" data={this.state.Rgt} />
      </>,
      <>
        <div>
          <p>
            <strong> Item 12.3.5</strong> Os quadros ou painéis de comando e
            potência das máquinas e equipamentos devem atender aos seguintes
            requisitos mínimos de segurança:
          </p>
        </div>
        <div>
          <p>
            a) possuir porta de acesso mantida permanentemente fechada, exceto
            nas situações de manutenção, pesquisa de defeitos e outras
            intervenções, devendo ser observadas as condições previstas nas
            normas técnicas oficiais ou nas normas internacionais aplicáveis;
          </p>
          <p>
            b) possuir sinalização quanto ao perigo de choque elétrico e
            restrição de acesso por pessoas não autorizadas;{" "}
          </p>
          <p>
            c) ser mantidos em bom estado de conservação, limpos e livres de
            objetos e ferramentas;{" "}
          </p>
          <p>d) possuir proteção e identificação dos circuitos; e </p>
          <p>
            e) observar ao grau de proteção adequado em função do ambiente de
            uso.{" "}
          </p>
        </div>
        <Situation
          resp={this.handleResponse}
          code="s65d4654fd5"
          data={this.state.Situation}
        />
        <Jtf code="32zdf1g651" data={this.state.Jtf} />
        <Rgt code="32d1f3fgh" data={this.state.Rgt} />
        <Obs code="hg2k3151sd51" data={this.state.Obs} />
      </>,

      <>
        <p>
          <strong> Item 12.3.6</strong> As ligações e derivações dos condutores
          elétricos das máquinas e equipamentos devem ser feitas mediante
          dispositivos apropriados e conforme as normas técnicas oficiais
          vigentes, de modo a assegurar resistência mecânica e contato elétrico
          adequado, com características equivalentes aos condutores elétricos
          utilizados e proteção contra riscos.
        </p>

        <Situation
          resp={this.handleResponse}
          code="32c132c32"
          data={this.state.Situation}
        />
        <Jtf code="df1s65fdf" data={this.state.Jtf} />
        <Rgt code="321sdfh524155" data={this.state.Rgt} />
        <Obs code="sfd3h5165fggf" data={this.state.Obs} />
      </>,
      <>
        <p>
          <strong> Item 12.3.7</strong> As instalações elétricas das máquinas e
          equipamentos que utilizem energia elétrica fornecida por fonte externa
          devem possuir dispositivo protetor contra sobrecorrente, dimensionado
          conforme a demanda de consumo do circuito.
        </p>
        <p>
          <strong> Item 12.3.7.1</strong> As máquinas e equipamentos devem
          possuir dispositivo protetor contra sobretensão quando a elevação da
          tensão puder ocasionar risco de acidentes.
        </p>
        <div>
          <Situation
            resp={this.handleResponse}
            code="drf3213fd"
            data={this.state.Situation}
          />
          <Jtf code="231525tr5dfh2" data={this.state.Jtf} />
          <Rgt code="254r8s4655df46" data={this.state.Rgt} />
        </div>
      </>,
      <>
        <div>
          <p>
            <strong>Item 12.3.7.2</strong> Nas máquinas e equipamentos em que a
            falta ou a inversão de fases da alimentação elétrica puder ocasionar
            riscos, deve haver dispositivo que impeça a ocorrência de acidentes.
          </p>
        </div>

        <Situation
          resp={this.handleResponse}
          code="321df321fd"
          data={this.state.Situation}
        />
        <Jtf code="32df13213fg" data={this.state.Jtf} />
        <Rgt code="sd321635fh65fg" data={this.state.Rgt} />
        <Obs code="gh21654gra65e" data={this.state.Obs} />

        <p>
          <strong> Item 12.3.8</strong> São proibidas nas máquinas e
          equipamentos:
        </p>

        <div>
          <p>
            a) a utilização de chave geral como dispositivo de partida e parada;{" "}
          </p>
          <p>b) a utilização de chaves tipo faca nos circuitos elétricos; e </p>
          <p>
            c) a existência de partes energizadas expostas de circuitos que
            utilizam energia elétrica.{" "}
          </p>
        </div>
        <div>
          <Situation
            resp={this.handleResponse}
            code="231f365gf"
            data={this.state.Situation}
          />
          <Jtf code="32d1f531fg" data={this.state.Jtf} />
          <Rgt code="32jk1l65asfg" data={this.state.Rgt} />
          <Obs code="654th654t" data={this.state.Obs} />
        </div>
        <div style={{ marginTop: 10 }}>
          <p>
            <strong> Item 12.3.9</strong> As baterias devem atender aos
            seguintes requisitos mínimos de segurança:{" "}
          </p>
          <div>
            <p>
              a) localização de modo que sua manutenção e troca possam ser
              realizadas facilmente a partir do solo ou de uma plataforma de
              apoio;{" "}
            </p>
            <p>
              b) constituição e fixação de forma a não haver deslocamento
              acidental; e{" "}
            </p>
            <p>
              c) proteção do terminal positivo, a fim de prevenir contato
              acidental e curto-circuito.{" "}
            </p>
          </div>
          <div>
            <strong> Item: 12.3.10</strong> Os serviços e substituições de
            baterias devem ser realizados conforme indicação constante do manual
            de operação.
          </div>
          <Situation
            resp={this.handleResponse}
            code="321x6f3516"
            data={this.state.Situation}
          />
          <Jtf code="23sd165vd5" data={this.state.Jtf} />
          <Rgt code="23165sd4d" data={this.state.Rgt} />
          <Obs code="231sda65g4df" data={this.state.Obs} />
        </div>
      </>,
      <>
        <div style={{ marginTop: 10 }}>
          <strong style={{ textAlign: "center" }}>
            6.4 Dispositivos de Partida Acionamento e Paradas
          </strong>
          <div className="mt-2">
            <p>
              <strong> Item 12.4.1</strong> Os dispositivos de partida,
              acionamento e parada das máquinas devem ser projetados,
            </p>
          </div>
        </div>
        <p>selecionados e instalados de modo que: </p>
        <div>
          <p>a) não se localizem em suas zonas perigosas;</p>
          <p>
            b) possam ser acionados ou desligados em caso de emergência por
            outra pessoa que não seja o operador;
          </p>
          <p>
            c) impeçam acionamento ou desligamento involuntário pelo operador ou
            por qualquer outra forma acidental;
          </p>
          <p>d) não acarretem riscos adicionais; e</p>
          <p>e) dificulte-se a burla.</p>
        </div>
        <div>
          <Situation
            resp={this.handleResponse}
            code="52fd45fg4"
            data={this.state.Situation}
          />
          <Jtf code="ft216fg5465h" data={this.state.Jtf} />
          <Rgt code="23df1g3213f" data={this.state.Rgt} />
          <Obs code="23rtd1s32fg" data={this.state.Obs} />
        </div>
      </>,
      <>
        <div>
          <p>
            <strong>Item 12.4.2</strong> Os comandos de partida ou acionamento
            das máquinas devem possuir dispositivos que impeçam seu
            funcionamento automático ao serem energizadas.
          </p>
        </div>
        <Situation
          resp={this.handleResponse}
          code="5dfzghfd"
          data={this.state.Situation}
        />
        <Jtf code="ft21fsshfsd" data={this.state.Jtf} />
        <Rgt code="23dsrytsdfg" data={this.state.Rgt} />
        <Obs code="2dyureasods" data={this.state.Obs} />
        <div>
          <div>
            <p>
              <strong> Item 12.4.3</strong> Quando forem utilizados dispositivos
              de acionamento bimanual, visando a manter as mãos do operador fora
              da zona de perigo, esses devem atender aos seguintes requisitos
              mínimos do comando:
            </p>

            <div>
              <p>
                a) possuir atuação síncrona, ou seja, um sinal de saída deve ser
                gerado somente quando os dois dispositivos de atuação do comando
                - botões - forem atuados com um retardo de tempo menor ou igual
                a 0,5 s (meio segundo);
              </p>
              <p>
                b) estar sob monitoramento automático por interface de
                segurança, se indicado pela apreciação de risco;
              </p>
              <p>
                c) ter relação entre os sinais de entrada e saída, de modo que
                os sinais de entrada aplicados a cada um dos dois dispositivos
                de atuação devem juntos se iniciar e manter o sinal de saída
                somente durante a aplicação dos dois sinais;
              </p>
              <p>
                d) o sinal de saída deve terminar quando houver desacionamento
                de qualquer dos dispositivos de atuação;
              </p>
              <p>
                e) possuir dispositivos de atuação que exijam intenção do
                operador em acioná-los a fim de minimizar a probabilidade de
                acionamento acidental;
              </p>
              <p>
                f) possuir distanciamento, barreiras ou outra solução prevista
                nas normas técnicas oficiais ou nas normas internacionais
                aplicáveis entre os dispositivos de atuação para dificultar a
                burla do efeito de proteção;
              </p>
              <p>
                g) tornar possível o reinício do sinal de saída somente após a
                desativação dos dois dispositivos de atuação.
              </p>
            </div>
          </div>

          <Situation
            resp={this.handleResponse}
            code="5dfdfreewd"
            data={this.state.Situation}
          />
          <Jtf code="ft2srtuasksd" data={this.state.Jtf} />
          <Rgt code="23dsdrysdrtg" data={this.state.Rgt} />
          <Obs code="2dasdrtgdrss" data={this.state.Obs} />
        </div>
      </>,
      <>
        <p>
          <strong> Item 12.4.4</strong> Nas máquinas e equipamentos operados por
          dois ou mais dispositivos de acionamento bimanual, a atuação síncrona
          é requerida somente para cada um dos dispositivos de acionamento
          bimanual e não entre dispositivos diferentes, que devem manter
          simultaneidade entre si.
        </p>

        <Situation
          resp={this.handleResponse}
          code="321df6351"
          data={this.state.Situation}
        />

        <Jtf code="df5464165fd4" data={this.state.Jtf} />

        <Rgt code="OIug8yGFyf" data={this.state.Rgt} />

        <p>
          <strong> Item 12.4.5</strong> Os dispositivos de acionamento bimanual
          devem ser posicionados a uma distância segura da zona de perigo,
          levando em consideração:
        </p>
        <p>
          <strong>a)</strong> a forma, a disposição e o tempo de resposta do
          dispositivo de acionamento bimanual;
        </p>
        <p>
          <strong>b)</strong> o tempo máximo necessário para a paralisação da
          máquina ou para a remoção do perigo, após o término do sinal de saída
          do dispositivo de acionamento bimanual; e
        </p>
        <p>
          <strong>c)</strong> a utilização projetada para a máquina.
        </p>
        <Situation
          resp={this.handleResponse}
          code="21f35f5gds"
          data={this.state.Situation}
        />

        <Jtf code="5d6s4654dfdsf" data={this.state.Jtf} />

        <Rgt code="OUYHfgou" data={this.state.Rgt} />
        <p>
          <strong> Item 12.4.6</strong> Os dispositivos de acionamento bimanual
          móveis instalados em pedestais devem:{" "}
        </p>
        <p>
          <strong>a)</strong> manter-se estáveis em sua posição de trabalho; e
        </p>
        <p>
          <strong>b)</strong> possuir altura compatível com o alcance do
          operador em sua posição de trabalho.
        </p>

        <Situation
          resp={this.handleResponse}
          code="231rt6s54er8"
          data={this.state.Situation}
        />
        <Jtf code="32ds4654dffsad" data={this.state.Jtf} />
        <Rgt code="56f4654fd6gh56" data={this.state.Rgt} />
        <Obs code="a32sd4165df4g6" data={this.state.Obs} />

        <p>
          <strong> Item 12.4.7</strong> Nas máquinas e equipamentos cuja
          operação requeira a participação de mais de uma pessoa, o número de
          dispositivos de acionamento bimanual simultâneos deve corresponder ao
          número de operadores expostos aos perigos decorrentes de seu
          acionamento, de modo que o nível de proteção seja o mesmo para cada
          trabalhador.
        </p>
        <p>
          Item: <strong>12.30.2</strong> O circuito de acionamento deve ser
          projetado de modo a impedir o funcionamento dos dispositivos de
          acionamento bimanual habilitados pelo seletor enquanto os demais
          comandos não habilitados não forem desconectados.{" "}
        </p>
      </>,
      <>
        <div className="mb-3">
          <strong>Item 12.4.7.1</strong> Deve haver seletor do número de
          dispositivos de acionamento em utilização, com bloqueio que impeça a
          sua seleção por pessoas não autorizadas.
        </div>

        <div className="mb-3">
          <strong>Item 12.4.7.2</strong> O circuito de acionamento deve ser
          projetado de modo a impedir o funcionamento dos dispositivos de
          acionamento bimanual habilitados pelo seletor enquanto os demais
          dispositivos de acionamento bimanuais não habilitados não forem
          desconectados.
        </div>

        <div className="mb-3">
          <strong>Item 12.4.7.3</strong> Quando utilizados dois ou mais
          dispositivos de acionamento bimanual simultâneos, devem possuir sinal
          luminoso que indique seu funcionamento.
        </div>

        <Situation
          resp={this.handleResponse}
          code="321rse564t"
          data={this.state.Situation}
        />
        <Jtf code="32546fd465fg" data={this.state.Jtf} />
        <Rgt code="32rew545t46f5" data={this.state.Rgt} />
        <Obs code="df564165f4ghf" data={this.state.Obs} />

        <div className="mb-3">
          <strong>Item 12.4.8</strong> As máquinas ou equipamentos concebidos e
          fabricados para permitir a utilização de vários modos de comando ou de
          funcionamento que apresentem níveis de segurança diferentes devem
          possuir um seletor que atenda aos seguintes requisitos:
        </div>

        <div>
          <p>
            <strong>a)</strong> possibilidade de bloqueio em cada posição,
            impedindo a sua mudança por pessoas não autorizadas;
          </p>
          <p>
            <strong>b)</strong> correspondência de cada posição a um único modo
            de comando ou de funcionamento;
          </p>
          <p>
            <strong>c)</strong> modo de comando selecionado com prioridade sobre
            todos os outros sistemas de comando, com exceção da parada de
            emergência; e
          </p>
          <p>
            <strong>d)</strong> a seleção deve ser visível, clara e facilmente
            identificável.
          </p>
        </div>
        <Situation
          resp={this.handleResponse}
          code="52fg4654"
          data={this.state.Situation}
        />
        <Jtf code="21t564fg6554" data={this.state.Jtf} />
        <Rgt code="32fh65fg455t" data={this.state.Rgt} />
        <div className="mb-3">
          {" "}
          <strong>Item 12.4.9</strong> As máquinas e equipamentos, cujo
          acionamento por pessoas não autorizadas possa oferecer risco à saúde
          ou integridade física de qualquer pessoa, devem possuir sistema que
          possibilite o bloqueio de seus dispositivos de acionamento.
        </div>

        <div className="mb-3">
          <strong>Item 12.4.10</strong> O acionamento e o desligamento
          simultâneo por um único comando de um conjunto de máquinas e
          equipamentos ou de máquinas e equipamentos de grande dimensão devem
          ser precedidos da emissão de sinal sonoro ou visual.
        </div>

        <div className="mb-3">
          <strong>Item 12.4.11</strong> Devem ser adotadas, quando necessárias,
          medidas adicionais de alerta, como sinal visual e dispositivos de
          telecomunicação, considerando as características do processo produtivo
          e dos trabalhadores.
        </div>
      </>,
      <>
        <div className="mb-3">
          <strong>Item 12.4.12</strong> As máquinas e equipamentos comandados
          por radiofrequência devem possuir proteção contra interferências
          eletromagnéticas acidentais.
        </div>
        <Situation
          resp={this.handleResponse}
          code="541g65fd465"
          data={this.state.Situation}
        />
        <Jtf code="2df165g46fgf" data={this.state.Jtf} />
        <Rgt code="d23f1g531df65" data={this.state.Rgt} />
        <Obs code="231df564f665g" data={this.state.Obs} />
        <p>
          <strong> Item 12.4.13</strong> Os componentes de partida, parada,
          acionamento e controles que compõem a interface de operação das
          máquinas e equipamentos fabricados a partir de 24 de Março de 2012
          devem:
        </p>
        <div>
          <p>
            <strong>a)</strong> possibilitar a instalação e funcionamento do
            sistema de parada de emergência, quando aplicável, conforme itens e
            subitens do capítulo sobre dispositivos de parada de emergência,
            desta NR; e
          </p>
          <p>
            <strong>b)</strong> operar em extrabaixa tensão de até 25VCA (vinte
            e cinco volts em corrente alternada) ou de até 60VCC (sessenta volts
            em corrente contínua).
          </p>
        </div>
        <Situation
          resp={this.handleResponse}
          code="56f1d65416df4"
          data={this.state.Situation}
        />
        <Jtf code="3fdg5646dfg" data={this.state.Jtf} />
        <Rgt code="3df6546fd6f5" data={this.state.Rgt} />
        <Obs code="df5df65f4g6hf" data={this.state.Obs} />
        <div className="mb-3">
          <strong>Item 12.4.13.1</strong> Os componentes de partida, parada,
          acionamento e controles que compõem a interface de operação das
          máquinas e equipamentos fabricados até 24 de março de 2012 devem:
        </div>
        <div>
          <p>
            <strong>a)</strong> possibilitar a instalação e funcionamento do
            sistema de parada de emergência, quando aplicável, conforme itens e
            subitens do capítulo dispositivos de parada de emergência, desta NR;
            e
          </p>
          <p>
            <strong>b)</strong> quando a apreciação de risco indicar a
            necessidade de proteções contra choques elétricos,operar em
            extrabaixa tensão de até 25VCA (vinte e cinco volts em corrente
            alternada) ou de até 60VCC (sessenta volts em corrente contínua).
          </p>
        </div>
        <Situation
          resp={this.handleResponse}
          code="5fdghfgf4"
          data={this.state.Situation}
        />
        <Jtf code="3fdfhsgfdsf" data={this.state.Jtf} />
        <Rgt code="3dghdfgfdfd" data={this.state.Rgt} />
        <Obs code="fgdsdfsgfdf" data={this.state.Obs} />
      </>,
      <>
        <div>
          <strong>Item 12.4.13.1.1</strong> Poderá ser adotada outra medida de
          proteção contra choques elétricos, conforme normas técnicas oficiais
          vigentes em alternativa às alíneas "b" dos respectivos subitens
          12.4.13 e 12.4.13.1 desta NR.
        </div>

        <div className="mt-3 mb-3">
          <strong>Item 12.4.14</strong> Se indicada pela apreciação de riscos a
          necessidade de redundância dos dispositivos responsáveis pela
          prevenção de partida inesperada ou pela função de parada relacionada à
          segurança, conforme a categoria de segurança requerida, o circuito
          elétrico da chave de partida de motores de máquinas e equipamentos
          deve:
        </div>

        <div>
          <p>
            <strong>a)</strong> possuir estrutura redundante;
          </p>
          <p>
            <strong>b)</strong> permitir que as falhas que comprometem a função
            de segurança sejam monitoradas; e
          </p>
          <p>
            <strong>c)</strong> ser adequadamente dimensionado de acordo com o
            estabelecido pelas normas técnicas oficiais ou pelas normas
            internacionais aplicáveis.
          </p>
        </div>
        <Situation
          resp={this.handleResponse}
          code="5fdfhfhgf"
          data={this.state.Situation}
        />
        <Jtf code="3fdfhjysdyktd" data={this.state.Jtf} />
        <strong>Resgistro:</strong>
        <Rgt code="d61g165984wef21" data={this.state.Rgt} />
        <Obs code="gh62we89f213zsc" data={this.state.Obs} />

        <div className="mt-3 mb-3">
          <p>
            <strong> Item 12.4.14.1</strong> É permitida a parada controlada do
            motor, desde que não haja riscos decorrentes de sua parada não
            instantânea.
          </p>
        </div>
        <Situation
          resp={this.handleResponse}
          code="gh54651asdfg"
          data={this.state.Situation}
        />
        <Jtf code="21xdf12hfg32" data={this.state.Jtf} />
        <Rgt code="jk321321dsfa" data={this.state.Rgt} />
        <Obs code="jn2546tf23yt" data={this.state.Obs} />

        <strong>6.5 Sistemas de Segurança</strong>
        <div className="mt-3 mb-3">
          <p>
            <strong> Item 12.5.1</strong> As zonas de perigo das máquinas e
            equipamentos devem possuir sistemas de segurança, caracterizados por
            proteções fixas, proteções móveis e dispositivos de segurança
            interligados, que resguardem proteção à saúde e à integridade física
            dos trabalhadores.
          </p>
        </div>
        <Situation
          resp={this.handleResponse}
          code="f65xgf6516514"
          data={this.state.Situation}
        />
        <Jtf code="c5g61v65c65" maxLength={2500} data={this.state.Jtf} />
      </>,
    ];

    var itens3 = [
      <>
        <p>
          <strong> Item 12.5.1.1</strong> Quando utilizadas proteções que
          restringem o acesso do corpo ou parte dele, devem ser observadas as
          distâncias mínimas conforme normas técnicas oficiais ou normas
          internacionais aplicáveis.
        </p>
        <Situation
          resp={this.handleResponse}
          code="sd65f468f5df5d6"
          data={this.state.Situation}
        />
        <Jtf code="re6511f32fg21" data={this.state.Jtf} />
        <Rgt code="2r321dfs213df" data={this.state.Rgt} />
        <Obs code="df321y65dfs1f" data={this.state.Obs} />
        <div className="mt-3 mb-3">
          <strong>Item 12.5.2</strong> Os sistemas de segurança devem ser
          selecionados e instalados de modo a atender aos seguintes requisitos:
        </div>
        <div>
          <p>
            <strong>a)</strong> ter categoria de segurança conforme apreciação
            de riscos prevista nas normas técnicas oficiais;
          </p>
          <p>
            <strong>b)</strong> estar sob a responsabilidade técnica de
            profissional legalmente habilitado;
          </p>
          <p>
            <strong>c)</strong> possuir conformidade técnica com o sistema de
            comando a que são integrados;
          </p>
          <p>
            <strong>d)</strong> instalação de modo que dificulte a sua burla;
          </p>
          <p>
            <strong>e)</strong> manterem-se sob vigilância automática, ou seja,
            monitoramento, se indicado pela apreciação de risco, de acordo com a
            categoria de segurança requerida, exceto para dispositivos de
            segurança exclusivamente mecânicos; e
          </p>
          <p>
            <strong>f)</strong> paralisação dos movimentos perigosos e demais
            riscos quando ocorrerem falhas ou situações anormais de trabalho.{" "}
          </p>
        </div>
        <Situation
          resp={this.handleResponse}
          code="d56r4561fg"
          data={this.state.Situation}
        />
        <Jtf code="65thd4665fd" data={this.state.Jtf} />
        <Rgt code="s65dt465fsg89r" data={this.state.Rgt} />
        <Obs code="231rt165g" data={this.state.Obs} />
        <div>
          <p>
            <strong> Item 12.5.2.1</strong> A instalação de sistemas de
            segurança deve ser realizada por profissional legalmente habilitado
            ou profissional qualificado ou capacitado, quando autorizados pela
            empresa.
          </p>
        </div>
        <Situation
          resp={this.handleResponse}
          code="d54g65g"
          data={this.state.Situation}
        />
        <Jtf code="65thse65t45fd" data={this.state.Jtf} />
        <Rgt code="s65d65dfy469r" data={this.state.Rgt} />
        <Obs code="23fd325fd565g" data={this.state.Obs} />
        <p>
          <strong> Item 12.5.3</strong> Os sistemas de segurança, se indicado
          pela apreciação de riscos, devem exigir rearme (“reset”) manual.{" "}
        </p>
        <Situation
          resp={this.handleResponse}
          code="ddf65s43dfsg"
          data={this.state.Situation}
        />
        12.5.3
        <Jtf code="6534565fd45fd" data={this.state.Jtf} />
        <Rgt code="s65dsfdh4569r" data={this.state.Rgt} />
        <Obs code="235df5665fd5g" data={this.state.Obs} />
        <p>
          <strong> Item 12.5.3.1</strong> Depois que um comando de parada tiver
          sido iniciado pelo sistema de segurança, a condição de parada deve ser
          mantida até que existam condições seguras para o rearme.
        </p>
        <Situation
          resp={this.handleResponse}
          code="24fd1565fdfg"
          data={this.state.Situation}
        />
        <Jtf code="65thdf5646adsd" data={this.state.Jtf} />
        <Rgt code="s6er4165fd5fkr" data={this.state.Rgt} />
        <Obs code="231rtdf66df55g" data={this.state.Obs} />
      </>,
      <>
        <div>
          <strong>Item 12.5.5</strong> Os componentes relacionados aos sistemas
          de segurança e comandos de acionamento e parada das máquinas,
          inclusive de emergência, devem garantir a manutenção do estado seguro
          da máquina ou equipamento quando ocorrerem flutuações no nível de
          energia além dos limites considerados no projeto, incluindo o corte e
          restabelecimento do fornecimento de energia.
        </div>

        <div className="mt-3">
          <strong>Item 12.5.6</strong> A proteção deve ser móvel quando o acesso
          a uma zona de perigo for requerido mais de uma vez por turno de
          trabalho, observando-se que:
        </div>

        <div>
          <p>
            <strong>a)</strong> a proteção deve ser associada a um dispositivo
            de intertravamento quando sua abertura não possibilitar o acesso à
            zona de perigo antes da eliminação do risco; e
          </p>
          <p>
            <strong>b)</strong> a proteção deve ser associada a um dispositivo
            de intertravamento com bloqueio quando sua abertura possibilitar o
            acesso à zona de perigo antes da eliminação do risco.
          </p>
        </div>
        <Situation
          resp={this.handleResponse}
          code="24fsrt651dfzsgg"
          data={this.state.Situation}
        />
        <Jtf code="65gfj65465gdsd" data={this.state.Jtf} />
        <Rgt code="s32tr89l65ia5r" data={this.state.Rgt} />
        <Obs code="231sda56f65fdg" data={this.state.Obs} />

        <p>
          <strong> Item 12.5.6.1</strong> É permitida a ligação em série, na
          mesma interface de segurança, de dispositivos de intertravamento de
          diferentes proteções móveis, desde que observado o disposto na ISO/TR
          24.119.
        </p>
        <Situation
          resp={this.handleResponse}
          code="f41dfgfbsgg"
          data={this.state.Situation}
        />
        <Jtf code="65gfghj32d165fd" data={this.state.Jtf} />
        <Rgt code="sjkl231ds65uy5r" data={this.state.Rgt} />

        <div style={{ display: "table", margin: "auto" }}>
          <div>
            <strong>Item 12.5.7</strong> As máquinas e equipamentos dotados de
            proteções móveis associadas a dispositivos de intertravamento devem:
          </div>
          <div>
            <p>
              <strong>a)</strong> operar somente quando as proteções estiverem
              fechadas;
            </p>
            <p>
              <strong>b)</strong> paralisar suas funções perigosas quando as
              proteções forem abertas durante a operação; e
            </p>
            <p>
              <strong>c)</strong> garantir que o fechamento das proteções por si
              só não possa dar início às funções perigosas.
            </p>
          </div>
          <Situation
            resp={this.handleResponse}
            code="24y54984dfg"
            data={this.state.Situation}
          />
          <Jtf code="65tfgj216fg5sd" data={this.state.Jtf} />
          <Rgt code="fg211j65ghjgkr" data={this.state.Rgt} />
          <Obs code="23fd214gh65f6g" data={this.state.Obs} />

          <p>
            <strong> Item 12.5.7.1</strong> A utilização de proteções
            intertravadas com comando de partida, como exceção ao previsto na
            alínea “c” do subitem 12.5.7, deve ser limitada e aplicada conforme
            as exigências específicas previstas em normas técnicas oficiais.
          </p>
          <Situation
            resp={this.handleResponse}
            code="2f351gfhfg"
            data={this.state.Situation}
          />
        </div>

        <Jtf code="65t32fgh5465d" data={this.state.Jtf} />
        <Rgt code="fg21fgh354gfr" data={this.state.Rgt} />
        <Obs code="2fg6516gf5h6g" data={this.state.Obs} />
      </>,
      <>
        <div className="mt-1 mb-3">
          <strong>Item 12.5.8</strong> Os dispositivos de intertravamento com
          bloqueio associados às proteções móveis das máquinas e equipamentos
          devem:
        </div>
        <div>
          <p>
            <strong>a)</strong> permitir a operação somente enquanto a proteção
            estiver fechada e bloqueada;
          </p>
          <p>
            <strong>b)</strong> manter a proteção fechada e bloqueada até que
            tenha sido eliminado o risco de lesão devido às funções perigosas da
            máquina ou do equipamento; e
          </p>
          <p>
            <strong>c)</strong> garantir que o fechamento e bloqueio da proteção
            por si só não possa dar início às funções perigosas da máquina ou do
            equipamento.
          </p>
        </div>
        <Situation
          resp={this.handleResponse}
          code="2ffg651651fg"
          data={this.state.Situation}
        />
        <Jtf code="65df5616g65d" data={this.state.Jtf} />
        <Rgt code="fg21fdfg51fr" data={this.state.Rgt} />
        <Obs code="2fg6dfg213g" data={this.state.Obs} />

        <p>
          <strong> Item 12.5.8.1</strong> A utilização de proteções
          intertravadas com comando de partida, como exceção ao previsto na
          alínea “c” do subitem 12.5.8, deve ser limitada e aplicada conforme as
          exigências específicas previstas em normas técnicas oficiais.
        </p>
        <Situation
          resp={this.handleResponse}
          code="2fffg321fdfg"
          data={this.state.Situation}
        />
        <Jtf code="6grf6515g65d" data={this.state.Jtf} />
        <Rgt code="fg2hg54vggfr" data={this.state.Rgt} />
        <Obs code="2fgfg54165dg" data={this.state.Obs} />

        <p>
          <strong> Item 12.5.9</strong> As transmissões de força e os
          componentes móveis a elas interligados, acessíveis ou expostos, desde
          que ofereçam risco, devem possuir proteções fixas, ou móveis com
          dispositivos de intertravamento, que impeçam o acesso por todos os
          lados.
        </p>
        <Situation
          resp={this.handleResponse}
          code="265gf1651gfg"
          data={this.state.Situation}
        />
        <Jtf code="65df4gh6546gd" data={this.state.Jtf} />
        <Rgt code="ffg654h65fghfr" data={this.state.Rgt} />
        <Obs code="2fgfg65.66.gfg" data={this.state.Obs} />

        <p>
          <strong> Item 12.5.9.1</strong> Quando utilizadas proteções móveis
          para o enclausuramento de transmissões de força que possuam inércia,
          devem ser utilizados dispositivos de intertravamento com bloqueio.
        </p>
        <Situation
          resp={this.handleResponse}
          code="df561651fdgfg"
          data={this.state.Situation}
        />
        <Jtf code="65ik5y156a65ytgd" data={this.state.Jtf} />
        <Rgt code="ffhk621984refdfr" data={this.state.Rgt} />
        <Obs code="h65465asdfc21546" data={this.state.Obs} />

        <p>
          <strong> Item 12.5.9.2</strong> O eixo cardã deve possuir proteção
          adequada, em toda a sua extensão, fixada na tomada de força da
          máquina, desde a cruzeta até o acoplamento do implemento ou
          equipamento.
        </p>
        <Situation
          resp={this.handleResponse}
          code="bhv651132g"
          data={this.state.Situation}
        />
        <Jtf code="65ig651561fxcfgf" data={this.state.Jtf} />
        <Obs code="hfg651ff5d61651g" data={this.state.Obs} />
        <Rgt code="KLJhvlJKHhujh" data={this.state.Rgt} />
      </>,
      <>
        <p>
          <strong> Item 12.5.10</strong> As máquinas e equipamentos que ofereçam
          risco de ruptura de suas partes, projeção de materiais, partículas ou
          substâncias, devem possuir proteções que garantam a segurança e a
          saúde dos trabalhadores.
        </p>
        <Situation
          resp={this.handleResponse}
          code="651gh132sd"
          data={this.state.Situation}
        />
        <Jtf code="f65g5651hfg654jh" data={this.state.Jtf} />
        <Obs code="hfgf6fg651665fgg" data={this.state.Obs} />
        <Rgt code="ILUgUILgyo8" data={this.state.Rgt} />

        <div className="mt-3 mb-3">
          <strong>Item 12.5.11</strong> As proteções devem ser projetadas e
          construídas de modo a atender aos seguintes requisitos de segurança:
        </div>
        <div>
          <p>
            <strong>a)</strong> cumprir suas funções apropriadamente durante a
            vida útil da máquina ou possibilitar a reposição de partes
            deterioradas ou danificadas;
          </p>
          <p>
            <strong>b)</strong> ser constituídas de materiais resistentes e
            adequados à contenção de projeção de peças, materiais e partículas;
          </p>
          <p>
            <strong>c)</strong> fixação firme e garantia de estabilidade e
            resistência mecânica compatíveis com os esforços requeridos; .
          </p>
          <p>
            <strong>d)</strong> não criar pontos de esmagamento ou agarramento
            com partes da máquina ou com outras proteções;
          </p>
          <p>
            <strong>e)</strong> não possuir extremidades e arestas cortantes ou
            outras saliências perigosas;
          </p>
          <p>
            <strong>f)</strong> resistir às condições ambientais do local onde
            estão instaladas;
          </p>
          <p>
            <strong>g)</strong> dificulte-se a burla;
          </p>
          <p>
            <strong>h)</strong> proporcionar condições de higiene e limpeza;
          </p>
          <p>
            <strong>i)</strong> impedir o acesso à zona de perigo;
          </p>
          <p>
            <strong>j)</strong> ter seus dispositivos de intertravamento
            protegidos adequadamente contra sujidade, poeiras e corrosão, se
            necessário;
          </p>
          <p>
            <strong>k)</strong> ter ação positiva, ou seja, atuação de modo
            positivo;
          </p>
          <p>
            <strong>l)</strong> não acarretar riscos adicionais.
          </p>
        </div>
        <Situation
          resp={this.handleResponse}
          code="265f56gh156hg12"
          data={this.state.Situation}
        />
        <Jtf code="65dff65fg65d" data={this.state.Jtf} />
        <Obs code="2fg56516gg" data={this.state.Obs} />
        <Rgt code="LIflyuvijhçugpiu" data={this.state.Rgt} />
        <div className="mt-3 mb-3">
          <p>
            <strong> Item 12.5.12</strong> Quando a proteção for confeccionada
            com material descontínuo, devem ser observadas as distâncias de
            segurança para impedir o acesso às zonas de perigo, conforme
            previsto nas normas técnicas oficiais ou nas normas internacionais
            aplicáveis.
          </p>
        </div>

        <Situation
          resp={this.handleResponse}
          code="265fg564g6"
          data={this.state.Situation}
        />
        <Jtf code="6561hg516jf5d" data={this.state.Jtf} />
        <Obs code="2gh261gf21g13" data={this.state.Obs} />
        <Rgt code="UYTDurxty" data={this.state.Rgt} />
      </>,

      <>
        <div className="mt-3 mb-3">
          <strong>Item 12.5.13</strong> Sempre que forem utilizados sistemas de
          segurança, inclusive proteções distantes, com possibilidade de alguma
          pessoa ficar na zona de perigo, deve ser adotada uma das seguintes
          medidas adicionais de proteção coletiva para impedir a partida da
          máquina enquanto houver pessoas nessa zona:
        </div>
        <div>
          <p>
            <strong>a)</strong> sensoriamento da presença de pessoas;
          </p>
          <p>
            <strong>b)</strong> proteções móveis ou sensores de segurança na
            entrada ou acesso à zona de perigo, associadas a rearme (“reset”)
            manual.
          </p>
        </div>
        <Situation
          resp={this.handleResponse}
          code="32g231fg"
          data={this.state.Situation}
        />
        <Jtf code="123ffgf2h12f3214" data={this.state.Jtf} />
        <Rgt code="rtsfds2611gf35gh" data={this.state.Rgt} />
        <Obs code="sdgf21d56112fdg4" data={this.state.Obs} />
        <div className="mt-3 mb-3">
          <p>
            <strong> Item 12.5.13.1</strong> A localização dos atuadores de
            rearme (“reset”) manual deve permitir uma visão completa da zona
            protegida pelo sistema.
          </p>
        </div>
        <Situation
          resp={this.handleResponse}
          code="gfhj1231hg"
          data={this.state.Situation}
        />
        <Jtf code="1fdg231h3f3214" data={this.state.Jtf} />
        <Obs code="sdgfdf56ggf214" data={this.state.Obs} />

        <p>
          <strong> Item 12.5.13.2</strong> Quando não for possível o cumprimento
          da exigência do subitem 12.5.13.1, deve ser adotado o sensoriamento da
          presença de pessoas nas zonas de perigo com a visualização obstruída,
          ou a adoção de sistema que exija a ida à zona de perigo não
          visualizada, como, por exemplo, duplo rearme (“reset”).
        </p>

        <Situation
          resp={this.handleResponse}
          code="3gh656h5j1g"
          data={this.state.Situation}
        />
        <Jtf code="1fgh5645gre8rgk4" data={this.state.Jtf} />
        <Obs code="gh21465era546dg4" data={this.state.Obs} />

        <p>
          <strong> Item 12.5.13.3</strong> Deve haver dispositivos de parada de
          emergência localizados no interior da zona protegida pelo sistema, bem
          como meios de liberar pessoas presas dentro dela.
        </p>

        <Situation
          resp={this.handleResponse}
          code="32xfdh154dfg"
          data={this.state.Situation}
        />
        <Jtf code="fddf3212132fd4" data={this.state.Jtf} />
        <Rgt code="rf3213213d3dfh" data={this.state.Rgt} />
        <Obs code="df62gf23fg124" data={this.state.Obs} />

        <p>
          <strong> Item 12.5.14</strong> As proteções também utilizadas como
          meio de acesso por exigência das características da máquina ou do
          equipamento devem atender aos requisitos de resistência e segurança
          adequados a ambas as finalidades
        </p>
        <Situation
          resp={this.handleResponse}
          code="3h321ty54f"
          data={this.state.Situation}
        />
        <Jtf code="d321fx34fgh65gfh" data={this.state.Jtf} />
        <Obs code="gf324165gfh65dg4" data={this.state.Obs} />
      </>,
      <>
        <p>
          <strong> Item 12.5.15</strong> Deve haver proteção no fundo dos
          degraus da escada, ou seja, nos espelhos, sempre que uma parte
          saliente do pé ou da mão possa contatar uma zona perigosa.
        </p>
        <Situation
          resp={this.handleResponse}
          code="f65f56465f"
          data={this.state.Situation}
        />
        <Jtf code="d3fgh5465g6fg5dh" data={this.state.Jtf} />
        <Rgt code="Phgyp98ny" data={this.state.Rgt} />
        <div class="mt-3 mb-3">
          <strong> Item 12.5.16 </strong> As proteções, dispositivos e sistemas
          de segurança são partes integrantes das máquinas e equipamentos e não
          podem ser considerados itens opcionais para qualquer fim.
        </div>
        <Situation
          resp={this.handleResponse}
          code="fgf32165fg"
          data={this.state.Situation}
        />
        <Jtf code="df231g52165fghjk" data={this.state.Jtf} />
        <Obs code="sda32135fh46gf68" data={this.state.Obs} />
        <Rgt code="OYgf8ygt" data={this.state.Rgt} />
        <p>
          <strong> Item 12.5.17</strong> Em função do risco, poderá ser exigido
          projeto, diagrama ou representação esquemática dos sistemas de
          segurança de máquinas, com respectivas especificações técnicas em
          língua portuguesa, elaborado por profissional legalmente habilitado.
        </p>
        <Situation
          resp={this.handleResponse}
          code="fgfd566545g"
          data={this.state.Situation}
        />
        <Jtf code="ddfg3213gf654fjk" data={this.state.Jtf} />
        <Obs code="df2316541654df65" data={this.state.Obs} />
        <Rgt code="UOfyouygiu" data={this.state.Rgt} />
        <div className="mb-3">
          <strong style={{ marginLeft: 0 }}>
            6.6 Dispositivos de Parada de Emergência
          </strong>
        </div>
        <p>
          <strong> Item 12.6.1</strong> As máquinas devem ser equipadas com um
          ou mais dispositivos de parada de emergência, por meio dos quais
          possam ser evitadas situações de perigo latentes e existentes.
        </p>
        <Situation
          resp={this.handleResponse}
          code="fdg216df54"
          data={this.state.Situation}
        />
        <Jtf code="ffg656.gfhhfgfg4" data={this.state.Jtf} />
        <Rgt multiple code="fg6d546fg44gf89h" data={this.state.Rgt} />
      </>,

      <>
        <p>
          <strong> Item 12.6.1.1</strong> Os dispositivos de parada de
          emergência não devem ser utilizados como dispositivos de partida ou de
          acionamento.
        </p>
        <Situation
          resp={this.handleResponse}
          code="dfs564g65df"
          data={this.state.Situation}
        />
        <Jtf code="fd64684fd4" data={this.state.Jtf} />
        <Obs code="5fxd4g6.59fd8s" data={this.state.Obs} />
        <Rgt code="LIyfgliou" data={this.state.Rgt} />

        <div>
          <strong>Item 12.6.1.2</strong> Excetuam-se da obrigação do subitem
          12.6.1:
        </div>
        <div>
          <p>
            <strong>a)</strong> as máquinas autopropelidas;
          </p>
          <p>
            <strong>b)</strong> as máquinas e equipamentos nas quais o
            dispositivo de parada de emergência não possibilita a redução do
            risco.
          </p>
        </div>
        <Situation
          resp={this.handleResponse}
          code="fg564h65fg"
          data={this.state.Situation}
        />
        <Jtf code="fdf65g65gf.54" data={this.state.Jtf} />
        <Obs code="5fxfd654df+8s" data={this.state.Obs} />
        <Rgt code="OLguyo8uig" data={this.state.Rgt} />

        <p>
          <strong> Item 12.6.2</strong> Os dispositivos de parada de emergência
          devem ser posicionados em locais de fácil acesso e visualização pelos
          operadores em seus postos de trabalho e por outras pessoas, e mantidos
          permanentemente desobstruídos.
        </p>
        <Situation
          resp={this.handleResponse}
          code="fdfh456dgh"
          data={this.state.Situation}
        />
        <Jtf code="fdf6+fg59+87gf4" data={this.state.Jtf} />
        <Obs code="5fg4h98gfh49898" data={this.state.Obs} />
        <Rgt code="PIUguigt" data={this.state.Rgt} />

        <div>
          <strong>Item 12.6.3</strong> Os dispositivos de parada de emergência
          devem:
        </div>
        <div>
          <p>
            <strong>a)</strong> ser selecionados, montados e interconectados de
            forma a suportar as condições de operação previstas, bem como as
            influências do meio;
          </p>
          <p>
            <strong>b)</strong> ser usados como medida auxiliar, não podendo ser
            alternativa a medidas adequadas de proteção ou a sistemas
            automáticos de segurança;
          </p>
          <p>
            <strong>c)</strong> possuir acionadores projetados para fácil
            atuação do operador ou outros que possam ser acionados rapidamente
            em situações de emergência;
          </p>
          <p>
            <strong>d)</strong> prevalecer sobre todos os outros comandos;
          </p>
          <p>
            <strong>e)</strong> provocar a parada da operação ou processo
            perigoso em período de tempo tão reduzido quanto tecnicamente
            possível, sem provocar riscos suplementares;
          </p>
          <p>
            <strong>f)</strong> ter sua função disponível e operacional a
            qualquer tempo, independentemente do modo de operação;
          </p>
        </div>
        <Situation
          resp={this.handleResponse}
          code="fdg216dty654"
          data={this.state.Situation}
        />
        <Jtf code="ffgfg654654fdfg4" data={this.state.Jtf} />
        <Obs code="sgf6554fg569d+f9" data={this.state.Obs} />
        <Rgt code="Ptgu8piugpiu" data={this.state.Rgt} />
      </>,
      <>
        <div className="mt-3 mb-3">
          <strong>Item 12.6.4</strong> A função parada de emergência não deve:
        </div>
        <div>
          <p>
            <strong>a)</strong> prejudicar a eficiência de sistemas de segurança
            ou dispositivos com funções relacionadas com a segurança;
          </p>
          <p>
            <strong>b)</strong> prejudicar qualquer meio projetado para resgatar
            pessoas acidentadas; e
          </p>
          <p>
            <strong>c)</strong> gerar risco adicional.
          </p>
        </div>

        <Situation
          resp={this.handleResponse}
          code="fd65fg46654"
          data={this.state.Situation}
        />
        <Jtf code="ff6dg565ffdfdfg4" data={this.state.Jtf} />
        <Obs code="sgf65sd56f465+f9" data={this.state.Obs} />
        <Rgt code="LOguyoiu" data={this.state.Rgt} />
        <p>
          <strong> Item 12.6.5</strong> O acionamento do dispositivo de parada
          de emergência deve também resultar na retenção do acionador, de tal
          forma que, quando a ação no acionador for descontinuada, este se
          mantenha retido até que seja desacionado.
        </p>

        <Situation
          resp={this.handleResponse}
          code="df516fd54"
          data={this.state.Situation}
        />
        <Jtf code="ff6dg56564+drgg4" data={this.state.Jtf} />
        <Obs code="sgfsdaf445ds54-9" data={this.state.Obs} />
        <Rgt code="LIY&gytg87" data={this.state.Rgt} />

        <p>
          <strong> Item 12.6.5.1</strong> O desacionamento deve ser possível
          apenas como resultado de uma ação manual intencionada sobre o
          acionador, por meio de manobra apropriada.
        </p>
        <Situation
          resp={this.handleResponse}
          code="fddf5465y654"
          data={this.state.Situation}
        />
        <Jtf code="ffgfdfg65465df" data={this.state.Jtf} />
        <Obs code="sgf6654dfg9859" data={this.state.Obs} />

        <Rgt code="ÒIH9ghṕiohj" data={this.state.Rgt} />
      </>,
      <>
        <div className="mt-3 mb-3">
          <strong>Item 12.6.6</strong> Quando usados acionadores do tipo cabo,
          deve-se:
        </div>
        <div>
          <p>
            <strong>a)</strong> utilizar chaves de parada de emergência que
            trabalhem tracionadas, de modo a cessarem automaticamente as funções
            perigosas da máquina em caso de ruptura ou afrouxamento dos cabos;
          </p>
          <p>
            <strong>b)</strong> considerar o deslocamento e a força aplicada nos
            acionadores, necessários para a atuação das chaves de parada de
            emergência; e
          </p>
          <p>
            <strong>c)</strong> obedecer à distância máxima entre as chaves de
            parada de emergência recomendada pelo fabricante.
          </p>
        </div>

        <div className="mb-3">
          <strong>Item 12.6.7</strong> As chaves de parada de emergência devem
          ser localizadas de tal forma que todo o cabo de acionamento seja
          visível a partir da posição de desacionamento da parada de emergência.
        </div>

        <div className="mb-3">
          <strong>Item 12.6.7.1</strong> Se não for possível o cumprimento da
          exigência do subitem 12.6.7, deve-se garantir que, após a atuação e
          antes do desacionamento, a máquina ou equipamento seja inspecionado em
          toda a extensão do cabo.
        </div>
        <Situation
          resp={this.handleResponse}
          code="fddf5465y654e"
          data={this.state.Situation}
        />
        <Jtf code="ffgfdfg65465dfe" data={this.state.Jtf} />
        <Obs code="sgf6654dfg9859e" data={this.state.Obs} />
        <Rgt code="ÇILUgYFfgou" data={this.state.Rgt} />

        <p>
          <strong> Item 12.6.8</strong> A parada de emergência deve exigir
          rearme ou reset manual a ser realizado somente após a correção do
          evento que motivou o acionamento da parada de emergência.
        </p>

        <Situation
          resp={this.handleResponse}
          code="fdddfaghg4"
          data={this.state.Situation}
        />
        <Jtf code="fghldfghdfdg" data={this.state.Jtf} />
        <Obs code="sgfgjdfjfd59" data={this.state.Obs} />
        <Rgt code="LIguyo8ytfg8" data={this.state.Rgt} />

        <p>
          <strong> Item 12.6.8.1</strong> A localização dos acionadores de
          rearme deve permitir uma visualização completa da área protegida pelo
          cabo.
        </p>
        <Situation
          resp={this.handleResponse}
          code="ffghgfhfgwe4"
          data={this.state.Situation}
        />
        <Jtf code="fgrytruhdfdg" data={this.state.Jtf} />
        <Obs code="sgfdyhfgfdf9" data={this.state.Obs} />
        <Rgt code="IKfyoiuyf" data={this.state.Rgt} />
      </>,
      <>
        <div className="mt-3 mb-3">
          <strong>6.7 Componentes Pressurizados</strong>
        </div>

        <p>
          <strong> Item 12.7.1</strong> Devem ser adotadas medidas adicionais de
          proteção das mangueiras, tubulações e demais componentes pressurizados
          sujeitos a eventuais impactos mecânicos e outros agentes agressivos,
          quando houver risco.
        </p>

        <Situation
          resp={this.handleResponse}
          code="3fgh+965.fg"
          data={this.state.Situation}
        />
        <Jtf code="fd5f3d26.ds4" data={this.state.Jtf} />
        <Rgt code="rtdf6565gffd" data={this.state.Rgt} />
        <Obs code="sd656df465g4" data={this.state.Obs} />

        <strong>6.8 Transportadores de Materiais</strong>

        <div className="mt-3 mb-3">
          <p>
            <strong> Item 12.8.1</strong> Os movimentos perigosos dos
            transportadores contínuos de materiais, acessíveis durante a
            operação normal, devem ser protegidos, especialmente nos pontos de
            esmagamento, agarramento e aprisionamento.
          </p>
        </div>
        <Situation
          resp={this.handleResponse}
          code="3fgh654fg4h"
          data={this.state.Situation}
        />
        <Jtf code="fd56f.g654fg54" data={this.state.Jtf} />
        <Rgt code="rdfg656fg4ggfd" data={this.state.Rgt} />
        <Obs code="sd656df465g4df" data={this.state.Obs} />

        <strong>6.9 Aspectos Ergonômicos</strong>

        <div className="mt-3 mb-3">
          <p>
            <strong> Item 12.9.1</strong> Para o trabalho em máquinas e
            equipamentos devem ser respeitadas as disposições contidas na Norma
            Regulamentadora n.º 17 - Ergonomia.
          </p>
        </div>

        <Situation
          resp={this.handleResponse}
          code="365xdf45g"
          data={this.state.Situation}
        />
        <Jtf code="fd5dfz654564" data={this.state.Jtf} />
        <Rgt code="r65d4fzsdagh" data={this.state.Rgt} />
        <Obs code="sd65dfshfgg4" data={this.state.Obs} />
      </>,
      <>
        <div className="mt-3 mb-3">
          <strong>6.10 Riscos Adicionais</strong>
        </div>
        <div className="mb-3">
          <strong>Item 12.10.1</strong> Para fins de aplicação desta NR, devem
          ser considerados os seguintes riscos adicionais:
        </div>
        <div>
          <p>
            <strong>a)</strong> substâncias perigosas quaisquer, sejam agentes
            biológicos ou agentes químicos em estado sólido, líquido ou gasoso,
            que apresentem riscos à saúde ou integridade física dos
            trabalhadores por meio de inalação, ingestão ou contato com a pele,
            olhos ou mucosas;
          </p>
        </div>
        <div>
          <p>
            <strong>b)</strong> radiações ionizantes geradas pelas máquinas e
            equipamentos ou provenientes de substâncias radiativas por eles
            utilizadas, processadas ou produzidas;
          </p>
          <p>
            <strong>c)</strong> radiações não ionizantes com potencial de causar
            danos à saúde ou integridade física dos trabalhadores;
          </p>
          <p>
            <strong>d)</strong> vibrações;
          </p>
          <p>
            <strong>e)</strong> ruído;
          </p>
          <p>
            <strong>f)</strong> calor;
          </p>
          <p>
            <strong>g)</strong> combustíveis, inflamáveis, explosivos e
            substâncias que reagem perigosamente; e
          </p>
          <p>
            <strong>h)</strong> superfícies aquecidas acessíveis que apresentem
            risco de queimaduras causadas pelo contato com a pele.
          </p>
        </div>

        <Situation
          resp={this.handleResponse}
          code="fggfhfgryik"
          data={this.state.Situation}
        />
        <Jtf code="fgcjgfggfhjtyk" data={this.state.Jtf} />
        <Rgt code="ILÇjgbçojklb" data={this.state.Rgt} />

        <div className="mt-3 mb-3">
          <strong>
            6.11 Manutenção, Inspeção, Preparação, Ajustes e Reparos
          </strong>
        </div>

        <div className="mb-3">
          <strong>Item 12.11.1</strong> As máquinas e equipamentos devem ser
          submetidos a manutenções na forma e periodicidade determinada pelo
          fabricante, por profissional legalmente habilitado ou por profissional
          qualificado, conforme as normas técnicas oficiais ou normas técnicas
          internacionais aplicáveis.
        </div>
        <div className="mb-3">
          <strong>Item 12.11.2</strong> As manutenções devem ser registradas em
          livro próprio, ficha ou sistema informatizado interno da empresa, com
          os seguintes dados:
        </div>
        <div className="mb-3">
          <p>
            <strong>a)</strong> intervenções realizadas;
          </p>
          <p>
            <strong>b)</strong> data da realização de cada intervenção;
          </p>
          <p>
            <strong>c)</strong> serviço realizado;
          </p>
          <p>
            <strong>d)</strong> peças reparadas ou substituídas;
          </p>
          <p>
            <strong>e)</strong> condições de segurança do equipamento;
          </p>
          <p>
            <strong>f)</strong> indicação conclusiva quanto às condições de
            segurança da máquina; e
          </p>
          <p>
            <strong>g)</strong> nome do responsável pela execução das
            intervenções.
          </p>
        </div>

        <div className="mb-3">
          <strong>Item 12.11.2.1</strong> O registro das manutenções deve ficar
          disponível aos trabalhadores envolvidos na operação, manutenção e
          reparos, bem como à Comissão Interna de Prevenção de Acidentes - CIPA,
          ao Serviço de Segurança e Medicina do Trabalho - SESMT e à Auditoria
          Fiscal do Trabalho.
        </div>
      </>,
      <>
        <div className="mt-3 mb-3">
          <strong>Item 12.11.2.2</strong> As manutenções de itens que
          influenciem na segurança devem:
        </div>
        <div>
          <p>
            <strong>a)</strong> no caso de preventivas, possuir cronograma de
            execução;
          </p>
          <p>
            <strong>b)</strong> no caso de preditivas, possuir descrição das
            técnicas de análise e meios de supervisão centralizados ou de
            amostragem.
          </p>
        </div>
        <div className="mb-3">
          <strong>Item 12.11.3</strong> A manutenção, inspeção, reparos,
          limpeza, ajuste e outras intervenções que se fizerem necessárias devem
          ser executadas por profissionais capacitados, qualificados ou
          legalmente habilitados, formalmente autorizados pelo empregador, com
          as máquinas e equipamentos parados e adoção dos seguintes
          procedimentos:
        </div>
        <div>
          <p>
            <strong>a)</strong> isolamento e descarga de todas as fontes de
            energia das máquinas e equipamentos, de modo visível ou facilmente
            identificável por meio dos dispositivos de comando;
          </p>
          <p>
            <strong>b)</strong> bloqueio mecânico e elétrico na posição
            “desligado” ou “fechado” de todos os dispositivos de corte de fontes
            de energia, a fim de impedir a reenergização, e sinalização com
            cartão ou etiqueta de bloqueio contendo o horário e a data do
            bloqueio, o motivo da manutenção e o nome do responsável;
          </p>
          <p>
            <strong>c)</strong> medidas que garantam que à jusante dos pontos de
            corte de energia não exista possibilidade de gerar risco de
            acidentes;
          </p>
          <p>
            <strong>d)</strong> medidas adicionais de segurança, quando for
            realizada manutenção, inspeção e reparos de máquinas ou equipamentos
            sustentadas somente por sistemas hidráulicos e pneumáticos;
          </p>
          <p>
            <strong>e)</strong> sistemas de retenção com trava mecânica, para
            evitar o movimento de retorno acidental de partes basculadas ou
            articuladas abertas das máquinas e equipamentos.
          </p>
        </div>
        <div className="mb-3">
          <strong>Item 12.11.3.1</strong> Para situações especiais de
          manutenção, regulagem, ajuste, limpeza, pesquisa de defeitos e
          inconformidades, em que não seja possível o cumprimento das condições
          estabelecidas no subitem 12.11.3, e em outras situações que impliquem
          a redução do nível de segurança das máquinas e equipamentos e houver
          necessidade de acesso às zonas de perigo, deve ser possível selecionar
          um modo de operação que:
        </div>
        <div>
          <p>
            <strong>a)</strong> torne inoperante o modo de comando automático;
          </p>
          <p>
            <strong>b)</strong> permita a realização dos serviços com o uso de
            dispositivo de acionamento de ação continuada associado à redução da
            velocidade, ou dispositivos de comando por movimento limitado;
          </p>
        </div>

        <div className="mb-3">
          <strong>Item 12.11.3.1</strong> Para situações especiais de
          manutenção, regulagem, ajuste, limpeza, pesquisa de defeitos e
          inconformidades, em que não seja possível o cumprimento das condições
          estabelecidas no subitem 12.11.3, e em outras situações que impliquem
          a redução do nível de segurança das máquinas e equipamentos e houver
          necessidade de acesso às zonas de perigo, deve ser possível selecionar
          um modo de operação que:
        </div>

        <div>
          <p>
            <strong>a)</strong> torne inoperante o modo de comando automático;
          </p>
          <p>
            <strong>b)</strong> permita a realização dos serviços com o uso de
            dispositivo de acionamento de ação continuada associado à redução da
            velocidade, ou dispositivos de comando por movimento limitado;
          </p>
          <p>
            <strong>c)</strong> impeça a mudança por trabalhadores não
            autorizados;
          </p>
          <p>
            <strong>d)</strong> a seleção corresponda a um único modo de comando
            ou de funcionamento;
          </p>
          <p>
            <strong>e)</strong> quando selecionado, tenha prioridade sobre todos
            os outros sistemas de comando, com exceção da parada de emergência;
          </p>
        </div>
        <div>
          <p>
            <strong>f)</strong> torne a seleção visível, clara e facilmente
            identificável.
          </p>
        </div>
      </>,
      <>
        <div className="mb-3">
          <strong>Item 12.11.3.2</strong> Ficam dispensadas do atendimento dos
          subitens 12.11.3 e 12.11.3.1, as situações especiais de manutenção,
          regulagem, ajuste, pesquisa de defeitos e inconformidades que não
          ofereçam riscos às pessoas envolvidas na realização destas atividades,
          que não impliquem na redução do nível de segurança e que não
          necessitem de acesso às zonas de perigo, desde que executadas sob
          supervisão do empregador ou pessoa por ele designada.
        </div>
        <div className="mb-3">
          <strong>Item 12.11.3.3</strong> Na impossibilidade técnica da
          aplicação das medidas dos subitens 12.11.3 e 12.11.3.1, em função de
          inércia térmica do processo, podem ser adotadas outras medidas de
          segurança, desde que sejam planejadas e gerenciadas por profissional
          legalmente habilitado e resguardem a segurança e a saúde dos
          trabalhadores.
        </div>
        <div className="mb-3">
          <strong>Item 12.11.4</strong> A manutenção de máquinas e equipamentos
          contemplará, quando indicado pelo fabricante, dentre outros itens, a
          realização de Ensaios Não Destrutivos - ENDs, nas estruturas e
          componentes submetidos a solicitações de força e cuja ruptura ou
          desgaste possa ocasionar acidentes.
        </div>
        <div className="mb-3">
          <strong>Item 12.11.4.1</strong> Os ENDs, quando realizados, devem
          atender às normas técnicas oficiais ou normas técnicas internacionais
          aplicáveis.
        </div>
        <div className="mb-3">
          <strong>Item 12.11.5</strong> Nas manutenções das máquinas e
          equipamentos, sempre que detectado qualquer defeito em peça ou
          componente que comprometa a segurança, deve ser providenciada sua
          reparação ou substituição imediata por outra peça ou componente
          original ou equivalente, de modo a garantir as mesmas características
          e condições seguras de uso.
        </div>
        <Situation
          resp={this.handleResponse}
          code="4d6s5f465df4g65gd"
          data={this.state.Situation}
        />
        <Jtf code="y6546548sdth98rht" data={this.state.Jtf} />
        <Rgt code="ÒIgpiugiu" data={this.state.Rgt} />
      </>,
      <>
        <div className="mt-3 mb-3">
          <strong>6.12 Sinalização</strong>
        </div>

        <p>
          <strong> Item 12.12.1</strong> As máquinas e equipamentos, bem como as
          instalações em que se encontram, devem possuir sinalização de
          segurança para advertir os trabalhadores e terceiros sobre os riscos a
          que estão expostos, as instruções de operação e manutenção e outras
          informações necessárias para garantir a integridade física e a saúde
          dos trabalhadores.
        </p>

        <p>
          <strong> Item 12.12.1.1</strong> A sinalização de segurança compreende
          a utilização de cores, símbolos, inscrições, sinais luminosos ou
          sonoros, entre outras formas de comunicação de mesma eficácia.
        </p>
        <Situation
          resp={this.handleResponse}
          code="gfh51665hgdf"
          data={this.state.Situation}
        />
        <Jtf code="fgfh65654gh" data={this.state.Jtf} />
        <Rgt multiple code="rtsfghd655" data={this.state.Rgt} />

        <p>
          <strong> Item 12.12.1.1</strong> A sinalização de segurança compreende
          a utilização de cores, símbolos, inscrições, sinais luminosos ou
          sonoros, entre outras formas de comunicação de mesma eficácia.
        </p>
        <Situation
          resp={this.handleResponse}
          code="gfh51665hgdf"
          data={this.state.Situation}
        />
        <Jtf code="fgfh65654gh" data={this.state.Jtf} />
        <Rgt multiple code="rtsfghd655" data={this.state.Rgt} />
        <p>
          <strong> Item 12.12.1.2</strong> A sinalização, inclusive cores, das
          máquinas e equipamentos utilizados nos setores alimentícios, médico e
          farmacêutico deve respeitar a legislação sanitária vigente, sem
          prejuízo da segurança e saúde dos trabalhadores ou terceiros.
        </p>

        <Situation
          resp={this.handleResponse}
          code="gfh5sthgf5hgdf"
          data={this.state.Situation}
        />
        <Jtf code="fgfer6ue6tgf" data={this.state.Jtf} />
        <Rgt multiple code="rtfdjhfjyd" data={this.state.Rgt} />
      </>,
      <>
        <div className="mt-3 mb-3">
          <p>
            <strong> Item 12.12.1.3</strong> A sinalização de segurança deve ser
            adotada em todas as fases de utilização e vida útil das máquinas e
            equipamentos
          </p>
        </div>

        <Situation
          resp={this.handleResponse}
          code="gfh5sthgf5hgdf2"
          data={this.state.Situation}
        />
        <Jtf code="fgfer6ue6tgf2" data={this.state.Jtf} />
        <Rgt multiple code="rtfdjhfjyd2" data={this.state.Rgt} />

        <div className="mt-3 mb-3">
          <strong>Item 12.12.2</strong> A sinalização de segurança deve:
        </div>
        <div>
          <p>
            <strong>a)</strong> ficar destacada na máquina ou equipamento;
          </p>
          <p>
            <strong>b)</strong> ficar em localização claramente visível; e
          </p>
          <p>
            <strong>c)</strong> ser de fácil compreensão.
          </p>
        </div>
        <Situation
          resp={this.handleResponse}
          code="fgjhfdutrghy"
          data={this.state.Situation}
        />
        <Jtf code="srtyydjfghjt" data={this.state.Jtf} />
        <Rgt multiple code="fghfdgfgrtu" data={this.state.Rgt} />

        <p>
          <strong> Item 12.12.4</strong> As inscrições das máquinas e
          equipamentos devem:
        </p>
        <p>
          <strong>a)</strong> ser escritas na língua portuguesa (Brasil); e
        </p>
        <p>
          <strong>b)</strong> ser legíveis.
        </p>
        <Situation
          resp={this.handleResponse}
          code="fgjhffgfdsgh"
          data={this.state.Situation}
        />
        <Jtf code="srtdfghdf" data={this.state.Jtf} />
        <Rgt code="fdfsfdshfd" data={this.state.Rgt} />

        <p>
          <strong> Item 12.12.4.1</strong> As inscrições devem indicar
          claramente o risco e a parte da máquina ou equipamento a que se
          referem, e não deve ser utilizada somente a inscrição de “perigo”.
        </p>
        <Situation
          resp={this.handleResponse}
          code="fgdfurtuiyddsgh"
          data={this.state.Situation}
        />
        <Jtf code="srtgkjdsfhtdfsf" data={this.state.Jtf} />
        <Rgt code="ffgjyfgfufd" data={this.state.Rgt} />

        <p>
          <strong> Item 12.12.5</strong> As inscrições e símbolos devem ser
          utilizados nas máquinas e equipamentos para indicar as suas
          especificações e limitações técnicas fundamentais à segurança.
        </p>
        <Situation
          resp={this.handleResponse}
          code="fgddt654165fdhsgh"
          data={this.state.Situation}
        />
        <Jtf code="srdf5456f4h65dfsf" data={this.state.Jtf} />
        <Rgt code="ffgjdf2165fd" data={this.state.Rgt} />
      </>,
      <>
        <div className="mt-3 mb-3">
          <strong>Item 12.12.7</strong> As máquinas e equipamentos fabricados a
          partir de 24 de dezembro de 2011 devem possuir em local visível as
          seguintes informações indeléveis:
        </div>
        <div>
          <p>
            <strong>a)</strong> razão social, CNPJ e endereço do fabricante ou
            importador;
          </p>
          <p>
            <strong>b)</strong> informação sobre tipo, modelo e capacidade;
          </p>
          <p>
            <strong>c)</strong> número de série ou identificação, e ano de
            fabricação;
          </p>
          <p>
            <strong>d)</strong> número de registro do fabricante/importador ou
            do profissional legalmente habilitado no Conselho Regional de
            Engenharia e Agronomia - CREA; e
          </p>
          <p>
            <strong>e)</strong> peso da máquina ou equipamento.
          </p>
        </div>

        <Situation
          resp={this.handleResponse}
          code="fgdfdfg165fdhsgh"
          data={this.state.Situation}
        />
        <Jtf code="srdf545fdgdfgdyrdtf" data={this.state.Jtf} />
        <Rgt code="ffgasdtgrr2165fd" data={this.state.Rgt} />

        <p>
          <strong> Item 12.12.7.1</strong> As máquinas e equipamentos fabricados
          antes de 24 de dezembro de 2011 devem possuir em local visível as
          seguintes informações:
        </p>
        <p>
          <strong>a)</strong> informação sobre tipo, modelo e capacidade;{" "}
        </p>
        <p>
          <strong>b)</strong> número de série ou, quando inexistente,
          identificação atribuída pela empresa.
        </p>
      </>,
      <>
        <Situation
          resp={this.handleResponse}
          code="fyt5465dfsfdhsgh"
          data={this.state.Situation}
        />
        <Jtf code="srdf54drt65651htf" data={this.state.Jtf} />

        <Rgt code="32rsrd54" data={this.state.Rgt} />

        <p>
          <strong> Item 12.12.8</strong> Para advertir os trabalhadores sobre os
          possíveis perigos, devem ser instalados dispositivos indicadores, se
          necessária a leitura qualitativa ou quantitativa para o controle de
          segurança.
        </p>

        <Situation
          resp={this.handleResponse}
          code="fgddtt65.46dsasgh"
          data={this.state.Situation}
        />
        <Jtf code="srdfadfsg65+fsf" data={this.state.Jtf} />
        <Rgt code="ffgjdfs654dsf5fd" data={this.state.Rgt} />

        <div className="mt-3 mb-3">
          <strong>6.13 Manuais</strong>
        </div>

        <p>
          <strong> Item 12.13.1</strong> As máquinas e equipamentos devem
          possuir manual de instruções fornecido pelo fabricante ou importador,
          com informações relativas à segurança em todas as fases de utilização.
        </p>

        <Situation
          resp={this.handleResponse}
          code="32dsrty654fd5g4g"
          data={this.state.Situation}
        />
        <Jtf code="fd5try654fd6g64" data={this.state.Jtf} />
        <Rgt code="rdfs6545dg65fdh" data={this.state.Rgt} />
        <Obs code="sdf98485dfg5f54" data={this.state.Obs} />

        <strong>6.14 Procedimentos de trabalho e segurança</strong>

        <div className="mt-3 mb-3">
          <p>
            <strong> Item 12.14.1</strong> Devem ser elaborados procedimentos de
            trabalho e segurança para máquinas e equipamentos, específicos e
            padronizados, a partir da apreciação de riscos.
          </p>
        </div>
        <Situation
          resp={this.handleResponse}
          code="32dsrytu98dt4g"
          data={this.state.Situation}
        />
        <Jtf code="fd5trst98df64" data={this.state.Jtf} />
        <Rgt code="rdfsrdg564fgfdh" data={this.state.Rgt} />
        <Obs code="sdf98dfg54dsf54" data={this.state.Obs} />

        <strong>6.15 Capacitação</strong>

        <div className="mt-3 mb-3">
          <p>
            <strong> Item 12.16.1</strong> A operação, manutenção, inspeção e
            demais intervenções em máquinas e equipamentos devem ser realizadas
            por trabalhadores habilitados ou qualificados ou capacitados, e
            autorizados para este fim.
          </p>
        </div>

        <Situation
          resp={this.handleResponse}
          code="32dssdfag56fdt4g"
          data={this.state.Situation}
        />
        <Jtf code="fd5fgh564gfh4" data={this.state.Jtf} />
        <Rgt code="rdfsrdgadfs56dh" data={this.state.Rgt} />
        <Obs code="sdf98d654fdsgf4" data={this.state.Obs} />
      </>,

      <>
        <strong>6.16 Disposições finais</strong>

        <div className="mt-3 mb-3">
          <p>
            <strong> Item 12.18.1</strong> O empregador deve manter à disposição
            da Auditoria-Fiscal do Trabalho relação atualizada das máquinas e
            equipamentos.
          </p>
        </div>

        <Situation
          resp={this.handleResponse}
          code="32dfdgfdhg"
          data={this.state.Situation}
        />
        <Jtf code="fd5dfgfdjhg4" data={this.state.Jtf} />
        <Rgt code="rdfgjdghdgfh" data={this.state.Rgt} />
        <Obs code="sd6fghffsgf4" data={this.state.Obs} />
      </>,
    ];

    var TemCortina =
      this.state.CortinaLuz.length > 0
        ? [
            <>
              <div className="mt-3 mb-3">
                <strong>6.17 Outros requisitos específicos de segurança</strong>
              </div>

              <p>
                <strong> Item 12.17.5</strong> Para fins de aplicação desta NR,
                os Anexos contemplam obrigações, disposições especiais ou
                exceções que se aplicam a um determinado tipo de máquina ou
                equipamento, em caráter prioritário aos demais requisitos desta
                NR, sem prejuízo ao disposto em NR especifica.
              </p>
              <Situation
                resp={this.handleResponse}
                code="drtygdsfgdfg"
                data={this.state.Situation}
              />
              <Jtf code="fdfghfgdsrytg4" data={this.state.Jtf} />
              <Rgt code="OPIguyo8ughiu" data={this.state.Rgt} />

              <p>
                <p>
                  <strong>Registro: </strong>Cálculo das distâncias mínimas de
                  segurança para instalação de detectores de presença
                  optoeletrônicos – ESPS usando cortina de luz – AOPD O cálculo
                  da distância mínima, conforme ISO 12855 e NR-12 Anexo IB é a
                  seguinte: 𝑆=(𝐾×𝑇)+𝐶
                </p>{" "}
                <p>
                  Onde: S = é a mínima distância, em milímetros, da zona de
                  perigo até o ponto, linha ou plano de detecção;
                </p>
                <p>
                  {" "}
                  K = é um parâmetro em milímetros por segundo, derivado dos
                  dados de velocidade de aproximação do corpo ou partes do
                  corpo;
                </p>{" "}
                <p>
                  T = é a performance de parada de todo o sistema – tempo de
                  resposta total em segundos;
                </p>{" "}
                <p>
                  C = é a distância adicional em milímetros, baseada na intrusão
                  contra a zona de perigo antes da atuação do dispositivo de
                  proteção.
                </p>
              </p>
            </>,
          ]
        : [];

    var CortinaLuz =
      this.state.CortinaLuz.length > 0
        ? this.state.CortinaLuz.map((item, index) => (
            <>
              <table className="table table-sm ntbl table-bordered">
                <thead>
                  <tr>
                    <th>Título</th>
                    <th>Valor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Fabricante</td>
                    <td>{item.fabricante || "---"}</td>
                  </tr>
                  <tr>
                    <td>Modelo</td>
                    <td>{item.modelo || "---"}</td>
                  </tr>
                  <tr>
                    <td>Campo de Proteção</td>
                    <td>{item.campo_protecao || "---"}</td>
                  </tr>
                  <tr>
                    <td>Tipo de Proteção</td>
                    <td>IP65</td>
                  </tr>
                  <tr>
                    <td>Categoria de Segurança</td>
                    <td>Cat.4 SIL3</td>
                  </tr>
                  <tr>
                    <td>Tensão de Alimentação</td>
                    <td>24V</td>
                  </tr>
                  <tr>
                    <td>Tempo de Reação</td>
                    <td>{item.tempo_reacao || "---"}</td>
                  </tr>
                </tbody>
              </table>
              <div>
                <p>
                  {" "}
                  Cálculo da distância mínima para a configuração apresentada:
                  Menor Distância Real entre a área de proteção da cortina de
                  luz e a área de risco = {item.cortina_luz + "mm" || "?"}{" "}
                </p>
                <p>
                  <p>
                    {" "}
                    Velocidade de aproximação conforme{" "}
                    <strong>NR-12 = {item.nr12} mm/s</strong>
                  </p>
                  <p>
                    {" "}
                    Tempo de resposta conforme manual de instruções da cortina
                    de luz = <strong>{item.tempo_reacao + "ms" || "?"}</strong>
                  </p>
                </p>
                <p>
                  Distância adicional conforme Quadro I anexo I da NR-12 ={" "}
                  {this.DistanciaAdc(item.tempo_reacao)}
                  mm 𝑆=({item.nr12 || "?"}×{item.tempo_reacao / 1000 || "?"}
                  )+ {this.DistanciaAdc(item.tempo_reacao)}{" "}
                  <strong>
                    𝑺=
                    {item.nr12 * (item.tempo_reacao / 1000) +
                      this.DistanciaAdc(item.tempo_reacao)}
                    mm
                  </strong>
                </p>
                <div>
                  <strong>Resultado:</strong>{" "}
                  {item.nr12 * (item.tempo_reacao + item.t2 / 1000) + item.t1}
                </div>
              </div>
            </>
          ))
        : [];

    var itens4 = [
      <>
        <div className="mt-1">
          <p>
            Como a distância real é maior que a distância mínima da zona de
            perigo até o plano de detecção, o resultado é o seguinte:
          </p>
          <div className="mt-3 mb-3">
            <strong>Situação</strong>
          </div>
          <div></div>
          <div>
            <strong>AÇÃO CORRETIVA: N/A</strong>
          </div>
          <div className="mt-3 mb-3">
            Item 12.17.5.1 Nas situações onde os itens dos Anexos conflitarem
            com os itens da parte geral da NR, prevalecem os requisitos do
            anexo.
          </div>
          <div className="mt-3">
            <Situation
              resp={this.handleResponse}
              code="OBIuyfyf"
              data={this.state.Situation}
            />
            <Jtf code="KYRVSutdfo" data={this.state.Jtf} />
            <Rgt code="Ktvriby" data={this.state.Rgt} />
          </div>
          <div className="mt-3 mb-3">
            <strong>7. SITUAÇÃO DE CONFORMIDADE</strong>
          </div>
          <div>
            <table class="table table-sm ntbl table-bordered mt-3">
              <thead>
                <tr>
                  <th scope="col">Descrição</th>
                  <th scope="col">Valor Nominal</th>

                  <th scope="col">Percentil</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Total de itens aplicáveis avaliados</td>
                  <td>
                    {this.state.responses.a +
                      this.state.responses.b +
                      this.state.responses.c}
                  </td>
                  <td>100%</td>
                </tr>

                <tr>
                  <td>Total de itens conforme</td>
                  <td>{this.state.responses.b}</td>
                  <td>
                    {Math.round(
                      (this.state.responses.b /
                        (this.state.responses.a +
                          this.state.responses.b +
                          this.state.responses.c)) *
                        100
                    )}
                    %
                  </td>
                </tr>

                <tr>
                  <td>Total de itens não conforme</td>
                  <td>{this.state.responses.c}</td>
                  <td>
                    {Math.round(
                      (this.state.responses.c /
                        (this.state.responses.a +
                          this.state.responses.b +
                          this.state.responses.c)) *
                        100
                    )}
                    %
                  </td>
                </tr>

                <tr>
                  <td>Itens não aplicáveis</td>
                  <td>{this.state.responses.a}</td>
                  <td>
                    {Math.round(
                      (this.state.responses.a /
                        (this.state.responses.a +
                          this.state.responses.b +
                          this.state.responses.c)) *
                        100
                    )}
                    %
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div>
          <div style={{ textAlign: "center" }}>
            <h3>Situação</h3>
          </div>
          <div className="d-flex justify-content-center">
            <SituacaoGrafico
              values={{
                cf: this.state.responses.b,
                na: this.state.responses.a,
              }}
            />
          </div>
        </div>
      </>,
      <>
        <div className="mt-3 mb-3">
          <strong>8. PARECER TÉCNICO</strong>
        </div>

        <div className="mb-3">
          <strong>Parecer Técnico - Fresadora FC-400</strong>
        </div>

        <p>
          Considerando todos os sistemas de segurança instalados no equipamento
          denominado {this.ShowText("Uyfibi76")}, como a instalação das
          proteções fixas, móveis, sensores, monitoramento dos dispositivos de
          segurança pelo relé de segurança e demais itens relacionados à
          segurança, CONCLUI-SE que o equipamento oferece segurança necessária e
          encontra-se adequado para utilização e operação segundo a Norma
          Regulamentadora NR-12 e atendendo à categoria de segurança necessária
          segundo apreciação de Riscos – {this.ShowText("rfy7iof8y7")}.
        </p>

        <div className="mt-3 mb-3">
          <strong>Item 12.1.9 Cabe aos trabalhadores:</strong>
        </div>
        <div class="item">
          <strong>a)</strong> Cumprir todas as orientações relativas aos
          procedimentos seguros de operação, alimentação, abastecimento,
          limpeza, manutenção, inspeção, transporte, desativação, desmonte e
          descarte das máquinas e equipamentos;
        </div>
        <div class="item">
          <strong>b)</strong> Não realizar qualquer tipo de alteração nas
          proteções mecânicas ou dispositivos de segurança de máquinas e
          equipamentos, de maneira que possa colocar em risco a sua saúde e
          integridade física ou de terceiros;
        </div>
        <div class="item">
          <strong>c)</strong> Comunicar seu superior imediato se uma proteção ou
          dispositivo de segurança foi removido, danificado ou se perdeu sua
          função;
        </div>
        <div class="item">
          <strong>d)</strong> Participar dos treinamentos fornecidos pelo
          empregador para atender às exigências/requisitos descritos nesta NR;
        </div>
        <div class="item">
          <strong>e)</strong> Colaborar com o empregador na implementação das
          disposições contidas nesta NR.
        </div>
        <div className="mt-3 mb-3">
          <strong>9. PLANO DE AÇÃO</strong>
        </div>
        <p>
          Elaborar procedimento de trabalho detalhado passo a passo de cada
          tarefa em operação normal ou ajuste da máquina.
        </p>
        <p>
          <strong>9.1 </strong>Ações
        </p>
        <p>
          <strong>N.A</strong>
        </p>
        <p>
          <strong>9.2</strong> Prazos
        </p>
        <p>
          <strong>30 dias</strong>
        </p>
      </>,
    ];

    const images =
      this.state.data.images.length > 0
        ? this.state.data.images.map((item, index) => (
            <>
              <span style={{ display: "none" }}>smr-10</span>
              {index == 0 && (
                <div style={{ margin: 10, marginTop: 5 }}>
                  <strong>5 - EQUIPAMENTO AVALIADO- DESCRIÇÃO</strong>
                  <div style={{ marginTop: 20 }}>Máquina/Equipamento: </div>
                  <div style={{ marginTop: 20 }}>
                    <strong>Visão Geral:</strong>{" "}
                  </div>
                </div>
              )}
              <div className="vga" style={{ display: "table", margin: "auto" }}>
                <img
                  style={{ border: "solid 2px", width: 500, height: 500 }}
                  src={`${apiUrl}/img/` + item.img}
                />
              </div>
            </>
          ))
        : [];

    var txts =
      this.state.TextPage.length > 0
        ? this.state.TextPage.map((item, index) => (
            <>
              <div>{this.quebraLinha(item.text)}</div>
            </>
          ))
        : [];

    var Anexos1 =
      Array.isArray(this.state.Anexos?.["OIUGpiu"]) &&
      this.state.Anexos["OIUGpiu"].length > 0
        ? this.state.Anexos["OIUGpiu"].map((item, index) => (
            <>
              <div className="img-anx">
                <img src={`${apiUrl}/img/` + item.file} />
              </div>
            </>
          ))
        : [];

    var Anexos2 =
      Array.isArray(this.state.Anexos?.["LKUygoilub"]) &&
      this.state.Anexos["LKUygoilub"].length > 0
        ? this.state.Anexos["LKUygoilub"].map((item, index) => (
            <>
              {index === 0 && (
                <>
                  <strong>ANEXOS</strong>
                </>
              )}
              <div className="img-anx">
                <img src={`${apiUrl}/img/` + item.file} />
              </div>
            </>
          ))
        : [];

    var Anexos3 =
      Array.isArray(this.state.Anexos?.["IOugyiUYFbi"]) &&
      this.state.Anexos["IOugyiUYFbi"].length > 0
        ? this.state.Anexos["IOugyiUYFbi"].map((item, index) => (
            <>
              {index === 0 && (
                <>
                  <strong>
                    ANEXO I - Anotação de Responsabilidade Técnica – ART
                  </strong>
                </>
              )}
              <div className="img-anx">
                <img src={`${apiUrl}/img/` + item.file} />
              </div>
            </>
          ))
        : [];

    var Anexos4 =
      Array.isArray(this.state.Anexos?.["IUbfyrvdli"]) &&
      this.state.Anexos["IUbfyrvdli"].length > 0
        ? this.state.Anexos["IUbfyrvdli"].map((item, index) => (
            <>
              {index === 0 && (
                <>
                  <strong>
                    ANEXO II - Certificação de Dispositivos de Segurança
                  </strong>
                </>
              )}
              <div className="img-anx">
                <img src={`${apiUrl}/img/` + item.file} />
              </div>
            </>
          ))
        : [];

    var Anexos5 =
      Array.isArray(this.state.Anexos?.["IB6tdfkrF"]) &&
      this.state.Anexos["IB6tdfkrF"].length > 0
        ? this.state.Anexos["IB6tdfkrF"].map((item, index) => (
            <>
              {index === 0 && (
                <>
                  <strong>
                    ANEXO III - Esquema Elétrico dos dispositivos de segurança e
                    emergência
                  </strong>
                </>
              )}
              <div className="img-anx">
                <img src={`${apiUrl}/img/` + item.file} />
              </div>
            </>
          ))
        : [];

    var rgt1 =
      Array.isArray(this.state.Rgt?.["OIugbolijn"]) &&
      this.state.Rgt["OIugbolijn"].length > 0
        ? this.state.Rgt["OIugbolijn"].map((item, index) => (
            <>
              {item.type == 1 && (
                <div className="mt-3">
                  <div>
                    <strong>Registro:</strong> {item.text}
                  </div>
                </div>
              )}

              {item.type == 2 && (
                <div className="mt-3">
                  <div className="d-flex justify-content-center">
                    {item.i1 && <img src={`${apiUrl}/img/${item.i1}`} />}

                    {item.i2 && (
                      <img
                        style={{ marginLeft: "10px" }}
                        src={`${apiUrl}/img/${item.i2}`}
                      />
                    )}
                  </div>
                  <div className="d-flex justify-content-center mt-3">
                    {item.i3 && <img src={`${apiUrl}/img/${item.i3}`} />}

                    {item.i4 && (
                      <img
                        style={{ marginLeft: "10px" }}
                        src={`${apiUrl}/img/${item.i4}`}
                      />
                    )}
                  </div>
                </div>
              )}
            </>
          ))
        : [];
    var TxtAnx = [
      <>
        <div className="mt-3 mb-3">
          <strong>10. Controle de revisões</strong>
        </div>
        <div className="mb-3">
          <strong>Documento Original.</strong>
        </div>
        {this.ShowText("ghfgdutrmty")}
      </>,
      <>
        <div className="mt-3 mb-3">
          <strong>11. Anexos</strong>
        </div>

        {this.ShowText("OUfgy8gyuiyboi")}
      </>,
    ];

    const TotalP = [
      ...itens,
      ...images,
      ...itens2,
      ...txts,
      ...rgt1,
      ...itens3,
      ...TemCortina,
      ...CortinaLuz,
      ...itens4,
      ...TxtAnx,
      ...Anexos1,

      ...Anexos3,
      ...Anexos4,
      ...Anexos5,
    ];

    var Final = [
      <>
        <div>
          <strong>12 ENCERRAMENTO</strong>
        </div>
        <div className="mt-3">
          Este documento é composto por{" "}
          {this.NumeroPorExtenso(TotalP.length + 3)} páginas numeradas e
          assinado na última pelos responsáveis técnicos.
        </div>
        <div className="d-flex justify-content-center mt-3">
          {this.ShowText("OUIygfouy")}
        </div>
        <div className="mt-3">
          <div className="d-flex justify-content-center">
            <strong> CORPO TÉCNICO RESPONSÁVEL</strong>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderBottom: "solid 1px black",
              marginTop: 150,
            }}
          ></div>
          <div className="d-flex justify-content-center mt-3">
            {this.state.data.tecs.length > 0 ? (
              <strong>{this.state.data.tecs[0].nome}</strong>
            ) : (
              "---"
            )}
          </div>
        </div>
      </>,
    ];
    const combinedPages = [
      ...itens,
      ...images,
      ...itens2,
      ...txts,
      ...rgt1,
      ...itens3,
      ...TemCortina,
      ...CortinaLuz,
      ...itens4,
      ...TxtAnx,
      ...Anexos1,

      ...Anexos3,
      ...Anexos4,
      ...Anexos5,
      ...Final,
    ];

    return (
      <>
        <section id="p2" className="ac5 pvw" style={{ marginBottom: 0 }}>
          <div
            className="text-center"
            style={{ fontWeight: "bold", marginTop: 80, fontSize: 18 }}
          >
            LTV - LAUDO TÉCNICO DE VALIDAÇÃO EM CONFORMIDADE COM A NORMA
            REGULAMENTADORA N°12.
          </div>
          <div
            style={{
              fontWeight: "bold",
              fontSize: 20,
              textAlign: "center",
              marginTop: 50,
              marginBottom: -10,
            }}
          >
            EXECUTANTE:
          </div>
          <div
            style={{
              fontWeight: "bold",
              fontSize: 20,
              textAlign: "center",
              marginTop: 20,
            }}
          >
            {this.state.data.executante.razao_social}
          </div>
          <div className="al-c" style={{ marginTop: 18 }}>
            <img src={`${apiUrl}/img/${this.state.data.executante.logo}`} />
          </div>

          <div
            style={{
              fontWeight: "bold",
              fontSize: 18,
              textAlign: "center",
              marginTop: 50,
              marginBottom: -10,
            }}
          >
            CONTRATANTE:
          </div>
          <div
            style={{
              fontWeight: "bold",
              fontSize: 18,
              textAlign: "center",
              marginTop: 20,
            }}
          >
            {this.state.data.contratante.razao_social}
          </div>
          <div className="al-c" style={{ marginTop: 20 }}>
            <img src={`${apiUrl}/img/${this.state.data.contratante.logo}`} />
          </div>
          <div
            style={{
              marginTop: 50,
              fontWeight: "bold",
              fontSize: 18,
              marginLeft: 40,
            }}
          >
            MÁQUINA / EQUIPAMENTO: {this.ShowText("Uyfibi76")}
          </div>
          <div
            style={{
              fontWeight: "bold",
              fontSize: 18,
              marginLeft: 40,
            }}
          >
            ART VINCULADA: {this.ShowText("liyfubinyu")}
          </div>
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: 18,
              marginTop: 50,
            }}
          >
            {this.ShowText("OGNyfn7y")}
          </div>
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: 18,
              marginTop: 10,
            }}
          >
            {this.ShowText("INgb79F75d")}
          </div>

          <div>
            <div
              class="aqv-d"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: 50,
                marginBottom: 20,
              }}
            ></div>

            <div
              className="d-flex justify-content-end MB-3"
              style={{ marginRight: 50 }}
            >
              <div className="d-flex flex-column">
                <div className="d-flex justify-content-center mb-2">
                  <strong>Arquivos Digitais:</strong>
                </div>
                <ImageView
                  type="image"
                  code="INgb79F75d"
                  width={150}
                  height={150}
                  data={this.state.ImageView}
                />
              </div>
            </div>
            <div style={{ marginLeft: 50, marginRight: 50, marginTop: 20 }}>
              <p style={{ fontSize: 13 }}>
                As informações deste documento são confidenciais. Informações
                fornecidas e destinadas ao uso interno da empresa
                executante/contratante não sendo permitida a cópia, reprodução
                total ou parcial de qualquer tipo de informação deste documento
                sem a expressa autorização das partes envolvidas sob as penas da
                lei.
              </p>
            </div>
          </div>
        </section>
        {combinedPages.map((PageComponent, index) => (
          <section id="p2" className="ac5 pvw" style={{ marginBottom: 0 }}>
            <div class="dados">
              <div class="area-mtp">
                <div class="area-a">
                  <div>
                    <strong>Data:</strong> {this.ShowText("INgb79F75d")}
                  </div>
                  <div className="aln-e">
                    <strong>Rev:</strong> {this.ShowText("IDTbiyu")}
                  </div>
                  <div className="aln-e">
                    <strong>Número:</strong> {this.ShowText("KBYufnt")}
                  </div>
                  <div>
                    <strong>Página:</strong> {` `} {index + 3} de{" "}
                    {combinedPages.length + 2}
                  </div>
                </div>

                <div class="area-b">
                  <div style={{ fontWeight: "bold" }}>
                    LTV - LAUDO TÉCNICO DE VALIDAÇÃO
                  </div>
                  <div>{this.ShowText("Uyfibi76")}</div>
                </div>

                <div class="area-c">
                  <img
                    alt="logo"
                    src={`${apiUrl}/img/${this.state.data.contratante.logo}`}
                  />
                </div>
              </div>
              <div>
                <div style={{ marginLeft: 20 }}>{PageComponent}</div>
              </div>

              <TextView
                link
                estilo="ex-s"
                type="text"
                code="OIfy9n76fo"
                data={this.state.TextView}
              />
            </div>
          </section>
        ))}
      </>
    );
  }
}

export default PreviewLau;

import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPlus, faSave } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { apiUrl } from "../../config";
import { json } from "react-router-dom";
import { StaticDialog } from "react-st-modal";
class Jtf extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      ntext: "",
      textList: [],
      psq: "",
      text: "undefined",
    };
  }
  quebraLinha = (texto) => {
    if (texto == null || texto == undefined) return "";

    return texto.split("\n").map((linha, index) => (
      <React.Fragment key={index}>
        {linha.split("*").map((parte, i) =>
          i % 2 === 1 ? (
            <strong key={i}>{parte}</strong> // Aplica negrito nas partes entre asteriscos
          ) : (
            parte
          )
        )}
        {index < texto.split("\n").length - 1 && <br />}
      </React.Fragment>
    ));
  };
  componentDidMount() {
    const matchedItem = this.props.data.find(
      (item) => item.code === this.props.code
    );
    this.setState({
      data: this.props.data,
      text: matchedItem != undefined ? matchedItem.text : "",
    });
  }

  handleChange() {
    var text = this.state.ntext;

    if (text.trim().length == 0) {
      //event.target.value = '';
    } else {
      fetch(`${apiUrl}/Jtf`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ref: window.location.pathname.split("/")[3],
          code: this.props.code,
          text,
        }),
      }).then((response) => {
        response.json().then((data) => {
          this.setState({
            text,
            modal: false,
          });
          toast.success("Salvo!");
        });
      });
    }
  }

  render() {
    const { type, vz } = this.props;
    const listaFiltrada = this.state.textList.filter((item) =>
      item.text.toLowerCase().includes(this.state.psq.toLowerCase())
    );

    if (
      window.location.pathname.split("/")[1] == "previewLau" ||
      window.location.pathname.split("/")[1] == "previewLau2"
    ) {
      return (
        <div className="">
          <strong>Justificativa: </strong>{" "}
          {this.state.text != ""
            ? this.quebraLinha(this.state.text)
            : "Não Aplicável(eis) "}
        </div>
      );
    }

    if (vz == "v") {
      return <div code={this.props.code}>{this.state.text}</div>;
    }

    return (
      <>
        <div class="" style={{ marginTop: 15 }}>
          <div className="card">
            <div className="card-header">Justificativa:</div>
            <div className="card-body">
              <div
                className="mt-1"
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  padding: "5px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  fetch(`${apiUrl}/text-list`, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                      "Content-Type": "application/json",
                    },
                  }).then((response) => {
                    if (response.status == 200) {
                      response.json().then((data) => {
                        this.setState({ textList: data });
                      });
                    }
                  });

                  this.setState({ modal: true, ntext: this.state.text });
                }}
              >
                {this.quebraLinha(this.state.text) ||
                  this.quebraLinha(this.state.text) ||
                  "Digite:"}
              </div>
            </div>
          </div>
        </div>
        <StaticDialog
          isOpen={this.state.modal}
          title="TEXTO:"
          onAfterClose={(result) => {
            this.setState({ modal: false });
          }}
        >
          <div style={{ padding: 10 }}>
            <textarea
              style={{ height: 150 }}
              class="form-control form-control-sm"
              onChange={(e) => {
                this.setState({ ntext: e.target.value });
              }}
              value={this.state.ntext}
              placeholder="Digite:"
            ></textarea>
            <input
              style={{
                width: "100%",
                height: 35,
                marginTop: 10,
              }}
              type="text"
              onChange={(e) => {
                this.setState({ psq: e.target.value });
              }}
              placeholder="Pesquisar:"
            />
            <div class="area-ptxt">
              {listaFiltrada.length > 0 ? (
                <>
                  <ul>
                    {listaFiltrada.map((item) => (
                      <>
                        <li
                          onClick={() => {
                            this.setState({ ntext: item.text });
                          }}
                        >
                          {this.quebraLinha(item.text)}
                        </li>
                      </>
                    ))}
                  </ul>
                </>
              ) : (
                <>Não há nada aqui!</>
              )}
            </div>
          </div>

          <hr />

          <div
            class="d-flex justify-content-end"
            style={{
              margin: 10,
            }}
          >
            <button
              type="button"
              class="btn btn-primary"
              onClick={() => {
                this.handleChange();
              }}
              disabled={this.state.text != this.state.ntext ? false : true}
            >
              <FontAwesomeIcon icon={faSave} color="#fff" /> Salvar
            </button>
          </div>
        </StaticDialog>
      </>
    );
  }
}

export default Jtf;

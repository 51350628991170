import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { StaticDialog, useDialog } from "react-st-modal";

import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEye,
  faUniversity,
  faPen,
  faUpload,
  faPlus,
  faArrowLeft,
  faAngleLeft,
  faCopy,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";

import p404 from "../../images/undraw_text_files_au1q.png";
import { apiUrl } from "../../config";
class LaudoList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loader: true,
      error: false,
      data: [],
      Ptxt: "",
      Tpsq: "Nome",
      search: [],
      busca: false,
      trash: {},
    };
  }

  componentDidMount() {
    document.title = "Apreciação de Riscos do solicitante";
    fetch(
      `${apiUrl}/laudo-tecnico/${window.location.pathname.split("/")[2]}/list`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Cookies.get("auth_token"),
        },
      }
    ).then((response) => {
      if (response.status == 200) {
        response.json().then((data) => {
          this.setState({ data, Loader: false });
        });
      }
      if (response.status == 404) {
        this.setState({ error: true, Loader: false });
      }
    });
  }

  converteParaFormatoBrasileiro(dataISO) {
    const data = new Date(dataISO);
    return data.toLocaleString("pt-BR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  }

  _Busca = () => {
    fetch(
      `${apiUrl}/busca-cotacao/${window.location.pathname.split("/")[2]}/`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Cookies.get("auth_token"),
        },
        body: JSON.stringify({
          Tpsq: this.state.Tpsq,
          Ptxt: this.state.Ptxt,
        }),
      }
    ).then((response) => {
      if (response.status == 200) {
        response.json().then((search) => {
          this.setState({ busca: true, search });
        });
      }
    });
  };

  getFilteredData() {
    const { data, Ptxt } = this.state;
    if (!Ptxt) return data;

    // Filtra os dados com base no termo de busca
    return data.filter((item) =>
      Object.values(item).some(
        (value) =>
          value && value.toString().toLowerCase().includes(Ptxt.toLowerCase())
      )
    );
  }

  render() {
    if (this.state.Loader == true) {
      return (
        <>
          <div className="loader-container">
            <div class="loader"></div>
          </div>
        </>
      );
    }
    if (this.state.error == true) {
      return (
        <>
          <div class="p404">
            <img src={p404} />
            <p>Desculpe, Esse documento não foi encontrado.</p>
            <button
              type="submit"
              class="btn btn-primary float-left ml-2"
              onClick={() => {
                this.props.navigate(
                  `/${window.location.pathname.split("/")[1]}`
                );
              }}
            >
              {" "}
              <FontAwesomeIcon color="#fff" icon={faArrowLeft} /> Início
            </button>
          </div>
        </>
      );
    }
    const filteredData = this.getFilteredData();
    return (
      <>
        <header class="bg-white shadow">
          <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <button
              class="font-semibold text-xl text-gray-800 leading-tight"
              onClick={() => {
                this.props.navigate(-1);
              }}
            >
              <FontAwesomeIcon icon={faAngleLeft} />
              Apreciação de Riscos{" "}
            </button>
          </div>
        </header>
        <main>
          <div class="py-1">
            <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div class="col-12">
                <div class="row"></div>

                <div class="row">
                  <div class="py-12">
                    <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                      <div class="col-12">
                        <div class="card">
                          <div class="card-header d-flex justify-content-between">
                            <div className="area-busca">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Digite:"
                                onChange={(e) => {
                                  this.setState({ Ptxt: e.target.value });
                                }}
                                name="nome"
                              ></input>
                              <button
                                class="btn btn-xs btn-primary"
                                onClick={() => {
                                  this._Busca();
                                }}
                              >
                                <FontAwesomeIcon icon={faSearch} />
                              </button>
                            </div>

                            <div className="alh-bt">
                              <button
                                class="btn btn-xs btn-primary"
                                onClick={(e) => {
                                  e.target.classList.add("load");

                                  fetch(
                                    `${apiUrl}/laudo-tecnico/${
                                      window.location.pathname.split("/")[2]
                                    }/criar-documento`,
                                    {
                                      method: "POST",
                                      credentials: "include",
                                      headers: {
                                        "Content-Type": "application/json",
                                        Authorization:
                                          "Bearer " + Cookies.get("auth_token"),
                                      },
                                      body: JSON.stringify({
                                        Tpsq: this.state.Tpsq,
                                        Ptxt: this.state.Ptxt,
                                      }),
                                    }
                                  ).then((response) => {
                                    e.target.classList.remove("load");
                                    if (response.status == 200) {
                                      response.json().then((r) => {
                                        this.setState((prevState) => ({
                                          data: [
                                            {
                                              token: r.token,
                                              sigla: "A definir",
                                              created_at: r.created_at,
                                              executante: {},
                                              contratante: {
                                                razao_social: r.razao_social,
                                                cnpj: r.cnpj,
                                              },
                                            },
                                            ...prevState.data,
                                          ],
                                        }));
                                        toast.success("Documento criado");
                                      });
                                    }
                                  });
                                }}
                              >
                                <FontAwesomeIcon color="#fff" icon={faPlus} />{" "}
                                Novo documento
                              </button>
                            </div>
                          </div>

                          <div class="card-body">
                            <div class="row">
                              <div class="col-12">
                                <div>
                                  {/* Campo de busca */}

                                  {filteredData.length > 0 ? (
                                    <>
                                      <table className="table table-sm table-bordered">
                                        <thead>
                                          <tr>
                                            <th scope="col">NOME</th>
                                            <th scope="col">EXECUTANTE</th>
                                            <th scope="col">CONTRATANTE</th>
                                            <th scope="col">DATA</th>
                                            <th>ARQUIVOS</th>
                                            <th scope="col">Opções</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {filteredData.map((item, index) => (
                                            <tr key={index}>
                                              <td>
                                                {item.sigla === ""
                                                  ? "A definir"
                                                  : item.sigla}
                                              </td>
                                              <td>
                                                {Object.keys(item.executante)
                                                  .length === 0 ? (
                                                  <>A definir</>
                                                ) : (
                                                  `${item.executante.razao_social} - ${item.executante.cnpj}`
                                                )}
                                              </td>
                                              <td>
                                                {`${item.contratante.razao_social} - ${item.contratante.cnpj}`}
                                              </td>
                                              <td>
                                                {this.converteParaFormatoBrasileiro(
                                                  item.created_at
                                                )}
                                              </td>
                                              <td>
                                                <Link
                                                  to={`/laudo-tecnico/preview/${item.token}/files`}
                                                >
                                                  Acessar
                                                </Link>
                                              </td>
                                              <td>
                                                <div class="btn-group btn-group-sm">
                                                  <div class="btn-group btn-group-sm">
                                                    <a
                                                      class="btn btn-primary"
                                                      href={`/${
                                                        window.location.pathname.split(
                                                          "/"
                                                        )[1]
                                                      }/preview/${item.token}`}
                                                    >
                                                      <FontAwesomeIcon
                                                        color="#fff"
                                                        icon={faPen}
                                                      />
                                                    </a>

                                                    <button
                                                      className="btn btn-success"
                                                      onClick={(e) => {
                                                        e.target.classList.add(
                                                          "load"
                                                        );

                                                        var contratante =
                                                          item.contratante;
                                                        var executante =
                                                          item.executante;

                                                        fetch(
                                                          apiUrl +
                                                            `/laudo-tecnico/${item.token}/copy`,
                                                          {
                                                            method: "POST",
                                                            credentials:
                                                              "include",
                                                            headers: {
                                                              "Content-Type":
                                                                "application/json",
                                                              Authorization:
                                                                "Bearer " +
                                                                Cookies.get(
                                                                  "auth_token"
                                                                ),
                                                            },
                                                            body: JSON.stringify(
                                                              {
                                                                ref: document.location.pathname.split(
                                                                  "/"
                                                                )[2],
                                                              }
                                                            ),
                                                          }
                                                        )
                                                          .then((r) => {
                                                            e.target.classList.remove(
                                                              "load"
                                                            );
                                                            if (
                                                              r.status === 200
                                                            ) {
                                                              r.json().then(
                                                                (data) => {
                                                                  this.setState(
                                                                    {
                                                                      data: [
                                                                        {
                                                                          token:
                                                                            data.token,
                                                                          sigla:
                                                                            item.sigla.replace(
                                                                              /\d+/,
                                                                              String(
                                                                                data.nmr
                                                                              ).padStart(
                                                                                3,
                                                                                "0"
                                                                              )
                                                                            ),
                                                                          created_at:
                                                                            data.created_at,
                                                                          executante:
                                                                            {
                                                                              razao_social:
                                                                                executante.razao_social,
                                                                              cnpj: executante.cnpj,
                                                                            },
                                                                          contratante:
                                                                            {
                                                                              razao_social:
                                                                                contratante.razao_social,
                                                                              cnpj: contratante.cnpj,
                                                                            },
                                                                        },
                                                                        ...this
                                                                          .state
                                                                          .data,
                                                                      ],
                                                                    }
                                                                  );

                                                                  toast.success(
                                                                    "Documento copiado com sucesso"
                                                                  );
                                                                }
                                                              );
                                                            }
                                                          })
                                                          .catch((err) =>
                                                            console.log({})
                                                          );
                                                      }}
                                                    >
                                                      <FontAwesomeIcon
                                                        icon={faCopy}
                                                      />
                                                    </button>

                                                    <button
                                                      class="btn btn-danger"
                                                      onClick={() => {
                                                        this.setState({
                                                          trash: item,
                                                        });
                                                      }}
                                                    >
                                                      {" "}
                                                      <FontAwesomeIcon
                                                        icon={faTrash}
                                                      />
                                                    </button>
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </>
                                  ) : (
                                    <p className="text-center">
                                      Nenhum dado encontrado para a busca.
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <div id="modal-root"></div>
        <StaticDialog
          isOpen={Object.keys(this.state.trash).length > 0}
          title="Tem certeza de que deseja continuar?"
          onAfterClose={(result) => {
            this.setState({ trash: {} });
            // do something with dialog result
          }}
        >
          <div style={{ margin: 10, fontSize: 14 }}>
            Se você confirmar a exclusão, não será possível recuperar os dados
            posteriormente.
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-danger"
              onClick={(e) => {
                e.target.classList.add("load");
                var item = this.state.trash;

                fetch(`${apiUrl}/laudo-tecnico/remover/${item.token}`, {
                  method: "DELETE",
                  credentials: "include",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + Cookies.get("auth_token"),
                  },
                })
                  .then((response) => {
                    e.target.classList.remove("load");

                    if (response.status == 200) {
                      this.setState((prevState) => ({
                        data: prevState.data.filter(
                          (i) => i.token !== item.token
                        ),
                        trash: {},
                      }));
                      toast.success("O documento foi removido com sucesso!");
                    }
                  })
                  .catch((error) => {
                    e.target.classList.remove("load");
                    toast.error("Ocorreu um erro ao excluir o laudo.");
                  });
              }}
            >
              <FontAwesomeIcon color="#fff" icon={faTrash} /> Excluir
            </button>
          </div>
        </StaticDialog>
      </>
    );
  }
}

export default LaudoList;

import React, { Component } from "react";
import { apiUrl } from "../../config";
import { toast } from "react-toastify";

class GalleryModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
      currentPage: 1,
      itemsPerPage: 10,
    };
  }

  handleSearchChange = (event) => {
    this.setState({ searchTerm: event.target.value });
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };

  render() {
    const { gallery } = this.props;
    const { searchTerm, currentPage, itemsPerPage } = this.state;

    const filteredGallery = gallery
      ? gallery.filter((item) =>
          item.title.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : [];

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredGallery.slice(
      indexOfFirstItem,
      indexOfLastItem
    );

    const totalPages = Math.ceil(filteredGallery.length / itemsPerPage);

    const renderPagination = () => {
      const pageNumbers = [];
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }

      return (
        <nav aria-label="Page navigation">
          <ul className="pagination justify-content-center">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button
                className="page-link"
                onClick={() => this.handlePageChange(currentPage - 1)}
                aria-label="Previous"
              >
                <span aria-hidden="true">&laquo;</span>
              </button>
            </li>
            {pageNumbers.map((number) => (
              <li
                key={number}
                className={`page-item ${
                  currentPage === number ? "active" : ""
                }`}
              >
                <button
                  className="page-link"
                  onClick={() => this.handlePageChange(number)}
                >
                  {number}
                </button>
              </li>
            ))}
            <li
              className={`page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => this.handlePageChange(currentPage + 1)}
                aria-label="Next"
              >
                <span aria-hidden="true">&raquo;</span>
              </button>
            </li>
          </ul>
        </nav>
      );
    };

    return (
      <div>
        <div className="d-flex justify-content-center mt-3 mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Buscar por título..."
            value={searchTerm}
            onChange={this.handleSearchChange}
          />
        </div>

        {filteredGallery.length === 0 ? (
          <div>Nenhuma imagem encontrada para a pesquisa.</div>
        ) : (
          <div>
            <table className="table table-sm ntbl table-bordered">
              <thead>
                <tr>
                  <th>Imagem</th>
                  <th>Título</th>
                  <th>Ação</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((item, index) => (
                  <tr key={index}>
                    <td className="d-flex justify-content-center">
                      <img
                        src={`${apiUrl}/img/${item.img}`}
                        alt={item.title}
                        style={{ width: 100, height: 100 }}
                      />
                    </td>
                    <td>{item.title}</td>
                    <td>
                      <button
                        className="btn btn-primary"
                        onClick={(e) => {
                          e.target.classList.add("load");
                          var img = this.props.img;
                          fetch(`${apiUrl}/save-img-galery/`, {
                            method: "POST",
                            headers: {
                              "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                              id: img[0],
                              item: img[1],
                              file: item.img,
                            }),
                          }).then((response) => {
                            e.target.classList.remove("load");
                            if (response.status == 200) {
                              toast.success("Imagem salva com sucesso!");
                              this.props.onImageSelect(item.img);
                            }
                          });
                        }}
                      >
                        Selecionar
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {totalPages > 1 && renderPagination()}
          </div>
        )}
      </div>
    );
  }
}

export default GalleryModal;

import React, { Component } from "react";
import logo from "../../../src/images/logo-c.png";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, NavLink } from "react-router-dom";
import { apiUrl } from "../../config";
import { Drop, Dropdown, Item } from "../Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enableMenu: false,
    };
  }

  getClassNames = ({ isActive }) => {
    return isActive
      ? "inline-flex items-center px-1 pt-1 border-b-2 border-indigo-400 text-sm font-medium leading-5 text-gray-900 focus:outline-none focus:border-indigo-700 "
      : "inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300";
  };

  render() {
    return (
      <>
        <nav class="bg-white border-b border-gray-100">
          <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="flex justify-between h-16 items-center">
              <div className="flex">
                <div class="shrink-0 flex items-center">
                  <div class="logo-t">
                    <Link to="/">
                      <img src={logo} width={100} height={100} />
                    </Link>
                  </div>
                  <div class="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex">
                    <NavLink exact className={this.getClassNames} to="/">
                      {" "}
                      Apreciação de Riscos{" "}
                    </NavLink>
                  </div>
                  <div class="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex">
                    <NavLink
                      exact
                      className={this.getClassNames}
                      to="/laudo-tecnico"
                    >
                      Laudo técnico
                    </NavLink>
                  </div>
                  <div class="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex">
                    <NavLink
                      className={this.getClassNames}
                      to="/cadastro-executante"
                    >
                      {" "}
                      Cadastro do executante{" "}
                    </NavLink>
                  </div>
                  <div class="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex">
                    <NavLink
                      className={this.getClassNames}
                      to="/cadastro-solicitante"
                    >
                      {" "}
                      Cadastro do contratante{" "}
                    </NavLink>
                  </div>

                  <div class="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex">
                    <NavLink className={this.getClassNames} to="/normas">
                      {" "}
                      Normas{" "}
                    </NavLink>
                  </div>

                  <div class="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex">
                    <NavLink className={this.getClassNames} to="/tecnicos">
                      {" "}
                      Técinicos{" "}
                    </NavLink>
                  </div>

                  <div class="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex">
                    <NavLink className={this.getClassNames} to="/galeria">
                      {" "}
                      Galeria{" "}
                    </NavLink>
                  </div>

                  <div class="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex">
                    <NavLink className={this.getClassNames} to="/textos">
                      {" "}
                      Textos{" "}
                    </NavLink>
                  </div>
                </div>
              </div>
              <div class="btn-func flex items-center space-x-4">
                <button
                  onClick={() => {
                    document.cookie.split(";").forEach((cookie) => {
                      document.cookie = cookie
                        .replace(/^ +/, "") // Remover espaços extras
                        .replace(
                          /=.*/,
                          "=;expires=" + new Date(0).toUTCString() + ";path=/"
                        );
                    });

                    // Remover todos os itens do localStorage
                    localStorage.clear();
                    window.location = "/";
                  }}
                >
                  <FontAwesomeIcon icon={faSignOut} />
                </button>
              </div>
            </div>
          </div>
        </nav>
      </>
    );
  }
}

export default Header;

import React from "react";

// Componente para cada linha da tabela
const TableRow = ({ descricao, valor, percentual, cor, font }) => (
  <tr style={{ border: "1px solid #000" }}>
    <td
      style={{
        backgroundColor: cor,
        color: font || "#000",
        borderRight: "1px solid #000",
      }}
    >
      {descricao}
    </td>
    <td style={{ borderRight: "1px solid #000" }}>{valor}</td>
    <td style={{ borderRight: "1px solid #000" }}>{percentual.toFixed(2)}%</td>
  </tr>
);

// Componente da tabela principal
const TabelaRisco = ({ data }) => {
  // Definindo os dados completos, com as cores para cada risco
  const tabelaData = [
    { descricao: "ACEITÁVEL", cor: "green" },
    { descricao: "BAIXO", cor: "lightgreen" },
    { descricao: "MODERADO", cor: "yellow" },
    { descricao: "SIGNIFICATIVO", cor: "gold" },
    { descricao: "ALTO", cor: "orange" },
    { descricao: "MUITO ALTO", cor: "red" },
    { descricao: "EXTREMO", cor: "#000", font: "#fff" },
  ];

  // Função para contar a ocorrência de cada descrição em `data`
  const countOccurrences = (description) =>
    data.filter((item) => item.toUpperCase() === description).length;

  // Total de itens para calcular o percentual
  const totalItems = data.length;

  // Ajustando os valores e calculando o percentual automaticamente
  const adjustedData = tabelaData.map((item) => {
    const valor = countOccurrences(item.descricao.toUpperCase());
    const percentual = totalItems > 0 ? (valor / totalItems) * 100 : 0;
    return { ...item, valor, percentual };
  });

  return (
    <table style={{ width: "100%", border: "1px solid #000" }}>
      <thead style={{ backgroundColor: "#d9d9d9", color: "#000" }}>
        <tr>
          <th style={{ border: "1px solid #000" }}>Descrição</th>
          <th style={{ border: "1px solid #000" }}>Valor Nominal</th>
          <th>Percentil %</th>
        </tr>
      </thead>
      <tbody>
        {adjustedData.map((item, index) => (
          <TableRow
            key={index}
            descricao={item.descricao}
            valor={item.valor}
            percentual={item.percentual}
            cor={item.cor}
            font={item.font}
          />
        ))}
      </tbody>
    </table>
  );
};

export default TabelaRisco;

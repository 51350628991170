import React, { Component } from "react";
import "./iv.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPlus, faSave } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { apiUrl } from "../../config";
import { json } from "react-router-dom";
import { StaticDialog } from "react-st-modal";
class ImageView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      img: undefined,
      modal: false,
      imgs: [],
      slc: "",
    };
  }
  componentDidMount() {
    this.setState({ data: this.props.data });
  }

  AbrirGaleria() {
    const id = toast.loading("Abrindo galeria");
    fetch(`${apiUrl}/galery/list`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.status == 200) {
        response.json().then((imgs) => {
          toast.update(id, {
            render: "Sucesso!",
            type: "success",
            isLoading: false,
            autoClose: 3000,
          });
          this.setState({
            imgs,
            modal: true,
          });
        });
      }
    });
  }

  Preview(img) {
    return (
      <div class="preview" code={this.props.code}>
        <div class="edit">
          {this.props.galery == "true" ? (
            <>
              <div
                class="open-glr"
                onClick={() => {
                  this.AbrirGaleria();
                }}
              ></div>
            </>
          ) : (
            <>
              <input
                type="file"
                onChange={(event) => {
                  this.handleChange(event, 2);
                }}
              />
            </>
          )}

          <FontAwesomeIcon icon={faPen} color="#fff" />
        </div>
        <img code={this.props.code} src={`${apiUrl}/img/${img}`} />
      </div>
    );
  }

  handleChange(event, type) {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;
      if (!fileType.startsWith("image/")) {
        toast.warn("Por favor, selecione uma imagem válida!");
        event.target.value = null;
      } else {
        const formData = new FormData();
        formData.append("imagem", file);
        formData.append("ref", window.location.pathname.split("/")[3]);
        formData.append("code", this.props.code);
        formData.append("type", type);

        fetch(`${apiUrl}/ImageView`, {
          method: "POST",
          body: formData,
        }).then((response) => {
          if (response.status == 200) {
            response.json().then((data) => {
              toast.success(type == 1 ? "Imagem salva" : "Imagem atualizada");
              if (type == 1) {
                this.setState({ img: data.img });
              } else {
                this.setState((prevState) => {
                  const updatedData = prevState.data.map((item) => {
                    if (item.code === this.props.code) {
                      return { ...item, img: data.img };
                    }
                    return item;
                  });
                  return { data: updatedData };
                });
              }
            });
          }
        });
      }
    }
  }

  render() {
    const { code } = this.props;
    const matchedItem = this.state.data.find((item) => item.code === code);

    if (window.location.pathname.split("/")[1] == "preview") {
      return (
        <>
          {matchedItem != undefined ? (
            <>
              <img
                code={this.props.code}
                src={`${apiUrl}/img/${matchedItem.img}`}
                alt="Imagem não carregada!"
              />
            </>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "solid 1px",
                width: 110,
                height: 110,
              }}
            >
              Sem imagem!
            </div>
          )}
        </>
      );
    }

    if (window.location.pathname.split("/")[1] == "previewLau") {
      return (
        <>
          {matchedItem != undefined ? (
            <>
              <img
                width={this.props.width != undefined ? this.props.width : 110}
                height={
                  this.props.height != undefined ? this.props.height : 110
                }
                code={this.props.code}
                src={`${apiUrl}/img/${matchedItem.img}`}
                alt="Imagem não carregada!"
              />
            </>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "solid 1px",
                width: 110,
                height: 110,
              }}
            >
              Sem imagem!
            </div>
          )}
        </>
      );
    }

    if (this.props.type == "v") {
      return (
        <>
          {matchedItem != undefined && (
            <>
              <img
                code={this.props.code}
                src={`${apiUrl}/img/${matchedItem.img}`}
                alt="Imagem não carregada!"
              />
            </>
          )}
        </>
      );
    }

    return (
      <>
        <div class="area-8" code={this.props.code}>
          {matchedItem ? (
            this.Preview(matchedItem.img)
          ) : (
            <>
              {this.state.img == undefined ? (
                <>
                  <div class="add" code={this.props.code}>
                    {this.props.galery == "true" ? (
                      <>
                        <div
                          class="open-glr"
                          onClick={() => {
                            this.AbrirGaleria();
                          }}
                        ></div>
                      </>
                    ) : (
                      <>
                        <input
                          type="file"
                          onChange={(event) => {
                            this.handleChange(event, 1);
                          }}
                        />
                      </>
                    )}

                    <FontAwesomeIcon icon={faPlus} color="#282828" />
                  </div>
                </>
              ) : (
                this.Preview(this.state.img)
              )}
            </>
          )}
        </div>

        <StaticDialog
          isOpen={this.state.modal}
          title="Galeria"
          onAfterClose={(result) => {
            this.setState({ modal: false, slc: "" });
          }}
        >
          <div style={{ padding: 10 }}>
            <div class="area-galeria">
              {this.state.data.length > 0 ? (
                <>
                  {this.state.imgs.map((item) => (
                    <div
                      class={this.state.slc == item.id ? "image slc" : "image"}
                      onClick={() => {
                        this.setState({ slc: item.id });
                      }}
                    >
                      <img src={`${apiUrl}/img/${item.img}`} />
                    </div>
                  ))}
                </>
              ) : (
                <>Não há imagens</>
              )}
            </div>
          </div>
          <div class="d-flex justify-content-end" style={{ margin: 10 }}>
            <button
              class="btn btn-primary"
              onClick={() => {
                const slc = this.state.imgs.find(
                  (i) => i.id === this.state.slc
                );

                this.setState({ img: `${slc.img}` });
              }}
              disabled={this.state.slc == "" ? true : false}
            >
              <FontAwesomeIcon
                style={{ marginRight: 10 }}
                icon={faSave}
                color="#fff"
              />
              Salvar
            </button>
          </div>
        </StaticDialog>
      </>
    );
  }
}

export default ImageView;

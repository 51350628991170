import React, { Component } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEye,
  faUniversity,
  faPen,
  faArrowLeft,
  faSave,
  faAngleLeft,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { apiUrl } from "../../config";
import p404 from "../../images/undraw_text_files_au1q.png";

class EditExec extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loader: true,
      p404: false,
      cadastro_executante: {
        razao_social: "",
        cnpj: "",
        endereco: "",
        estado: "",
        cep: "",
        telefone: "",
        email: "",
        site: "",
      },
    };
  }

  componentDidMount() {
    document.title = "Editar executante";
    var url = window.location.href;
    const match = url.match(/edit\/(.*)$/);
    fetch(
      `${apiUrl}/${window.location.pathname.split("/")[1]}/dados/${match[1]}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Cookies.get("auth_token"),
        },
      }
    ).then((response) => {
      if (response.status == 200) {
        response.json().then((data) => {
          this.setState({ cadastro_executante: data, Loader: false });
        });
      }
      if (response.status == 404) {
        this.setState({ Loader: false, p404: true });
      }
    });
  }

  handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const validImageTypes = ["image/jpeg", "image/png", "image/webp"];
      if (!validImageTypes.includes(file.type)) {
        toast.warn("Por favor, selecione uma imagem válida!");
        return;
      }

      // Cria o FormData para envio
      const formData = new FormData();
      formData.append("image", file);

      fetch(
        `${apiUrl}/executante/${window.location.pathname.split("/")[3]}/upload`,
        {
          method: "POST",
          body: formData,
        }
      ).then((response) => {
        if (response.status == 200) {
          response.json().then((data) => {
            this.setState({
              cadastro_executante: {
                ...this.state.cadastro_executante,
                logo: data.outputFileName,
              },
            });
          });
          toast.success("Imagem enviada com sucesso!");
        }
      });
    }
  };
  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      cadastro_executante: {
        ...prevState.cadastro_executante,
        [name]: value,
      },
    }));
  };

  render() {
    var cadastro_executante = this.state.cadastro_executante;
    if (this.state.Loader == true) {
      return (
        <>
          <div className="loader-container">
            <div class="loader"></div>
          </div>
        </>
      );
    }
    if (this.state.p404 == true) {
      return (
        <>
          <div class="p404">
            <img src={p404} />
            <p>Desculpe, Esse documento não foi encontrado.</p>
            <button
              type="submit"
              class="btn btn-primary float-left ml-2"
              onClick={() => {
                this.props.navigate(
                  `/${window.location.pathname.split("/")[1]}`
                );
              }}
            >
              {" "}
              <FontAwesomeIcon color="#fff" icon={faArrowLeft} /> Início
            </button>
          </div>
        </>
      );
    }
    return (
      <>
        <header class="bg-white shadow">
          <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <button
              class="font-semibold text-xl text-gray-800 leading-tight"
              onClick={() => {
                this.props.navigate(-1);
              }}
            >
              <FontAwesomeIcon icon={faAngleLeft} /> Editar executante{" "}
            </button>
          </div>
        </header>

        <main>
          <div className="py-12">
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h3>Editar executante:</h3>
                    </div>
                    <div className="card-body">
                      <div className="card-body">
                        <table class="table table-bordered">
                          <thead>
                            <tr>
                              <th scope="col">TITULO</th>
                              <th scope="col">REFERENCIA</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>RAZÃO SOCIAL:</td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Digite a razão social"
                                  value={cadastro_executante.razao_social}
                                  name="razao_social"
                                  onChange={this.handleInputChange}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>CNPJ:</td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Digite o cnpj"
                                  value={cadastro_executante.cnpj}
                                  name="cnpj"
                                  onChange={this.handleInputChange}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>LOGO:</td>
                              <td>
                                <div className="d-flex align-items-center area-logo">
                                  <img
                                    src={
                                      apiUrl +
                                      "/img/" +
                                      cadastro_executante.logo
                                    }
                                    style={{ width: 180, height: 65 }}
                                    alt="logo"
                                  />
                                  <div
                                    className="btn"
                                    style={{ marginLeft: "10px" }}
                                  >
                                    <input
                                      id="upload"
                                      type="file"
                                      accept="image/*"
                                      onChange={this.handleFileChange}
                                    />
                                    <button
                                      for="upload"
                                      className="btn btn-primary"
                                    >
                                      <FontAwesomeIcon
                                        color="#fff"
                                        icon={faUpload}
                                      />{" "}
                                      Upload
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>ENDEREÇO:</td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Digite o endereço"
                                  value={cadastro_executante.endereco}
                                  name="endereco"
                                  onChange={this.handleInputChange}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>ESTADO:</td>
                              <td>
                                <select
                                  className="form-control"
                                  name="estado"
                                  onChange={this.handleInputChange}
                                  value={cadastro_executante.estado}
                                >
                                  <option value="">Selecione o estado</option>
                                  {[
                                    "AC",
                                    "AL",
                                    "AP",
                                    "AM",
                                    "BA",
                                    "CE",
                                    "DF",
                                    "ES",
                                    "GO",
                                    "MA",
                                    "MT",
                                    "MS",
                                    "MG",
                                    "PA",
                                    "PB",
                                    "PR",
                                    "PE",
                                    "PI",
                                    "RJ",
                                    "RN",
                                    "RS",
                                    "RO",
                                    "RR",
                                    "SC",
                                    "SP",
                                    "SE",
                                    "TO",
                                  ].map((estado) => (
                                    <option key={estado} value={estado}>
                                      {estado}
                                    </option>
                                  ))}
                                </select>
                              </td>
                            </tr>

                            <tr>
                              <td>CEP:</td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={cadastro_executante.cep}
                                  placeholder="Digite o cep"
                                  name="cep"
                                  onChange={this.handleInputChange}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>TELEFONE:</td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={cadastro_executante.telefone}
                                  placeholder="Digite o telefone"
                                  name="telefone"
                                  onChange={this.handleInputChange}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>E-MAIL:</td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={cadastro_executante.email}
                                  placeholder="Digite o email"
                                  name="email"
                                  onChange={this.handleInputChange}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="card-footer">
                      <button
                        type="submit"
                        class="btn btn-danger float-left ml-2"
                        onClick={() => {
                          this.props.navigate(-1);
                        }}
                      >
                        {" "}
                        <FontAwesomeIcon color="#fff" icon={faArrowLeft} />{" "}
                        Voltar
                      </button>

                      <button
                        type="submit"
                        className="btn btn-primary float-right ml-2"
                        onClick={() => {
                          var url = window.location.href;
                          const match = url.match(/edit\/(.*)$/);

                          fetch(
                            `${apiUrl}/cadastro-exetante/${
                              window.location.pathname.split("/")[3]
                            }/atualizar/`,
                            {
                              method: "POST",
                              credentials: "include",
                              headers: {
                                "Content-Type": "application/json",
                                Authorization:
                                  "Bearer " + Cookies.get("auth_token"),
                              },
                              body: JSON.stringify({ cadastro_executante }),
                            }
                          ).then((response) => {
                            if (response.status == 200) {
                              response.json().then((data) => {
                                this.props.navigate(
                                  `/cadastro-executante/preview/${data.token}`
                                );
                              });
                            }
                          });
                        }}
                      >
                        <FontAwesomeIcon color="#fff" icon={faSave} /> Salvar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <ToastContainer />
      </>
    );
  }
}

export default EditExec;

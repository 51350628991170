import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { apiUrl } from "../../config";
import Modal from "../../Comps/Modal";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import p404 from "../../images/undraw_text_files_au1q.png";
import {
  faTrash,
  faEye,
  faUniversity,
  faPen,
  faUpload,
  faPlus,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
class LaudoTecnico extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loader: true,
      data: [],
      Ptxt: "",
      Tpsq: "Nome",
      search: [],
      busca: false,
      inputFocused: false,
    };
  }

  componentDidMount() {
    document.title = "laudo técnico";
    fetch(`${apiUrl}/laudos-tecnicos/list`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("auth_token"),
      },
    }).then((response) => {
      if (response.status == 200) {
        response.json().then((data) => {
          this.setState({ data, Loader: false });
        });
      }
    });
  }

  _Busca = () => {
    fetch(`${apiUrl}/busca-usr-laudos/`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("auth_token"),
      },
      body: JSON.stringify({
        Tpsq: this.state.Tpsq,
        Ptxt: this.state.Ptxt,
      }),
    }).then((response) => {
      if (response.status == 200) {
        response.json().then((search) => {
          this.setState({ busca: true, search });
        });
      }
    });
  };

  render() {
    if (this.state.Loader == true) {
      return (
        <>
          <div className="loader-container">
            <div class="loader"></div>
          </div>
        </>
      );
    }
    return (
      <>
        <header class="bg-white shadow">
          <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">
              Laudo técnico
            </h2>
          </div>
        </header>
        <main>
          <div class="py-1">
            <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div class="col-12">
                <div class="row">
                  <div class="py-12">
                    <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                      <div class="col-12">
                        <div class="card">
                          <div class="card-header d-flex justify-content-between">
                            <div className="area-busca">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Digite:"
                                onKeyUp={(e) => {
                                  if (e.keyCode == 13) {
                                    this._Busca();
                                  }
                                  if (e.target.value.length == 0) {
                                    this.setState({ busca: false, search: [] });
                                  }
                                }}
                                onChange={(e) => {
                                  this.setState({ Ptxt: e.target.value });
                                }}
                                name="nome"
                              ></input>
                              <button
                                class="btn btn-xs btn-primary"
                                onClick={() => {
                                  this._Busca();
                                }}
                              >
                                <FontAwesomeIcon icon={faSearch} />
                              </button>
                            </div>
                          </div>

                          <div class="card-body">
                            <div class="row">
                              <div class="col-12">
                                <div></div>
                                {this.state.busca == true ? (
                                  <>
                                    {this.state.search.length > 0 ? (
                                      <>
                                        {this.state.search.length > 0 ? (
                                          <>
                                            <table class="table ntbl table-bordered">
                                              <thead>
                                                <tr>
                                                  <th scope="col">Código</th>
                                                  <th scope="col">
                                                    Razão social
                                                  </th>
                                                  <th scope="col">cnpj</th>
                                                  <th scope="col">documento</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {this.state.search.map(
                                                  (item, index) => (
                                                    <>
                                                      <tr>
                                                        <td>{item.token}</td>
                                                        <td>
                                                          <Link
                                                            to={`/laudo-tecnico/${item.token}/list`}
                                                          >
                                                            {item.token}
                                                          </Link>
                                                        </td>
                                                        <td>
                                                          {item.razao_social}
                                                        </td>
                                                        <td>{item.cnpj}</td>
                                                      </tr>
                                                    </>
                                                  )
                                                )}
                                              </tbody>
                                            </table>
                                          </>
                                        ) : (
                                          <>Não há dados cadastrados!</>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <div class="se-re">
                                          <img src={p404} />
                                          <p>
                                            Desculpe, Esse documento não foi
                                            encontrado.
                                          </p>
                                        </div>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {this.state.data.length > 0 ? (
                                      <>
                                        <table class="table ntbl table-bordered">
                                          <thead>
                                            <tr>
                                              <th scope="col">Código</th>
                                              <th scope="col">Razão social</th>
                                              <th scope="col">Cnpj</th>
                                              <th scope="col">Documentos</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {this.state.data.map(
                                              (item, index) => (
                                                <>
                                                  <tr>
                                                    <td>{item.token}</td>
                                                    <td>
                                                      <Link
                                                        to={`/laudo-tecnico/${item.token}/list`}
                                                      >
                                                        {item.razao_social}
                                                      </Link>
                                                    </td>
                                                    <td>{item.cnpj}</td>
                                                    <td>{item.laudos}</td>
                                                  </tr>
                                                </>
                                              )
                                            )}
                                          </tbody>
                                        </table>
                                      </>
                                    ) : (
                                      <>
                                        <div class="se-re">
                                          <img src={p404} />
                                          <p>
                                            Desculpe, Esse documento não foi
                                            encontrado.
                                          </p>
                                        </div>
                                      </>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <div id="modal-root"></div>
      </>
    );
  }
}

export default LaudoTecnico;

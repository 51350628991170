import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faDownload,
  faPen,
  faArrowLeft,
  faAngleLeft,
} from "@fortawesome/free-solid-svg-icons";
import { apiUrl } from "../../../config";
import InputMask from "react-input-mask";
import p404 from "../../../images/undraw_text_files_au1q.png";
class EditTec extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loader: true,
      error: false,
      tecnicos: [],
    };
  }
  handleFileChange = (event) => {
    this.setState({ selectedFile: event.target.files[0] });
  };

  componentDidMount() {
    document.title = "Editar técnico";
    fetch(`${apiUrl}/tecnicos/${window.location.pathname.split("/")[3]}/view`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("auth_token"),
      },
    }).then((response) => {
      if (response.status == 200) {
        response.json().then((data) => {
          this.setState({ tecnicos: data, Loader: false });
        });
      }
      if (response.status == 404) {
        this.setState({ error: true, Loader: false });
      }
    });
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      tecnicos: {
        ...prevState.tecnicos,
        [name]: value,
      },
    }));
  };

  render() {
    const { tecnicos } = this.state;
    if (this.state.Loader == true) {
      return (
        <>
          <div className="loader-container">
            <div class="loader"></div>
          </div>
        </>
      );
    }
    if (this.state.error == true) {
      return (
        <>
          <div class="p404">
            <img src={p404} />
            <p>Desculpe, Esse documento não foi encontrado.</p>
            <button
              type="submit"
              class="btn btn-primary float-left ml-2"
              onClick={() => {
                this.props.navigate(
                  `/${window.location.pathname.split("/")[1]}`
                );
              }}
            >
              {" "}
              <FontAwesomeIcon color="#fff" icon={faArrowLeft} /> Início
            </button>
          </div>
        </>
      );
    }
    return (
      <>
        <header class="bg-white shadow">
          <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <button
              class="font-semibold text-xl text-gray-800 leading-tight"
              onClick={() => {
                this.props.navigate(-1);
              }}
            >
              <FontAwesomeIcon icon={faAngleLeft} /> {document.title}
            </button>
          </div>
        </header>

        <main>
          <div class="py-12">
            <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div class="col-12">
                <div class="row">
                  <div class="py-12">
                    <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                      <div class="col-12">
                        <div class="card">
                          <div class="card-header d-flex justify-content-between">
                            <h4>DADOS DO TÉCNICO</h4>
                          </div>

                          <div class="card-body">
                            <table class="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">Título</th>
                                  <th scope="col">Descrição</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Digite o nome:"
                                      name="nome"
                                      value={tecnicos.nome}
                                      onChange={this.handleChange}
                                    />
                                  </td>

                                  <td>
                                    <textarea
                                      type="text"
                                      className="form-control"
                                      placeholder="Digite a função:"
                                      name="func"
                                      value={tecnicos.func}
                                      onChange={this.handleChange}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-center">Usuário:</td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Digite:"
                                      name="user"
                                      value={tecnicos.user}
                                      onChange={this.handleChange}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-center">Senha:</td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Digite:"
                                      name="pass"
                                      value={tecnicos.pass}
                                      onChange={this.handleChange}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div class="card-footer">
                            <button
                              type="button"
                              class="btn btn-primary float-right ml-2"
                              onClick={(e) => {
                                e.target.classList.add("load");

                                fetch(
                                  `${apiUrl}/tecnicos/atualizar/${
                                    window.location.pathname.split("/")[3]
                                  }`,
                                  {
                                    method: "POST",
                                    credentials: "include",
                                    headers: {
                                      "Content-Type": "application/json",
                                      Authorization:
                                        "Bearer " + Cookies.get("auth_token"),
                                    },
                                    body: JSON.stringify({ tecnicos }),
                                  }
                                )
                                  .then((response) => {
                                    e.target.classList.remove("load");
                                    if (response.status == 200) {
                                      response.json().then((data) => {
                                        toast.success(
                                          "Tecnico atualizado com sucesso!"
                                        );

                                        this.props.navigate("/tecnicos");
                                      });
                                    }
                                    if (response.status == 422) {
                                      response.json().then((data) => {
                                        toast.error("Erro ao atualizar!");
                                      });
                                    }
                                  })
                                  .catch((err) => {
                                    e.target.classList.remove("load");
                                    toast.error("Erro ao atualizar!");
                                  });
                              }}
                            >
                              {" "}
                              <FontAwesomeIcon
                                color="#fff"
                                icon={faSave}
                              />{" "}
                              Atualizar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default EditTec;

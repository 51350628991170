import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

class SituacaoGrafico extends Component {
  render() {
    const { cf, na, nc } = this.props.values;

    const data = {
      labels: ["Conforme", "Não aplicável", "Não conforme"],
      datasets: [
        {
          data: [cf, na, nc],
          backgroundColor: ["#00FF00", "#000", "#FF0000"],
          borderColor: "black",
          borderWidth: 0,
        },
      ],
    };

    const options = {
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: "top",
        },
        title: {
          display: false,
          text: "Situação",
          font: {
            size: 20,
          },
        },
      },
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };

    return (
      <div
        style={{
          width: "500px",
          height: "300px",
          border: "1px solid #ccc",
          padding: "10px",
        }}
      >
        <div className="text-center">
          <strong>Situação</strong>
        </div>
        <Bar data={data} options={options} />
      </div>
    );
  }
}

export default SituacaoGrafico;

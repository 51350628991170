import React, { Component } from "react";
import { apiUrl } from "../../config";
import { StaticDialog } from "react-st-modal";
import { toast } from "react-toastify";
import { faTrash, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Anexos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
      error: null,
      show: {},
      file: null,
      trash: {},
      matchedItem: [],
    };
  }

  componentDidMount() {
    const matchedItem = this.props.data.hasOwnProperty(this.props.code)
      ? this.props.data[this.props.code]
      : [];

    if (matchedItem.length > 0) {
      this.setState({
        matchedItem,
        loader: false,
      });
    } else {
      this.setState({ loader: false, matchedItem: [] });
    }
  }

  handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Verifica se o arquivo é um PDF
      if (file.type !== "application/pdf") {
        toast.warn("Por favor, selecione um arquivo PDF");
      } else {
        this.setState({ file, error: null });
      }
    }
  };

  handleUpload = (e) => {
    const { file, matchedItem } = this.state;
    if (!file) {
      toast.warn("Por favor, selecione um arquivo PDF");
      return;
    }

    var formData = new FormData();
    formData.append("pdf", file);
    formData.append("code", this.props.code);
    formData.append("ref", window.location.pathname.split("/")[3]);
    e.target.classList.add("load");

    fetch(`${apiUrl}/anexos/`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        e.target.classList.remove("load");
        this.setState((prevState) => ({
          matchedItem: [...prevState.matchedItem, ...data], // Mantém os antigos e adiciona os novos
          file: null,
          loading: false,
        }));
      })
      .catch((error) => {
        this.setState({
          error: "Erro ao processar o arquivo",
          loading: false,
        });
        console.error(error);
      });
  };

  render() {
    return (
      <div className="col-12 mt-3">
        <div className="card">
          <div className="card-header d-flex justify-content-between">
            <h3>{this.props.title}</h3>
          </div>

          <div className="card-body">
            {this.state.matchedItem.length > 0 ? (
              <>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Arquivo</th>
                      <th>Ação</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.matchedItem.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <div
                            onClick={() => {
                              this.setState({ show: item });
                            }}
                          >
                            <img
                              src={`${apiUrl}/img/${item.file}`}
                              style={{
                                width: 150,
                                height: 150,
                                borderRadius: 5,
                                border: "1px solid #ccc",
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        </td>
                        <td className="text-center">
                          <button
                            className="btn btn-danger"
                            onClick={() => {
                              this.setState({ trash: item });
                            }}
                          >
                            <FontAwesomeIcon icon={faTrash} /> Excluir
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div>
                  {" "}
                  <div className="form-group">
                    <input
                      id="acs"
                      type="file"
                      className="form-control"
                      accept="application/pdf"
                      onChange={this.handleFileChange}
                    />
                  </div>
                  <div className="text-right mt-3">
                    {" "}
                    <button
                      className="btn btn-primary"
                      onClick={(e) => {
                        this.handleUpload(e);
                      }}
                    >
                      <FontAwesomeIcon icon={faUpload} /> Upload
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="form-group">
                  <input
                    type="file"
                    className="form-control"
                    accept="application/pdf"
                    onChange={this.handleFileChange}
                  />
                </div>
                <div className="text-right mt-3">
                  {" "}
                  <button
                    className="btn btn-primary"
                    onClick={(e) => {
                      this.handleUpload(e);
                    }}
                  >
                    <FontAwesomeIcon icon={faUpload} /> Upload
                  </button>
                </div>
              </>
            )}
          </div>
        </div>

        <StaticDialog
          isOpen={Object.keys(this.state.trash).length > 0}
          title="Tem certeza que deseja excluir?"
          onAfterClose={(result) => {
            this.setState({ trash: {} });
          }}
        >
          <div style={{ margin: 10 }}>
            Ao excluir, o arquivo será removido permanentemente.
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-danger"
              onClick={(e) => {
                e.target.classList.add("load");
                fetch(`${apiUrl}/anexos/delete/${this.state.trash.id}`, {
                  method: "DELETE",
                })
                  .then((response) => response.json())
                  .then((data) => {
                    e.target.classList.remove("load");
                    this.setState((prevState) => ({
                      matchedItem: prevState.matchedItem.filter(
                        (item) => item.id !== this.state.trash.id
                      ),
                      trash: {},
                    }));
                  })
                  .catch((error) => {
                    console.error(error);
                  });
              }}
            >
              <FontAwesomeIcon icon={faTrash} /> Remover
            </button>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={Object.keys(this.state.show).length > 0}
          title="Visualizar Imagem"
          onAfterClose={(result) => {
            this.setState({ show: {} });
          }}
        >
          <img src={`${apiUrl}/img/${this.state.show.file}`} />
        </StaticDialog>
      </div>
    );
  }
}

export default Anexos;

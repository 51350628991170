import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faDownload,
  faPen,
  faArrowLeft,
  faPrint,
  faAngleLeft,
  faImage,
  faUpload,
  faPlus,
  faClose,
  faFilePdf,
  faFileWord,
  faClock,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import error from "../../images/error.svg";
import { apiUrl } from "../../config";
import { Confirm, StaticDialog } from "react-st-modal";
class GerFilesLaudos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loader: true,
      modal: false,
      titulo: "",
      itens: [],
      selectedFile: null,
      imgUrl: "",
      trash: {},
    };
  }

  componentDidMount() {
    document.title = "Arquivos";
    fetch(
      `${apiUrl}/laudo-tecnico/files/${
        window.location.pathname.split("/")[3]
      }/list`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Cookies.get("auth_token"),
        },
      }
    ).then((response) => {
      if (response.status == 200) {
        response.json().then((data) => {
          this.setState({
            data,
            itens: data.data,
            Loader: false,
          });
        });
      }
      if (response.status == 404) {
        this.setState({ p404: true, Loader: false });
      }
    });
  }

  formatDateToBrazilian = (isoDate) => {
    const date = new Date(isoDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Meses começam em 0
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };

  render() {
    if (this.state.Loader == true) {
      return (
        <>
          <div className="loader-container">
            <div class="loader"></div>
          </div>
        </>
      );
    }
    if (this.state.p404 == true) {
      return (
        <>
          <div class="p404">
            <img src={error} />
            <p>Desculpe, Esse documento não foi encontrado.</p>
            <button
              type="submit"
              class="btn btn-primary float-left ml-2"
              onClick={() => {
                this.props.navigate(-1);
              }}
            >
              {" "}
              <FontAwesomeIcon color="#fff" icon={faArrowLeft} /> Início
            </button>
          </div>
        </>
      );
    }

    return (
      <>
        <header class="bg-white shadow">
          <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <button
              class="font-semibold text-xl text-gray-800 leading-tight"
              onClick={() => {
                this.props.navigate(-1);
              }}
            >
              <FontAwesomeIcon icon={faAngleLeft} /> Arquivos
            </button>
          </div>
        </header>

        <main>
          <div className="py-12">
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div class="card-header d-flex justify-content-between">
                    <h3>Lista</h3>

                    <div>
                      <button
                        className="btn btn-primary"
                        style={{ marginRight: 10 }}
                        onClick={(e) => {
                          e.target.classList.add("load");

                          fetch(`${apiUrl}/laudo-tecnico/GeneratePdf`, {
                            method: "POST",
                            headers: {
                              "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                              token: window.location.pathname.split("/")[3],
                            }),
                          })
                            .then((response) => {
                              e.target.classList.remove("load");
                              if (response.status == 401) {
                                toast.warn("Já existe um pdf sendo gerado!");
                                return;
                              }

                              toast.success(
                                "O aquivo está sendo gerado, aguarde..."
                              );
                            })
                            .catch((error) => {
                              e.target.classList.remove("load");
                              toast.error("Ocorreu um erro ao gerar o PDF.");
                            });
                        }}
                        disabled={this.state.data.inProcess}
                      >
                        <FontAwesomeIcon icon={faFilePdf} color="#fff" /> Gerar
                        PDF
                      </button>
                      <a
                        href={`${apiUrl}/laudo-tecnico/GenerateDocx/${
                          window.location.pathname.split("/")[3]
                        }`}
                        target="_blank"
                      >
                        <button className="btn btn-primary" onClick={() => {}}>
                          <FontAwesomeIcon icon={faFileWord} color="#fff" />{" "}
                          Gerar .DOCX
                        </button>
                      </a>
                    </div>
                  </div>

                  <div class="card-body">
                    <div className="">
                      {this.state.itens.length > 0 ? (
                        <>
                          <table className="table table-sm ntbl table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">Arquivo</th>
                                <th scope="col">Status</th>
                                <th scope="col">Data de Criação</th>
                                <th scope="col">Última Atualização</th>
                                <th scope="col">Ação</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.itens.map((item) => (
                                <>
                                  <tr>
                                    <td>
                                      {item.status == "finish" ? (
                                        <>
                                          {" "}
                                          <a
                                            href={`${apiUrl}/laudo-tecnico/file/${item.file}`}
                                          >
                                            {item.file}
                                          </a>
                                        </>
                                      ) : (
                                        <> {item.file}</>
                                      )}
                                    </td>
                                    <td>{item.status}</td>
                                    <td>
                                      {this.formatDateToBrazilian(
                                        item.created_at
                                      )}
                                    </td>
                                    <td>
                                      {item.status == "finish" ? (
                                        <>
                                          {" "}
                                          {this.formatDateToBrazilian(
                                            item.updated_at
                                          )}
                                        </>
                                      ) : (
                                        <>---</>
                                      )}
                                    </td>
                                    <td>
                                      <button
                                        className="btn btn-danger"
                                        onClick={(e) => {
                                          this.setState({
                                            trash: item,
                                          });
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faTrash}
                                          color="#fff"
                                        />
                                      </button>
                                    </td>
                                  </tr>
                                </>
                              ))}
                            </tbody>
                          </table>
                        </>
                      ) : (
                        <>
                          <div>Nenhum arquivo encontrado</div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <ToastContainer />

        <StaticDialog
          isOpen={Object.keys(this.state.trash).length > 0}
          title="Tem certeza de que deseja continuar?"
          onAfterClose={(result) => {
            this.setState({
              trash: {},
            });
          }}
        >
          <div style={{ padding: 10, fontSize: 14 }}>
            <span>
              Se você confirmar a exclusão, não será possível recuperar os dados
              posteriormente.
            </span>
          </div>
          <div class="d-flex justify-content-end" style={{ margin: 10 }}>
            <button
              class="btn btn-danger"
              onClick={(e) => {
                e.target.classList.add("load");
                var id = this.state.trash.id;
                fetch(`${apiUrl}/laudo-tecnico/files/delete/`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    id,
                  }),
                })
                  .then((response) => {
                    e.target.classList.remove("load");
                    if (response.status == 200) {
                      toast.success("Arquivo excluido com sucesso!");
                      this.setState((prevState) => ({
                        itens: prevState.itens.filter((item) => item.id !== id),
                        trash: {},
                      }));
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                    e.target.classList.remove("load");
                    toast.error("Ocorreu um erro ao excluir.");
                  });
              }}
            >
              <FontAwesomeIcon icon={faTrash} /> Remover
            </button>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={this.state.modal}
          title="Upload de imagem"
          onAfterClose={(result) => {
            this.setState({ modal: false, titulo: "", selectedFile: null });
          }}
        >
          <div style={{ padding: 10 }}>
            <input
              type="text"
              style={{
                width: "100%",
                height: 30,
              }}
              value={this.state.titulo}
              onChange={(e) => {
                this.setState({ titulo: e.target.value });
              }}
              placeholder="Titulo:"
            />

            <div class="area-8" style={{ marginBottom: -15 }} code="2111354er">
              {this.state.selectedFile == undefined ? (
                <>
                  <div class="add" code="2111354er">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        const file = e.target.files[0];

                        if (file && file.type.startsWith("image/")) {
                          this.setState({ selectedFile: file });
                          if (file) {
                            const imgUrl = URL.createObjectURL(file);
                            this.setState({ imgUrl });
                          }
                        } else {
                          toast.error(
                            "Por favor, selecione um arquivo de imagem válido."
                          );
                          return false;
                        }
                      }}
                    />
                    <FontAwesomeIcon icon={faPlus} />
                  </div>
                </>
              ) : (
                <>
                  <div className="preview">
                    <div
                      className="fx"
                      onClick={() => {
                        this.setState({ selectedFile: null });
                      }}
                    >
                      <FontAwesomeIcon icon={faClose} color="#fff" />
                    </div>
                    <img src={this.state.imgUrl} />
                  </div>
                </>
              )}
            </div>
          </div>

          <div class="d-flex justify-content-end" style={{ margin: 10 }}>
            <button
              class="btn btn-primary"
              onClick={() => {
                const id = toast.loading("Enviando imagem");

                const formData = new FormData();

                formData.append("imagem", this.state.selectedFile);
                formData.append("title", this.state.titulo);
                fetch(`${apiUrl}/galery/add`, {
                  method: "POST",
                  body: formData,
                })
                  .then((response) => {
                    if (response.status == 200) {
                      response.json().then((data) => {
                        const updatedData = [
                          { img: data.img, id: data.id },
                          ...this.state.data,
                        ];

                        this.setState({
                          data: updatedData,
                          modal: false,
                          titulo: "",
                          selectedFile: null,
                        });

                        toast.update(id, {
                          render: "Imagem salva",
                          type: "success",
                          isLoading: false,
                          autoClose: 3000,
                        });
                      });
                    }
                  })
                  .catch((data) => {
                    toast.update(id, {
                      render: "Erro no processamento!",
                      type: "error",
                      isLoading: false,
                      autoClose: 3000,
                    });
                  });
              }}
              disabled={this.state.selectedFile == null ? true : false}
            >
              <FontAwesomeIcon
                style={{ marginRight: 10 }}
                icon={faSave}
                color="#fff"
              />
              Salvar
            </button>
          </div>
        </StaticDialog>
      </>
    );
  }
}

export default GerFilesLaudos;

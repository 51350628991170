import React from "react";

const MAX_PAGE_HEIGHT = 1122;

function checkIfContentExceeds(content, pageHeight) {
  return content.length * 50 > pageHeight;
}

const PDFPagesV2 = ({ combinedPages }) => {
  const pages = [];
  let currentPage = [];
  let currentHeight = 0;

  combinedPages.forEach((content) => {
    const isFixed = content.props?.["data-fixed"] === "true";

    if (isFixed) {
      // Se houver uma página atual, adicionamos antes de incluir a fixa
      if (currentPage.length > 0) {
        pages.push(currentPage);
        currentPage = [];
        currentHeight = 0;
      }
      pages.push([content]); // Página única fixa
    } else {
      if (checkIfContentExceeds(currentPage.concat(content), MAX_PAGE_HEIGHT)) {
        pages.push(currentPage);
        currentPage = [content];
        currentHeight = 50; // Consideração aproximada de altura
      } else {
        currentPage = currentPage.concat(content);
        currentHeight += 50;
      }
    }
  });

  if (currentPage.length > 0) {
    pages.push(currentPage);
  }

  return (
    <div>
      {pages.map((pageContent, pageIndex) => (
        <div
          key={pageIndex}
          className="doc-v2"
          style={{
            pageBreakAfter: "always",
            padding: 0,
          }}
        >
          {pageContent}
        </div>
      ))}
    </div>
  );
};

export default PDFPagesV2;

import { faPlus, faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { apiUrl } from "../../config";
import { toast } from "react-toastify";
import { StaticDialog } from "react-st-modal";

class DadosCortina extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data || [],
      trash: {},
    };
  }

  handleInputChange = (index, event) => {
    const { name, value } = event.target;
    this.setState((prevState) => {
      const newData = [...prevState.data];
      newData[index] = { ...newData[index], [name]: value };
      return { data: newData };
    });
  };

  handleSave = (index, e) => {
    const item = this.state.data[index];
    e.target.classList.add("load");
    fetch(`${apiUrl}/DadosCortina/update/${item.id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fabricante: item.fabricante,
        modelo: item.modelo,
        campo_protecao: item.campo_protecao,
        cortina_luz: item.cortina_luz,
        nr12: item.nr12,
        tempo_reacao: item.tempo_reacao,
        t1: item.t1,
        t2: item.t2,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        e.target.classList.remove("load");
        toast.success("Dados atualizados com sucesso!");
      })
      .catch((error) => console.error("Erro ao salvar:", error));
  };

  render() {
    return (
      <div className="card">
        <div className="card-header">
          <h3>DADOS DA CORTINA DE LUZ UTILIZADA NO EQUIPAMENTO:</h3>
        </div>
        <div className="card-body">
          {this.state.data.length > 0 &&
            this.state.data.map((item, index) => (
              <div className="card mb-3" key={index}>
                <div className="card-header d-flex align-items-center justify-content-between">
                  <span>Cortina {index + 1}</span>
                  <button
                    className="btn btn-danger"
                    onClick={(e) => {
                      this.setState({
                        trash: item,
                      });
                    }}
                  >
                    <FontAwesomeIcon color="#fff" icon={faTrash} />
                  </button>
                </div>
                <div className="card-body">
                  <table className="table table-sm ntbl table-bordered">
                    <thead>
                      <tr>
                        <th>Título</th>
                        <th>Valor</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Fabricante</td>
                        <td>
                          <input
                            type="text"
                            name="fabricante"
                            className="form-control"
                            value={item.fabricante || ""}
                            placeholder="Digite:"
                            onChange={(e) => this.handleInputChange(index, e)}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Modelo</td>
                        <td>
                          <input
                            type="text"
                            name="modelo"
                            placeholder="Digite:"
                            className="form-control"
                            value={item.modelo || ""}
                            onChange={(e) => this.handleInputChange(index, e)}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Campo de Proteção</td>
                        <td>
                          <input
                            type="text"
                            name="campo_protecao"
                            className="form-control"
                            placeholder="Digite:"
                            value={item.campo_protecao || ""}
                            onChange={(e) => this.handleInputChange(index, e)}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Tipo de Proteção</td>
                        <td>
                          <input
                            type="text"
                            name="tipo_protecao"
                            value="IP65"
                            disabled
                            className="form-control"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Categoria de Segurança</td>
                        <td>
                          <input
                            type="text"
                            name="categoria_seguranca"
                            value="Cat.4 SIL3"
                            disabled
                            className="form-control"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Tensão de Alimentação</td>
                        <td>
                          <input
                            type="text"
                            name="tensao_alimentacao"
                            value="24V"
                            disabled
                            className="form-control"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Distância Real</td>
                        <td>
                          <input
                            type="text"
                            placeholder="300"
                            name="cortina_luz"
                            value={item.cortina_luz}
                            onChange={(e) => {
                              const value = e.target.value;
                              // Permitir apenas números (opcionalmente permitir números decimais)
                              if (/^\d*$/.test(value)) {
                                // Apenas números inteiros
                                this.setState((prevState) => {
                                  const newData = [...prevState.data];
                                  newData[index] = {
                                    ...newData[index],
                                    [e.target.name]: value,
                                  };
                                  return { data: newData };
                                });
                              }
                            }}
                            className="form-control"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>NR-12</td>
                        <td>
                          <select
                            name="nr12"
                            className="form-control"
                            value={item.nr12}
                            onChange={(e) => this.handleInputChange(index, e)}
                          >
                            <option value={10}>Selecione:</option>
                            <option value={1600}>1600</option>
                            <option value={2000}>2000</option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td>Tempo de Reação</td>
                        <td>
                          <input
                            type="text"
                            name="tempo_reacao"
                            className="form-control"
                            value={item.tempo_reacao || ""}
                            placeholder="21"
                            onChange={(e) => {
                              const value = e.target.value;
                              // Permitir apenas números (opcionalmente permitir números decimais)
                              if (/^\d*$/.test(value)) {
                                // Apenas números inteiros
                                this.setState((prevState) => {
                                  const newData = [...prevState.data];
                                  newData[index] = {
                                    ...newData[index],
                                    [e.target.name]: value,
                                  };
                                  return { data: newData };
                                });
                              }
                            }}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td>Tempo de Resposta De Parada da Máquina: T2</td>
                        <td>
                          <input
                            type="text"
                            name="t2"
                            className="form-control"
                            value={item.t2 || ""}
                            placeholder="Digite:"
                            onChange={(e) => {
                              const value = e.target.value;
                              // Permitir apenas números (opcionalmente permitir números decimais)
                              if (/^\d*$/.test(value)) {
                                // Apenas números inteiros
                                this.setState((prevState) => {
                                  const newData = [...prevState.data];
                                  newData[index] = {
                                    ...newData[index],
                                    [e.target.name]: value,
                                  };
                                  return { data: newData };
                                });
                              }
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Distância Adicional: C</td>
                        <td>
                          <input
                            type="text"
                            name="t1"
                            className="form-control"
                            value={item.t1 || ""}
                            placeholder="Digite:"
                            onChange={(e) => {
                              const value = e.target.value;
                              // Permitir apenas números (opcionalmente permitir números decimais)
                              if (/^\d*$/.test(value)) {
                                // Apenas números inteiros
                                this.setState((prevState) => {
                                  const newData = [...prevState.data];
                                  newData[index] = {
                                    ...newData[index],
                                    [e.target.name]: value,
                                  };
                                  return { data: newData };
                                });
                              }
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="card-footer d-flex justify-content-end">
                  <button
                    className="btn btn-primary"
                    onClick={(e) => this.handleSave(index, e)}
                  >
                    <FontAwesomeIcon color="#fff" icon={faSave} /> Salvar
                  </button>
                </div>
              </div>
            ))}
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-primary"
              onClick={(e) => {
                e.target.classList.add("load");
                fetch(`${apiUrl}/DadosCortina/new`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    ref: window.location.pathname.split("/")[3],
                  }),
                })
                  .then((response) => response.json())
                  .then((data) => {
                    e.target.classList.remove("load");
                    this.setState((prevState) => ({
                      data: [...prevState.data, { id: data.id }],
                    }));
                  });
              }}
            >
              <FontAwesomeIcon color="#fff" icon={faPlus} /> Adicionar
            </button>
          </div>
        </div>
        <StaticDialog
          isOpen={Object.keys(this.state.trash).length > 0}
          title="Tem certeza?"
          onAfterClose={(result) => {
            this.setState({ trash: {} });
          }}
        >
          <div style={{ margin: 10 }}>
            Uma vez deletado, não será possível recuperar
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-danger"
              onClick={(e) => {
                e.target.classList.add("load");
                fetch(`${apiUrl}/DadosCortina/delete`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({ id: this.state.trash.id }),
                })
                  .then((response) => {
                    response.json().then((data) => {
                      e.target.classList.remove("load");
                      this.setState((prevState) => ({
                        data: prevState.data.filter(
                          (item) => item.id !== this.state.trash.id
                        ),
                        trash: {},
                      }));
                    });
                  })
                  .catch((error) => {
                    e.target.classList.remove("load");
                    toast.error("Erro ao deletar imagem!");
                  });
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
              Remover
            </button>
          </div>
        </StaticDialog>
      </div>
    );
  }
}

export default DadosCortina;

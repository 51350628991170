import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

import Modal from "../../../Comps/Modal";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEye,
  faUniversity,
  faPen,
  faUpload,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { apiUrl } from "../../../config";
class Clientes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    document.title = "Contratantes";
    fetch(`${apiUrl}/empresas/list`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("auth_token"),
      },
    }).then((response) => {
      if (response.status == 200) {
        response.json().then((data) => {
          this.setState({ data, Loader: false });
        });
      }
    });
  }

  render() {
    if (this.state.Loader == true) {
      return (
        <>
          <div className="loader-container">
            <div class="loader"></div>
          </div>
        </>
      );
    }
    return (
      <>
        <header class="bg-white ">
          <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">
              {" "}
              Contratante{" "}
            </h2>
          </div>
        </header>

        <div class="py-12">
          <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div class="col-12">
              <div class="row">
                <div class="py-12">
                  <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <div class="col-12">
                      <div class="card">
                        <div class="card-header d-flex align-items-center justify-content-between">
                          <>Lista de contratantes</>
                          <div className="alh-bt">
                            <Link
                              class="btn btn-xs btn-primary"
                              to={`${window.location.pathname}/adicionar`}
                            >
                              <FontAwesomeIcon color="#fff" icon={faPlus} />{" "}
                              Adicionar Contratante
                            </Link>
                          </div>
                        </div>

                        <div class="card-body">
                          <div class="row">
                            <div class="col-12">
                              <div></div>
                              {this.state.data.length > 0 ? (
                                <>
                                  <table class="table table-sm table-bordered">
                                    <thead>
                                      <tr>
                                        <th scope="col">Código</th>
                                        <th scope="col">Razão social</th>
                                        <th scope="col">Documento</th>
                                        <th scope="col">Ação</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.data.map((item, index) => (
                                        <>
                                          <tr>
                                            <td>{item.token}</td>
                                            <td>
                                              {item.razao_social != ""
                                                ? item.razao_social
                                                : "Não informado!"}
                                            </td>
                                            <td>
                                              {item.cnpj != ""
                                                ? item.cnpj
                                                : "Não informado!"}
                                            </td>
                                            <td>
                                              <div class="btn-group btn-group-sm">
                                                <Link
                                                  class="btn btn-success"
                                                  to={`/${
                                                    window.location.pathname.split(
                                                      "/"
                                                    )[1]
                                                  }/preview/${item.token}`}
                                                >
                                                  <FontAwesomeIcon
                                                    color="#fff"
                                                    icon={faEye}
                                                  />
                                                </Link>
                                                <Link
                                                  class="btn btn-info"
                                                  to={`/${
                                                    window.location.pathname.split(
                                                      "/"
                                                    )[1]
                                                  }/edit/${item.token}`}
                                                >
                                                  <FontAwesomeIcon
                                                    color="#fff"
                                                    icon={faPen}
                                                  />
                                                </Link>
                                                <button
                                                  class="btn btn-danger"
                                                  onClick={() => {
                                                    Modal.create({
                                                      titulo: "Tem certeza?",
                                                      html: (
                                                        <>
                                                          <p>
                                                            Após a remoção, não
                                                            será possível
                                                            recuperar o dado.
                                                          </p>
                                                          <div class="card-footer">
                                                            <button
                                                              type="submit"
                                                              style={{
                                                                background:
                                                                  "#dc3545",
                                                              }}
                                                              class="btn btn-danger float-right ml-2"
                                                              onClick={() => {
                                                                fetch(
                                                                  `${apiUrl}${window.location.pathname}/remover/${item.token}`,
                                                                  {
                                                                    method:
                                                                      "DELETE",
                                                                    credentials:
                                                                      "include",
                                                                    headers: {
                                                                      "Content-Type":
                                                                        "application/json",
                                                                      Authorization:
                                                                        "Bearer " +
                                                                        Cookies.get(
                                                                          "auth_token"
                                                                        ),
                                                                    },
                                                                  }
                                                                ).then(
                                                                  (
                                                                    response
                                                                  ) => {
                                                                    if (
                                                                      response.status ==
                                                                      200
                                                                    ) {
                                                                      this.setState(
                                                                        (
                                                                          prevState
                                                                        ) => ({
                                                                          data: prevState.data.filter(
                                                                            (
                                                                              i
                                                                            ) =>
                                                                              i.token !==
                                                                              item.token
                                                                          ),
                                                                        })
                                                                      );
                                                                      Modal.close();
                                                                    }
                                                                  }
                                                                );
                                                              }}
                                                            >
                                                              <i class="fas fa-download"></i>{" "}
                                                              Remover{" "}
                                                            </button>
                                                          </div>
                                                        </>
                                                      ),
                                                    });
                                                  }}
                                                >
                                                  {" "}
                                                  <FontAwesomeIcon
                                                    icon={faTrash}
                                                  />
                                                </button>
                                              </div>
                                            </td>
                                          </tr>
                                        </>
                                      ))}
                                    </tbody>
                                  </table>
                                </>
                              ) : (
                                <>Não há dados cadastrados!</>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="modal-root"></div>
      </>
    );
  }
}

export default Clientes;

import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faClose,
  faFolder,
  faImage,
  faPen,
  faPlus,
  faSave,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { apiUrl } from "../../config";
import { json } from "react-router-dom";
import { Confirm, Modal, StaticDialog } from "react-st-modal";
import { text } from "@fortawesome/fontawesome-svg-core";
import ImageMarker from "../ImageMarker";
import GalleryModal from "../GalleryModal";
class Apc2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      Modal: {},
      add: undefined,
      text: null,
      matchedItem: undefined,
      image: null,
      file: null,
      ntext: "",
      textList: [],
      psq: "",
      modal: {},
      edit: "",
      Galery: [],
      loader: false,
      matchedItem: [],
      trash: {},
      trashImg: {},
      selectedArea: null, // Controla qual área está selecionada
    };
    this.textareaRef = React.createRef();
    this.inputRefs = {};
  }
  componentDidMount() {
    const matchedItem = this.props.data.hasOwnProperty(this.props.code)
      ? this.props.data[this.props.code]
      : [];

    if (matchedItem.length > 0) {
      this.setState({
        matchedItem,
        loader: false,
      });
    } else {
      this.setState({ loader: false, matchedItem: [] });
    }
  }
  handleAreaClick = (file, index) => {
    this.setState({ selectedArea: { file, index } }, () => {
      // Foca no input de arquivo correspondente
      if (this.inputRefs[`${file}-${index}`]) {
        this.inputRefs[`${file}-${index}`].focus();
      }
    });
  };

  quebraLinha = (texto) => {
    if (texto == null || texto == undefined) return "";

    return texto.split("\n").map((linha, index) => (
      <React.Fragment key={index}>
        {linha.split("*").map((parte, i) =>
          i % 2 === 1 ? (
            <strong key={i}>{parte}</strong> // Aplica negrito nas partes entre asteriscos
          ) : (
            parte
          )
        )}
        {index < texto.split("\n").length - 1 && <br />}
      </React.Fragment>
    ));
  };
  LoadGalery() {
    fetch(`${apiUrl}/galery/list`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.status == 200) {
        response.json().then((data) => {
          this.setState({ Galery: data });
        });
      }
    });
  }

  OpenFodler(a, b, c) {
    fetch(`${apiUrl}/galery/list`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.status == 200) {
        response.json().then((data) => {
          this.setState({ Galery: data, img: [b, c] });
        });
      }
    });
  }

  Preview(img) {
    return (
      <div class="area-8">
        <div class="preview" code={this.props.code}>
          <div class="edit">
            <input
              type="file"
              onChange={(event) => {
                this.handleImage(event);
              }}
            />

            <FontAwesomeIcon icon={faPen} color="#fff" />
          </div>
          <img code={this.props.code} src={`${apiUrl}/img/${img}`} />
        </div>
      </div>
    );
  }

  handleImage(event, id, img) {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;
      if (!fileType.startsWith("image/")) {
        toast.warn("Por favor, selecione uma imagem válida!");
        event.target.value = null;
      } else {
        const formData = new FormData();
        formData.append("id", id);
        formData.append("img", img);
        formData.append("imagem", file);
        formData.append("ref", window.location.pathname.split("/")[3]);
        formData.append("code", this.props.code);

        fetch(`${apiUrl}/apc2/image`, {
          method: "POST",
          body: formData,
        }).then((response) => {
          if (response.status == 200) {
            response.json().then((data) => {
              this.setState((prevState) => ({
                matchedItem: prevState.matchedItem.map((item) =>
                  item.id === id ? { ...item, [img]: data.img } : item
                ),
              }));
              toast.success("Salvo!");
            });
          }
        });
      }
    }
  }

  handleChange() {
    var text = this.state.ntext;

    if (text.trim().length == 0) {
      //event.target.value = '';
    } else {
      fetch(`${apiUrl}/apc2/text`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: this.state.modal.id,
          ref: window.location.pathname.split("/")[3],
          code: this.props.code,
          text: text,
        }),
      }).then((response) => {
        response.json().then((data) => {
          var id = this.state.modal.id;
          this.setState((prevState) => ({
            matchedItem: prevState.matchedItem.map((item) =>
              item.id === id ? { ...item, text } : item
            ),
            ntext: "",
            modal: {},
          }));
          toast.success("Salvo!");
        });
      });
    }
  }

  handleImageChange = (event) => {
    const file = event.target.files[0];

    // Verifica se o arquivo é uma imagem
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();

      reader.onloadend = () => {
        this.setState({ image: reader.result, file });
      };

      reader.readAsDataURL(file);
    } else {
      toast.warn("Por favor, selecione um arquivo de imagem.");
    }
  };
  adjustHeight = () => {
    const textarea = this.textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto"; // Redefine a altura para evitar crescimento infinito
      textarea.style.height = `${textarea.scrollHeight}px`; // Ajusta para o tamanho do conteúdo
    }
  };
  render() {
    const listaFiltrada = this.state.textList.filter((item) =>
      item.text.toLowerCase().includes(this.state.psq.toLowerCase())
    );
    if (this.state.loader) {
      return <>Carregando...</>;
    }

    if (window.location.pathname.split("/")[1] == "preview2") {
      var item = this.state.matchedItem[0];
      return (
        <div className="mb-3">
          {this.props.type == "1" ? (
            <div
              className="mt-3"
              style={{
                border: "solid 1px #000",
              }}
            >
              <div
                className="text-center"
                style={{
                  borderBottom: "solid 1px #000",
                  background: "#d9d9d9",
                }}
              >
                <strong> AÇÕES PROPOSTAS COMO SUGESTÃO DE ADEQUAÇÃO</strong>
              </div>
              <div style={{ margin: 5 }}>
                {" "}
                {this.state.matchedItem.length > 0 ? (
                  <>
                    {this.state.matchedItem.map((item) => (
                      <>
                        {item.type == 1 ? (
                          <div className="mt-3 mb-3">
                            {this.quebraLinha(item.text)}
                          </div>
                        ) : (
                          <div key={item.id} className="mt-3  mb-3 text-center">
                            {item.text && (
                              <div className="mt-3 mb-3">
                                {this.quebraLinha(item.text)}
                              </div>
                            )}

                            <div className="d-flex mt-3 mb-3 justify-content-center flex-wrap">
                              {[item.i1, item.i2, item.i3, item.i4]
                                .map(
                                  (img, index) =>
                                    img && (
                                      <img
                                        key={index}
                                        src={`${apiUrl}/img/${img}`}
                                        style={{
                                          width: 250,
                                          height: 250,
                                          margin: "5px",
                                          border: "solid 1px",
                                        }}
                                      />
                                    )
                                )
                                .reduce((acc, curr, index) => {
                                  if (index % 2 === 0) {
                                    acc.push([]);
                                  }
                                  acc[acc.length - 1].push(curr);
                                  return acc;
                                }, [])
                                .map((row, rowIndex) => (
                                  <div
                                    key={rowIndex}
                                    className="d-flex justify-content-center"
                                  >
                                    {row}
                                  </div>
                                ))}
                            </div>
                          </div>
                        )}
                      </>
                    ))}
                  </>
                ) : (
                  <div style={{ height: 30 }}></div>
                )}
              </div>
            </div>
          ) : (
            <>
              {this.state.matchedItem.length > 0 ? (
                <div className="">
                  <div className="mt-3 mb-3">
                    <strong>{this.props.noTitle != undefined ? "" : ""}</strong>
                  </div>
                  <div className="">
                    {this.state.matchedItem.map((item) => (
                      <div className="">
                        {item.type == 1 ? (
                          <div className="">{this.quebraLinha(item.text)}</div>
                        ) : (
                          <div key={item.id} className=" text-center">
                            {item.text && (
                              <div className="mb-3">
                                {this.quebraLinha(item.text)}
                              </div>
                            )}

                            <div className="d-flex justify-content-center flex-wrap">
                              {[item.i1, item.i2, item.i3, item.i4]
                                .map(
                                  (img, index) =>
                                    img && (
                                      <img
                                        key={index}
                                        src={`${apiUrl}/img/${img}`}
                                        style={{
                                          width: 250,
                                          height: 250,
                                          margin: "5px",
                                          border: "solid 1px",
                                        }}
                                      />
                                    )
                                )
                                .reduce((acc, curr, index) => {
                                  if (index % 2 === 0) {
                                    acc.push([]);
                                  }
                                  acc[acc.length - 1].push(curr);
                                  return acc;
                                }, [])
                                .map((row, rowIndex) => (
                                  <div
                                    key={rowIndex}
                                    className="d-flex justify-content-center"
                                  >
                                    {row}
                                  </div>
                                ))}
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      );
    }

    return (
      <div className="mt-3">
        <div className="card">
          <div className="card-header">
            <h3>
              {this.props.title}
              {process.env.NODE_ENV === "development"
                ? " - " + this.props.code
                : ""}
            </h3>
          </div>
          <div className="card-body">
            {this.state.matchedItem.length > 0 ? (
              <>
                {this.state.matchedItem.map((item) => (
                  <>
                    {item.type == 1 && (
                      <>
                        <div className="card mb-3">
                          <div className="card-header align-items-center d-flex justify-content-between">
                            <span>Texto</span>
                            <button
                              className="btn btn-danger"
                              onClick={() => {
                                this.setState({
                                  trash: item,
                                });
                              }}
                            >
                              <FontAwesomeIcon icon={faClose} />
                            </button>
                          </div>
                          <div className="card-body">
                            <div
                              className="mt-1"
                              style={{
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                                width: "100%",
                                padding: "5px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                fetch(`${apiUrl}/text-list`, {
                                  method: "GET",
                                  credentials: "include",
                                  headers: {
                                    "Content-Type": "application/json",
                                  },
                                }).then((response) => {
                                  if (response.status == 200) {
                                    response.json().then((data) => {
                                      this.setState({ textList: data });
                                      this.setState({
                                        modal: item,
                                        ntext: item.text,
                                      });
                                    });
                                  }
                                });
                              }}
                            >
                              {this.quebraLinha(item.text) || "Digite:"}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {item.type == 2 && (
                      <>
                        <div className="card mb-3">
                          <div className="card-header align-items-center d-flex justify-content-between">
                            <span>Imagens</span>
                            <button
                              className="btn btn-danger"
                              onClick={() => {
                                this.setState({
                                  trash: item,
                                });
                              }}
                            >
                              <FontAwesomeIcon icon={faClose} />
                            </button>
                          </div>
                          <div className="card-body">
                            <div style={{ display: "flex" }}>
                              {["i1", "i2", "i3", "i4"].map((file, index) => (
                                <>
                                  {item[file] == null ? (
                                    <div
                                      key={index}
                                      className={`area-8 gp-imgs ${
                                        this.state.selectedArea?.file ===
                                          file &&
                                        this.state.selectedArea?.index === index
                                          ? "selected"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        this.handleAreaClick(file, index)
                                      } // Adiciona o clique para selecionar a área
                                    >
                                      <div className="">
                                        <div className="area-8 gp-imgs">
                                          <div
                                            className="v2"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              height: 150,
                                              width: 150,
                                              border: "dashed 2px",
                                              marginRight: 10,
                                            }}
                                          >
                                            <div className="d-flex">
                                              <div className="file-v2">
                                                <button className="btn btn-primary">
                                                  <FontAwesomeIcon
                                                    icon={faImage}
                                                  />
                                                </button>
                                                <input
                                                  type="file"
                                                  onChange={(event) => {
                                                    this.handleImage(
                                                      event,
                                                      item.id,
                                                      file
                                                    );
                                                  }}
                                                />
                                              </div>

                                              <button
                                                onClick={(e) => {
                                                  this.OpenFodler(
                                                    e,
                                                    item.id,
                                                    file
                                                  );
                                                }}
                                                className="btn btn-primary"
                                                style={{ marginLeft: 10 }}
                                              >
                                                <FontAwesomeIcon
                                                  icon={faFolder}
                                                />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <>
                                      <div className="area-8 gp-imgs">
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            width: 150,
                                            height: 150,
                                            flexDirection: "column",
                                            border: "dashed 2px",
                                            marginRight: 10,
                                          }}
                                        >
                                          <img
                                            src={`${apiUrl}/img/${item[file]}`}
                                            style={{
                                              width: 75,
                                              height: 75,
                                            }}
                                          />
                                          <div style={{ marginTop: 10 }}>
                                            <button
                                              className="btn btn-primary"
                                              onClick={() => {
                                                this.setState({
                                                  edit: {
                                                    id: item.id,
                                                    img: item[file],
                                                    q: file,
                                                    index,
                                                  },
                                                });
                                              }}
                                            >
                                              <FontAwesomeIcon
                                                color="#fff"
                                                icon={faPen}
                                              />
                                            </button>
                                            <button
                                              style={{ marginLeft: 10 }}
                                              className="btn btn-danger"
                                              onClick={() => {
                                                this.setState({
                                                  trashImg: {
                                                    id: item.id,
                                                    index,
                                                    img: file,
                                                    type: item[file],
                                                  },
                                                });
                                              }}
                                            >
                                              <FontAwesomeIcon
                                                color="#fff"
                                                icon={faTrash}
                                              />
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </>
                              ))}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {item.type == 3 && (
                      <>
                        <div className="card mb-3">
                          <div className="card-header align-items-center d-flex justify-content-between">
                            <span>Texto com imagens</span>
                            <button
                              className="btn btn-danger"
                              onClick={() => {
                                this.setState({
                                  trash: item,
                                });
                              }}
                            >
                              <FontAwesomeIcon icon={faClose} />
                            </button>
                          </div>
                          <div className="card-body">
                            <div
                              className="mt-1"
                              style={{
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                                width: "100%",
                                padding: "5px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                fetch(`${apiUrl}/text-list`, {
                                  method: "GET",
                                  credentials: "include",
                                  headers: {
                                    "Content-Type": "application/json",
                                  },
                                }).then((response) => {
                                  if (response.status == 200) {
                                    response.json().then((data) => {
                                      this.setState({ textList: data });
                                      this.setState({
                                        modal: item,
                                        ntext: item.text,
                                      });
                                    });
                                  }
                                });
                              }}
                            >
                              {this.quebraLinha(item.text) || "Digite:"}
                            </div>
                            <div>
                              <div style={{ display: "flex" }}>
                                {["i1", "i2", "i3", "i4"].map((file, index) => (
                                  <>
                                    {item[file] == null ? (
                                      <div
                                        key={index}
                                        className={`area-8 gp-imgs ${
                                          this.state.selectedArea?.file ===
                                            file &&
                                          this.state.selectedArea?.index ===
                                            index
                                            ? "selected"
                                            : ""
                                        }`}
                                        onClick={() =>
                                          this.handleAreaClick(file, index)
                                        } // Adiciona o clique para selecionar a área
                                      >
                                        <div className="mt-3">
                                          <div className="area-8 gp-imgs">
                                            <div
                                              className="v2"
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                height: 150,
                                                width: 150,
                                                border: "dashed 2px",
                                                marginRight: 10,
                                              }}
                                            >
                                              <div className="d-flex">
                                                <div className="file-v2">
                                                  <button className="btn btn-primary">
                                                    <FontAwesomeIcon
                                                      icon={faImage}
                                                    />
                                                  </button>
                                                  <input
                                                    type="file"
                                                    onChange={(event) => {
                                                      this.handleImage(
                                                        event,
                                                        item.id,
                                                        file
                                                      );
                                                    }}
                                                  />
                                                </div>

                                                <button
                                                  onClick={(e) => {
                                                    this.OpenFodler(
                                                      e,
                                                      item.id,
                                                      file
                                                    );
                                                  }}
                                                  className="btn btn-primary"
                                                  style={{ marginLeft: 10 }}
                                                >
                                                  <FontAwesomeIcon
                                                    icon={faFolder}
                                                  />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <>
                                        <div
                                          className="area-8 mt-3"
                                          style={{ marginRight: 10 }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              width: 150,
                                              height: 150,
                                              flexDirection: "column",
                                              border: "dashed 2px",
                                            }}
                                          >
                                            <img
                                              src={`${apiUrl}/img/${item[file]}`}
                                              style={{
                                                width: 75,
                                                height: 75,
                                              }}
                                            />
                                            <div style={{ marginTop: 10 }}>
                                              <button
                                                className="btn btn-primary"
                                                onClick={() => {
                                                  this.setState({
                                                    edit: {
                                                      id: item.id,
                                                      img: item[file],
                                                      q: file,
                                                      index,
                                                    },
                                                  });
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  color="#fff"
                                                  icon={faPen}
                                                />
                                              </button>
                                              <button
                                                style={{ marginLeft: 10 }}
                                                className="btn btn-danger"
                                                onClick={() => {
                                                  this.setState({
                                                    trashImg: {
                                                      id: item.id,
                                                      index,
                                                      img: file,
                                                      type: item[file],
                                                    },
                                                  });
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  color="#fff"
                                                  icon={faTrash}
                                                />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ))}

                <div className="mt-3">
                  <select
                    onChange={(e) => {
                      var type = e.target.value;
                      if (type == 0) {
                        return;
                      }
                      e.target.disabled = true;
                      fetch(`${apiUrl}/create-apc2/`, {
                        method: "POST",
                        credentials: "include",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                          ref: window.location.pathname.split("/")[3],
                          code: this.props.code,
                          type,
                        }),
                      }).then((response) => {
                        if (response.status == 200) {
                          response.json().then((data) => {
                            e.target.disabled = false;
                            e.target.value = 0;
                            this.setState((prevState) => ({
                              matchedItem: [
                                ...prevState.matchedItem,
                                { id: data.id, type },
                              ],
                            }));
                          });
                        }
                      });
                    }}
                  >
                    <option value={0}>Selecione</option>
                    <option value={1}>Texto</option>
                    <option value={2}>Imagens</option>
                    <option value={3}>Texto e Imagens</option>
                  </select>
                </div>
              </>
            ) : (
              <div className="d-flex justify-content-center">
                <select
                  onChange={(e) => {
                    var type = e.target.value;
                    if (type == 0) {
                      return;
                    }
                    fetch(`${apiUrl}/create-apc2/`, {
                      method: "POST",
                      credentials: "include",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        ref: window.location.pathname.split("/")[3],
                        code: this.props.code,
                        type,
                      }),
                    }).then((response) => {
                      if (response.status == 200) {
                        response.json().then((data) => {
                          this.setState((prevState) => ({
                            matchedItem: [
                              ...prevState.matchedItem,
                              { id: data.id, type },
                            ],
                          }));
                        });
                      }
                    });
                  }}
                >
                  <option value={0}>Selecione</option>
                  <option value={1}>Texto</option>
                  <option value={2}>Imagens</option>
                  <option value={3}>Texto e Imagens</option>
                </select>
              </div>
            )}
          </div>
        </div>

        <StaticDialog
          isOpen={Object.keys(this.state.Galery).length > 0}
          title="Galeria:"
          onAfterClose={(result) => {
            this.setState({ Galery: [] });
          }}
        >
          <div style={{ margin: 10 }}>
            <GalleryModal
              gallery={this.state.Galery}
              img={this.state.img}
              onImageSelect={(file) => {
                var img = this.state.img;

                this.setState((prevState) => ({
                  matchedItem: prevState.matchedItem.map((item) =>
                    item.id === img[0] ? { ...item, [img[1]]: file } : item
                  ),
                  Galery: [],
                  img: [],
                }));
              }}
            />
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={Object.keys(this.state.modal).length > 0}
          title="TEXTO:"
          onAfterClose={(result) => {
            this.setState({ modal: {} });
          }}
        >
          <div style={{ padding: 10 }}>
            <textarea
              ref={this.textareaRef}
              onInput={this.adjustHeight}
              class="form-control form-control-sm"
              onChange={(e) => {
                this.setState({ ntext: e.target.value });
              }}
              value={this.state.ntext}
              placeholder="Digite:"
            ></textarea>
            <input
              style={{
                width: "100%",
                height: 35,
                marginTop: 10,
              }}
              type="text"
              onChange={(e) => {
                this.setState({ psq: e.target.value });
              }}
              placeholder="Pesquisar:"
            />
            <div class="area-ptxt">
              {listaFiltrada.length > 0 ? (
                <>
                  <ul>
                    {listaFiltrada.map((item) => (
                      <>
                        <li
                          onClick={() => {
                            this.setState({ ntext: item.text });
                          }}
                        >
                          {this.quebraLinha(item.text)}
                        </li>
                      </>
                    ))}
                  </ul>
                </>
              ) : (
                <>Não há nada aqui!</>
              )}
            </div>
          </div>

          <hr />

          <div
            class="d-flex justify-content-end"
            style={{
              margin: 10,
            }}
          >
            <button
              type="button"
              class="btn btn-primary"
              onClick={() => {
                this.handleChange();
              }}
              disabled={this.state.text != this.state.ntext ? false : true}
            >
              <FontAwesomeIcon icon={faSave} color="#fff" /> Salvar
            </button>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={Object.keys(this.state.trashImg).length > 0}
          title="Tem certeza?"
          onAfterClose={(result) => {
            this.setState({ trashImg: {} });
          }}
        >
          <div className="modal-body" style={{ margin: 10 }}>
            <div className="text-center">
              Deseja realmente deletar a imagem?
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-danger"
              onClick={(e) => {
                e.target.classList.add("load");
                var id = this.state.trashImg.id;
                var img = this.state.trashImg.img;

                fetch(`${apiUrl}/delete-img-apc2`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    id,
                    img,
                  }),
                })
                  .then((response) => {
                    response.json().then((data) => {
                      e.target.classList.remove("load");

                      this.setState((prevState) => ({
                        matchedItem: prevState.matchedItem.map((item) =>
                          item.id === id ? { ...item, [img]: null } : item
                        ),
                        trashImg: {},
                      }));
                    });
                  })
                  .catch((error) => {
                    e.target.classList.remove("load");
                    toast.error("Erro ao deletar imagem!");
                  });
              }}
            >
              <FontAwesomeIcon icon={faTrash} /> Remover
            </button>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={this.state.edit != ""}
          title="editar imagem"
          onAfterClose={(result) => {
            this.setState({ edit: "" });
            // do something with dialog result
          }}
        >
          <ImageMarker
            imageSrc={`${apiUrl}/img/${this.state.edit.img}`}
            isOpen={this.state.edit != ""}
            onUpload={(blob) => {
              const formData = new FormData();
              formData.append("id", this.state.edit.id);
              formData.append("q", this.state.edit.q);
              formData.append("image", blob, "update.png");
              fetch(`${apiUrl}/alterar-imagem-apc2`, {
                method: "POST",
                body: formData,
              }).then((response) => {
                toast.success("Imagem editada com sucesso!");

                response.json().then((data) => {
                  this.setState((prevState) => ({
                    matchedItem: prevState.matchedItem.map((item) =>
                      item.id === this.state.edit.id
                        ? { ...item, [this.state.edit.q]: data.img }
                        : item
                    ),
                    edit: "",
                  }));
                });
              });
            }}
            item={[]}
          />
        </StaticDialog>
        <StaticDialog
          isOpen={Object.keys(this.state.trash).length > 0}
          title="Tem certeza?"
          onAfterClose={(result) => {
            this.setState({ trash: {} });
          }}
        >
          <div style={{ margin: 10 }}>
            Uma vez deletado, não será possível recuperar
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-danger"
              onClick={(e) => {
                e.target.classList.add("load");
                fetch(`${apiUrl}/delete-apc2`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({ id: this.state.trash.id }),
                })
                  .then((response) => {
                    response.json().then((data) => {
                      e.target.classList.remove("load");
                      this.setState((prevState) => ({
                        matchedItem: prevState.matchedItem.filter(
                          (item) => item.id !== this.state.trash.id
                        ),
                        trash: {},
                      }));
                    });
                  })
                  .catch((error) => {
                    e.target.classList.remove("load");
                    toast.error("Erro ao deletar imagem!");
                  });
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
              Remover
            </button>
          </div>
        </StaticDialog>
      </div>
    );
  }
}

export default Apc2;

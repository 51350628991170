import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./g.css";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faDownload,
  faPen,
  faArrowLeft,
  faPrint,
  faAngleLeft,
  faImage,
  faUpload,
  faPlus,
  faClose,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import { apiUrl } from "../../config";
import { Confirm, StaticDialog } from "react-st-modal";

class Galeria extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loader: true,
      modal: false,
      titulo: "",
      selectedFile: null,
      imgUrl: "",
      data: [], // Inicialize data como um array vazio
      currentPage: 1,
      itemsPerPage: 9, // Número de itens por página
      searchTerm: "",
      filteredData: [],
    };
  }

  componentDidMount() {
    document.title = "Minha galeria";
    this.fetchImages();
  }

  fetchImages = () => {
    this.setState({ Loader: true });
    fetch(`${apiUrl}/galery/list`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("auth_token"),
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        if (response.status === 404) {
          this.setState({ p404: true, Loader: false, data: [] });
          return [];
        }
        throw new Error("Erro ao carregar as imagens");
      })
      .then((data) => {
        this.setState({
          data,
          Loader: false,
          filteredData: data, // Inicializa filteredData com todos os dados
          currentPage: 1, // Resetar a página ao carregar novos dados
        });
      })
      .catch((error) => {
        console.error("Erro ao buscar imagens:", error);
        this.setState({ Loader: false });
        toast.error("Erro ao carregar as imagens.");
      });
  };

  handleDeleteImage = async (itemId) => {
    const isConfirm = await Confirm(
      "Se confirmar, a imagem será excluída permanentemente. Deseja continuar?",
      "Confirmar Exclusão"
    );
    if (isConfirm) {
      fetch(`${apiUrl}/galery/delete/${itemId}`, {
        method: "DELETE",
      })
        .then((response) => {
          if (response.status === 200) {
            toast.success("Imagem excluída com sucesso!");
            this.fetchImages(); // Recarrega as imagens após a exclusão
          } else {
            toast.error("Erro ao excluir a imagem.");
          }
        })
        .catch((error) => {
          console.error("Erro ao excluir imagem:", error);
          toast.error("Erro ao excluir a imagem.");
        });
    }
  };

  handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    this.setState({ searchTerm, currentPage: 1 }, () => {
      this.filterImages();
    });
  };

  filterImages = () => {
    const { data, searchTerm } = this.state;
    const filtered = data.filter((item) => {
      return item.title && item.title.toLowerCase().includes(searchTerm);
    });
    this.setState({ filteredData: filtered });
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };

  renderImages() {
    const { itemsPerPage, currentPage, filteredData } = this.state;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    return currentItems.map((item) => (
      <tr key={item.id}>
        <td>
          <img
            src={`${apiUrl}/img/${item.img}`}
            style={{ width: 100, height: 100, objectFit: "cover" }}
            alt={item.title || "Imagem"}
          />
        </td>
        <td className="text-center">{item.title || "Sem título"}</td>
        <td className="text-center">
          <button
            className="btn btn-sm btn-primary"
            onClick={() => {
              this.setState({ edit: item });
            }}
          >
            <FontAwesomeIcon icon={faPen} /> Editar
          </button>

          <button
            style={{ marginLeft: 10 }}
            className="btn btn-sm btn-danger"
            onClick={() => this.handleDeleteImage(item.id)}
          >
            <FontAwesomeIcon icon={faTrash} /> Remover
          </button>
        </td>
      </tr>
    ));
  }

  renderPagination() {
    const { itemsPerPage, filteredData, currentPage } = this.state;
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    if (totalPages <= 1) return null;

    const renderPageNumbers = () => {
      const pageNumbers = [];
      let startPage = Math.max(1, currentPage - 2);
      let endPage = Math.min(totalPages, currentPage + 2);

      if (startPage > 1) {
        pageNumbers.push(1);
        if (startPage > 2) pageNumbers.push("...");
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }

      if (endPage < totalPages) {
        if (endPage < totalPages - 1) pageNumbers.push("...");
        pageNumbers.push(totalPages);
      }

      return pageNumbers.map((number, index) => (
        <li
          key={index}
          className={`page-item ${currentPage === number ? "active" : ""}`}
        >
          {number === "..." ? (
            <span className="page-link">...</span>
          ) : (
            <button
              className="page-link"
              onClick={() => this.handlePageChange(number)}
            >
              {number}
            </button>
          )}
        </li>
      ));
    };

    return (
      <nav aria-label="Page navigation">
        <ul className="pagination justify-content-center">
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <button
              className="page-link"
              onClick={() => this.handlePageChange(currentPage - 1)}
            >
              «
            </button>
          </li>

          {renderPageNumbers()}

          <li
            className={`page-item ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <button
              className="page-link"
              onClick={() => this.handlePageChange(currentPage + 1)}
            >
              »
            </button>
          </li>
        </ul>
      </nav>
    );
  }

  render() {
    if (this.state.Loader === true) {
      return (
        <>
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        </>
      );
    }

    return (
      <>
        <header className="bg-white shadow">
          <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <button
              className="font-semibold text-xl text-gray-800 leading-tight"
              onClick={() => {
                this.props.navigate(-1);
              }}
            >
              <FontAwesomeIcon icon={faAngleLeft} /> Galeria
            </button>
          </div>
        </header>

        <main>
          <div className="py-12">
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div className="col-12 mt-3" style={{ display: "block" }}>
                <div className="card">
                  <div className="card-header d-flex align-items-center justify-content-between">
                    Imagens
                    <Link to="/galeria">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          this.setState({ modal: true });
                        }}
                      >
                        <FontAwesomeIcon icon={faUpload} color="#fff" /> Upload
                      </button>
                    </Link>
                  </div>

                  <div className="card-body">
                    <div className="">
                      <div className="d-flex justify-content-center mb-3">
                        <div className="form-group w-50">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="Pesquisar por título"
                            style={{ height: 40 }}
                            value={this.state.searchTerm}
                            onChange={this.handleSearch}
                          />
                        </div>
                      </div>
                      {this.state.filteredData.length > 0 ? (
                        <>
                          <table className="table ntbl table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">Imagem</th>
                                <th scope="col">Título</th>
                                <th scope="col" className="text-center">
                                  Ações
                                </th>
                              </tr>
                            </thead>
                            <tbody>{this.renderImages()}</tbody>
                          </table>
                          {this.renderPagination()}
                        </>
                      ) : (
                        <>Não há imagens</>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <ToastContainer />

        <StaticDialog
          isOpen={this.state.modal}
          title="Upload de imagem"
          onAfterClose={(result) => {
            this.setState({ modal: false, titulo: "", selectedFile: null });
          }}
        >
          <div style={{ padding: 10 }}>
            <input
              type="text"
              style={{
                width: "100%",
                height: 30,
              }}
              value={this.state.titulo}
              onChange={(e) => {
                this.setState({ titulo: e.target.value });
              }}
              placeholder="Título:"
            />

            <div
              className="area-8"
              style={{ marginBottom: -15 }}
              code="2111354er"
            >
              {this.state.selectedFile == null ? (
                <>
                  <div className="add" code="2111354er">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        const file = e.target.files[0];

                        if (file && file.type.startsWith("image/")) {
                          this.setState({ selectedFile: file });
                          if (file) {
                            const imgUrl = URL.createObjectURL(file);
                            this.setState({ imgUrl });
                          }
                        } else {
                          toast.error(
                            "Por favor, selecione um arquivo de imagem válido."
                          );
                          return false;
                        }
                      }}
                    />
                    <FontAwesomeIcon icon={faPlus} />
                  </div>
                </>
              ) : (
                <>
                  <div className="preview">
                    <div
                      className="fx"
                      onClick={() => {
                        this.setState({ selectedFile: null });
                      }}
                    >
                      <FontAwesomeIcon icon={faClose} color="#fff" />
                    </div>
                    <img src={this.state.imgUrl} alt="Pré-visualização" />
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="d-flex justify-content-end" style={{ margin: 10 }}>
            <button
              className="btn btn-primary"
              onClick={() => {
                if (!this.state.selectedFile) {
                  toast.error("Por favor, selecione uma imagem.");
                  return;
                }

                const id = toast.loading("Enviando imagem");
                const formData = new FormData();

                formData.append("imagem", this.state.selectedFile);
                formData.append("title", this.state.titulo);
                fetch(`${apiUrl}/galery/add`, {
                  method: "POST",
                  body: formData,
                })
                  .then((response) => {
                    if (response.status === 200) {
                      return response.json();
                    }
                    throw new Error("Erro ao enviar a imagem");
                  })
                  .then((data) => {
                    const updatedData = [
                      { img: data.img, id: data.id, title: data.title },
                      ...this.state.data,
                    ];

                    this.setState({
                      data: updatedData,
                      filteredData: updatedData,
                      modal: false,
                      titulo: "",
                      selectedFile: null,
                      currentPage: 1, // Resetar a página após adicionar
                    });

                    toast.update(id, {
                      render: "Imagem salva",
                      type: "success",
                      isLoading: false,
                      autoClose: 3000,
                    });
                  })
                  .catch((error) => {
                    console.error("Erro ao enviar imagem:", error);
                    toast.update(id, {
                      render: "Erro no processamento!",
                      type: "error",
                      isLoading: false,
                      autoClose: 3000,
                    });
                  });
              }}
              disabled={!this.state.selectedFile}
            >
              <FontAwesomeIcon
                style={{ marginRight: 10 }}
                icon={faSave}
                color="#fff"
              />
              Salvar
            </button>
          </div>
        </StaticDialog>
      </>
    );
  }
}

export default Galeria;
